<template>
  <v-row class="no-gutters" :class="{ 'ma-n3': $vuetify.breakpoint.smAndDown }">
    <v-col md="4" xs="12" cols="12">
      <ChatContacts />
    </v-col>
    <v-col md="8" xs="12" cols="12">
      <div v-if="!selectedContact" class="unselected right-panel min-100 d-flex flex-column grey lighten-2 justify-center align-center" style="height:90vh">
        <img v-if="hasContacts" style="width:300px" src="@/assets/images/undraw_begin_chat_c6pj.svg" />
        <h2 v-if="hasContacts" class="mt-3">Select a contact</h2>
        <div v-if="!hasContacts && isInstructor" class="text-center pa-5">
          <p>You currently don't have any students paired with you.</p>
          <p>Share your pairing code with your students and ask them to use this when signing up to InstructorPay</p>
          <h3 class="display-1 text-lg-center">{{ instructorCode }}</h3>
        </div>
      </div>

      <div v-if="selectedContact" class="right-panel min-100 d-flex flex-column" style="max-height:90vh">
        <div class="top hidden-sm-and-down pa-3 grey lighten-2">
          <div class="d-flex  align-center justify-space-between">
            <div v-if="selectedContactProfile">
              <AvatarName :name="selectedContactProfile.fullName" :imageUrl="selectedContactProfile.profilePicture" :size="'md'" :isClickable="false" />
            </div>
            <div><AvatarName :name="fullName" :imageUrl="profilePicture" :size="'md'" :isClickable="false" /></div>
          </div>
        </div>
        <ChatConversation />

        <div class="bottom d-flex flex-column align-center justify-space-around grey lighten-2">
          <div v-if="connectionError" class="red white--text text-center" style="width:100%">Error connecting...</div>

          <div class="pa-5" style=" width:100% ">
            <!-- <v-textarea
              id="msg"
              class="mt-0"
              ref="input"
              :hide-details="true"
              :persistent-hint="false"
              background-color="white"
              color="primary"
              v-model="userMessage"
              :placeholder="'Type a message'"
              :outlined="true"
              :rounded="true"
              :single-line="true"
              @keyup.enter="sendMessage()"
              :disabled="disabled"
              :rows="1"
              :no-resize="true"
              :autofocus="true"
              :auto-grow="true"
            ></v-textarea> -->
            <v-text-field
              v-model="userMessage"
              id="msg"
              class="mt-0"
              ref="input"
              :hide-details="true"
              :persistent-hint="false"
              :placeholder="'Type a message'"
              :outlined="true"
              :rounded="true"
              :single-line="true"
              background-color="white"
              color="primary"
              @keyup.enter="sendMessage()"
              :disabled="disabled"
            ></v-text-field>

            <!-- <i class="fa fa-paperclip attachment" aria-hidden="true"></i> -->
            <!-- <v-btn color="success" v-on:click="sendMessage">text</v-btn> -->
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ChatContacts from './ChatContacts'
import ChatConversation from './ChatConversation'
import Storage from '@/services/storage'
import { Actions } from '@/store/actions.type'

import * as signalR from '@microsoft/signalr'

export default {
  components: {
    ChatContacts,
    ChatConversation
  },
  data() {
    return {
      signalR: {},
      userMessage: '',
      connection: '',
      messages: [],
      loginToken: '',
      connectionError: false,
      disabled: true
    }
  },
  computed: {
    selectedContact() {
      return this.$store.getters['chat/selectedContact']
    },
    hasContacts() {
      let contacts = this.$store.getters['chat/contacts']
      return contacts.length > 0
    },
    selectedContactProfile() {
      return this.$store.getters['chat/selectedContactProfile']
    },
    instructorCode() {
      return this.$store.getters.user.profile.instructorCode
    },
    user() {
      return this.$store.state.auth.user
    },
    fullName() {
      return this.user.profile.fullName
    },
    profilePicture() {
      return this.user.profile.profilePicture
    },
    isInstructor() {
      return this.user.role == 'Instructor'
    },
    isStudent() {
      return this.user.role == 'Student'
    }
  },
  methods: {
    sendMessage: function() {
      let isEmpty = this.userMessage == null || this.userMessage.trim() === ''
      if (!isEmpty) {
        // ---------
        //  Call hub methods from client
        // ---------
        this.connection
          .invoke('SendMessage', this.selectedContact, this.userMessage)
          .then(() => {
            this.userMessage = ''
          })
          .catch(err => {
            this.disabled = true
            this.connectionError = true
            this.refresh()
            console.log(err)
          })
      }
    },

    refresh() {
      let tokenModel = {
        accessToken: Storage.getAccessToken(),
        client: 'Web',
        refreshToken: Storage.getRefreshToken()
      }

      this.$store.dispatch(Actions.REFRESH, { tokenModel }).then(() => {
        this.setUpSignalR()
        //this.sendMessage()
      })
    },

    scrollDown() {
      setTimeout(function() {
        var objDiv = document.getElementById('conversation')
        objDiv.scrollTop = objDiv.scrollHeight
      }, 30)
    },
    setUpSignalR() {
      let token = Storage.getAccessToken()
      if (token) {
        this.loginToken = token
      }
      // ---------
      // Connect to our hub
      // ---------
      // eslint-disable-next-line no-undef
      var apiUrl = process.env.VUE_APP_API_URL

      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(apiUrl + 'chatHub', { accessTokenFactory: () => token })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build()

      this.connection
        .start()
        .then(() => {
          this.connectionError = false
          this.disabled = false
        })
        .catch(function(err) {
          console.log(err)
        })

      // this.connection.onreconnecting(error => {
      //   console.log('Connection lost due to error TCL: error', error)
      //   // alert('onreconnecting ')
      //   this.connectionError = true
      //   this.disabled = true
      //   this.refresh()
      //   //this.setUpSignalR()
      // })

      // this.connection.onreconnected(connectionId => {
      //   console.log('Connection reestablished. Connected with connectionId', connectionId)
      //   alert('reestablished ')

      //   this.connectionError = false
      //   this.disabled = false
      // })

      this.connection.onclose(error => {
        console.log('#################Connection closed due to error TCL: error', error)
        this.connectionError = false
        this.disabled = false
        //this.setUpSignalR()
        this.refresh()
      })

      this.connection.on('ReceiveMessage', chatMessage => {
        if (chatMessage.fromUserId == this.selectedContact || chatMessage.toUserId == this.selectedContact) {
          this.$store.commit('chat/appendMessage', chatMessage)
          this.scrollDown()
        } else {
          this.$store.commit('chat/incrementUnreadMessageCount', chatMessage)
        }
      })
    }
  },
  created: function() {},

  mounted: function() {
    this.$store.dispatch('chat/fetchContacts').then(() => {
      this.setUpSignalR()
    })
    // ---------
    // Call client methods from hub
    // ---------
    //var thisVue = this
    //thisVue.connection.start()
    // this.connection.on('ReceiveMessage', chatMessage => {
    //   if (chatMessage.fromUserId == this.selectedContact || chatMessage.toUserId == this.selectedContact) {
    //     this.$store.commit('chat/appendMessage', chatMessage)
    //     this.scrollDown()
    //   } else {
    //     this.$store.commit('chat/incrementUnreadMessageCount', chatMessage)
    //   }
    // })
  },
  beforeDestroy() {
    this.$store.commit('chat/resetAll')
  }
}
</script>

<style lang="scss">
$window-height: 72vh;

.min-100 {
  min-height: 100%;
}

.max-parent {
  height: $window-height;
}

// .outer {
//   height: $window-height;
//   background: #ff6633;
// }

.right-panel {
}

.scrollable {
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border: 2px solid #bbb;
  }
}

.top {
}

.middle {
}

.bottom {
  background: #281ba5;
}
#msg {
  margin-top: 0px;
}
</style>
