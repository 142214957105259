<template>
  <h3 class="heading mb-6">{{ title }}</h3>
</template>

<script>
export default {
  props: ['title']
}
</script>

<style scoped></style>
