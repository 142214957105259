import Vue from 'vue'

//import AuthAPI from '../services/auth.service'
//import StripeAPI from '../services/stripe.service'
import authService from '@/services/generated/auth.service.gen'
import stripeBillingService from '@/services/generated/stripeBilling.service.gen'

import Storage from '../services/storage'
//import { LOGIN, LOGOUT, REGISTER, REFRESH, CHANGE_PASSWORD, CONFIRM_EMAIL, FORGOT_PASSWORD, RESET_PASSWORD, Actions } from "./actions.type";
import { Actions } from './actions.type'
//import { SET_AUTH, PURGE_AUTH, SET_ERROR, UPDATE_TOKENS } from "./mutations.type";

import { Mutations } from './mutations.type'
import { SubscriptionStatus } from '@/helpers/enums'

const client = 'Web'

const state = {
  errors: null,
  user: Storage.getUserDetails(),
  //profile: Storage.getProfileDetails(),
  accessToken: Storage.getAccessToken(),
  refreshToken: Storage.getRefreshToken(),
  isAuthenticated: !!(Storage.getAccessToken() && Storage.getUserDetails()),
  isRefreshing: false
}

// GETTERS

const getters = {
  user(state) {
    return state.user
  },

  isRefreshing(state) {
    return state.isRefreshing
  },

  profile(state) {
    return state.user.profile
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  },
  hasInstructor(state) {
    //return false;
    try {
      if (state.user.profile.instructorId) {
        return true
      }
      return false
    } catch (err) {
      return false
    }
  },
  isInstructor(state) {
    return state.user.role == 'Instructor'
  },
  isStudent(state) {
    return state.user.role == 'Student'
  },
  hasAccess(state) {
    let isActive = state.user.profile.subscriptionStatus.subscriptionStatus == SubscriptionStatus.Active
    let isTrialing = state.user.profile.subscriptionStatus.subscriptionStatus == SubscriptionStatus.Trialing
    return isActive || isTrialing
  },
  subscriptionStatus(state) {
    return state.user.profile.subscriptionStatus
  },
  subscriptionStatusStripe(state) {
    return state.user.profile.subscriptionStatusStripe
  }
}

// ACTIONS

const actions = {
  [Actions.LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      authService
        .login(credentials.email, credentials.password, credentials.client)
        .then(({ data }) => {
          context.commit(Mutations.SET_AUTH, data)
          //   // user: data.user,
          //   // accessToken: data.accessToken,
          //   // refreshToken: data.refreshToken
          // }); //data.user);
          resolve(data)
        })
        .catch(({ response }) => {
          //reject(response.data.error);
          reject(response)
          //context.commit(Mutations.SET_ERROR, response.data.errors);
        })
    })
  },
  [Actions.LOGOUT](context) {
    context.commit(Mutations.PURGE_AUTH)
  },
  [Actions.REGISTER](context, registerForm) {
    return new Promise((resolve, reject) => {
      //AuthAPI.signUp(registerForm)
      authService
        .register(registerForm.email, registerForm.password, registerForm.confirmPassword, registerForm.role, registerForm.client)
        .then(({ data }) => {
          //context.commit(SET_AUTH, data);
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(Mutations.SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [Actions.CONFIRM_EMAIL](context, model) {
    return new Promise((resolve, reject) => {
      authService
        .confirmEmail(model.userId, model.code, model.client)
        .then(({ data }) => {
          context.commit(Mutations.SET_AUTH, data)
          resolve(data)
        })
        .catch(({ response }) => {
          //context.commit(Mutations.SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [Actions.CHANGE_PASSWORD](context, model) {
    return new Promise((resolve, reject) => {
      //authService.changePassword(model)
      authService
        .changePassword(model.oldPassword, model.newPassword, model.confirmPassword)
        .then(({ data }) => {
          //context.commit(SET_AUTH, data);
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(Mutations.SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [Actions.FORGOT_PASSWORD](context, model) {
    return new Promise((resolve, reject) => {
      //AuthAPI.forgotPassword(model)
      authService
        .forgotPassword(model)
        .then(({ data }) => {
          //context.commit(SET_AUTH, data);
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(Mutations.SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },

  [Actions.RESET_PASSWORD](context, model) {
    return new Promise((resolve, reject) => {
      //AuthAPI.resetPassword(model)
      authService
        .resetPassword(model.email, model.password, model.confirmPassword, model.code)
        .then(({ data }) => {
          //context.commit(SET_AUTH, data);
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(Mutations.SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },

  [Actions.REFRESH](context) {
    console.log('Refreshing')

    return new Promise((resolve, reject) => {
      let tokenModel = {
        accessToken: Storage.getAccessToken(),
        client: client,
        refreshToken: Storage.getRefreshToken()
      }
      state.isRefreshing = true
      //authService.refreshToken(tokenModel)
      authService
        .refreshToken(tokenModel.accessToken, tokenModel.client, tokenModel.refreshToken)
        .then(({ data }) => {
          console.log('data', data)
          if (data && data.isSuccess) {
            context.commit(Mutations.UPDATE_TOKENS, data)
            resolve(data)
          } else {
            reject(data)
          }
        })
        .catch(response => {
          reject(response)
          //context.commit(Mutations.SET_ERROR, response.data.errors)
        })
    })
  },

  [Actions.GET_SUBSCRIPTION](context) {
    return new Promise(resolve => {
      //StripeAPI.getSubscriptionStatus()
      stripeBillingService
        .getSubscriptionStatus()
        .then(response => {
          context.commit(Mutations.SET_SUBSCRIPTION_STATUS, response.data.ipSubscriptionStatus)
          context.commit(Mutations.SET_SUBSCRIPTION_STATUS_STRIPE, response.data.stripeSubscriptionStatus)
          resolve(response.data)
        })
        .catch(({ response }) => {
          context.commit(Mutations.SET_ERROR, response.data.errors)
        })
        .finally(() => {
          //this.isLoading = false
        })
    })
  }
}

// MUTATIONS

const mutations = {
  setRefreshState(state, val) {
    state.isRefreshing = val
  },
  [Mutations.SET_ERROR](state, error) {
    state.errors = error
  },
  [Mutations.SET_AUTH](state, data) {
    state.isAuthenticated = true
    state.user = data.user
    state.errors = {}
    Storage.saveAccessToken(data.accessToken)
    Storage.saveRefreshToken(data.refreshToken)
    Storage.setUserDetails(data.user)
  },
  [Mutations.UPDATE_TOKENS](state, data) {
    state.isAuthenticated = true
    state.errors = {}
    Storage.saveAccessToken(data.accessToken)
    Storage.saveRefreshToken(data.refreshToken)
    console.log('Refreshed!')
    state.isRefreshing = false
  },
  [Mutations.PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    Storage.destroyTokens()
  },
  [Mutations.SET_PROFILE](state, profile) {
    Storage.updateProfile(profile)
    Vue.set(state.user.profile, 'instructorId', profile.instructorId)
    Vue.set(state.user.profile, 'isProfileComplete', profile.isProfileComplete)
    Vue.set(state.user, 'profile', profile)
  },

  [Mutations.SET_PROFILE_PICTURE](state, profilePicture) {
    Storage.updateProfilePicture(profilePicture)
    // Vue.set(state.user.profile, "instructorId", profile.instructorId);
    Vue.set(state.user.profile, 'profilePicture', profilePicture)
    // Vue.set(state.user, "profile", profile);
  },

  [Mutations.SET_CODE](state, instructorCode) {
    Storage.updateCode(instructorCode)
    // Vue.set(state.user.profile, "instructorId", profile.instructorId);
    Vue.set(state.user.profile, 'instructorCode', instructorCode)
    // Vue.set(state.user, "profile", profile);
  },

  [Mutations.SET_SUBSCRIPTION_STATUS](state, subscriptionStatus) {
    Storage.updateSubscriptionStatus(subscriptionStatus)
    Vue.set(state.user.profile, 'subscriptionStatus', subscriptionStatus)
  },

  [Mutations.SET_SUBSCRIPTION_STATUS_STRIPE](state, subscriptionStatusStripe) {
    Storage.updateSubscriptionStatusStripe(subscriptionStatusStripe)
    Vue.set(state.user.profile, 'subscriptionStatusStripe', subscriptionStatusStripe)
  },

  [Mutations.SET_PAYOUT_STATUS](state, payoutStatus) {
    Storage.updatePayoutStatus(payoutStatus)
    Vue.set(state.user.profile, 'payoutStatus', payoutStatus)
  }
}

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters
}
