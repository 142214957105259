<template>
  <v-list-item :to="link" v-bind:class="classObject">
    <v-list-item-action>
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>{{ text }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
//import Router from '@/router'
import { mapState } from 'vuex'
import { mapMutations } from 'vuex'

export default {
  props: ['icon', 'text', 'link'],
  data() {
    return {}
  },
  computed: {
    ...mapState('global', ['activeDrawerItem']),
    classObject() {
      return '' //this.activeDrawerItem == this.link?'primary':''
    }
  },
  methods: {
    ...mapMutations('global', ['setActiveDrawerItem'])

    // navigate() {
    //   this.setActiveDrawerItem(this.link)
    //   //this.$store.commit('setActiveDrawerItem', {val:'tes'})//this.link)
    //   Router.push({ path: this.link })
    // }
  }
}
</script>

<style scoped></style>
