<template>
  <div>
    <v-layout flex align-center justify-center>
      <v-flex>
        <div class="pa-12 white elevation-0" v-if="!bankFormSaved">
          <v-sheet class="pa-4 grey--text lighten-3" v-show="isBankDisabled && hasBankDetails">
            <v-layout row>
              <h4 class="mr-4">Account Numbers (Last 4 digits):</h4>
              {{ bank.accountNumber }}
            </v-layout>
            <v-layout row>
              <h4 class="mr-4">Sort Code:</h4>
              {{ bank.sortCode }}
            </v-layout>
            <v-layout row>
              <v-btn v-if="hasBankDetails" @click="editBank" class="my-2" color="primary" :disabled="isEditBankDisabled" outlined>
                <v-icon>fas fa-pen mr-4 small-icon</v-icon>Edit
              </v-btn>
            </v-layout>
          </v-sheet>
          <v-form v-model="validBankForm" ref="bankForm" v-show="!isBankDisabled || !hasBankDetails">
            <v-alert dense outlined type="warning" v-if="hasBankDetails">
              If you update your bank details you will have to wait a period of time for your new bank details to be approved. During that time you will not be able to
              able to recieve payment through this platform
            </v-alert>
            <p>Bank details for the account you want money to be paid into.</p>
            <v-layout row wrap class="mt-2">
              <v-flex xs12>
                <v-text-field
                  :disabled="submittedBank"
                  v-model="newbank.bank_account.account_number"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                  prepend-icon="fas fa-credit-card"
                  label="Bank Account Number"
                  required
                  :rules="accountNumberRules"
                ></v-text-field>
              </v-flex>
              <!-- <v-flex lg1 hidden-xs-and-down></v-flex> -->
              <v-flex xs12>
                <!-- newbank.bank_account.routing_number -->
                <v-text-field
                  :disabled="submittedBank"
                  v-model="sortValue"
                  prepend-icon="fas fa-credit-card"
                  label="Sort Code"
                  v-mask="sortMask"
                  required
                  :rules="routingNumberRules"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-btn
              class="ma-2"
              v-show="!isBankDisabled || !hasBankDetails"
              @click="saveBankData"
              :disabled="submittedBank"
              :class="{ 'primary  white--text': validBankForm, disabled: !validBankForm }"
              >Save</v-btn
            >
            <v-btn class="ma-2" v-show="!isBankDisabled" text @click="cancelBank" :disabled="submittedBank" :class="{ disabled: !validBankForm }">Cancel</v-btn>
            <v-progress-circular v-if="submittedBank" indeterminate color="primary"></v-progress-circular>
            <v-btn v-if="isDevelopment" @click="success">success</v-btn>
            <!--<v-btn @click="noaccount">no_account</v-btn>
            <v-btn @click="accountclosed">account_closed</v-btn>
            <v-btn @click="insufficient_funds">insufficient_funds</v-btn>
            <v-btn @click="debit_not_authorized">debit_not_authorized</v-btn>
            <v-btn @click="invalid_currency">invalid_currency</v-btn> -->

            <FormErrors :errors="bankErrors" />
          </v-form>
        </div>
        <div v- v-if="bankFormSaved">
          <div class="d-flex justify-start align-start">
            <v-icon>fas fa-check-circle mr-4 success--text </v-icon>
            <div>
              <h4>Bank details saved</h4>
            </div>
          </div>
        </div>
        <!-- <SuccessTick v-if="bankFormSaved">Bank details saved</SuccessTick> -->
        <!-- <pre>{{ this.hasBankDetails }}</pre>
        <pre>{{ newbank }}</pre>
        <pre>{{ sortValue }}</pre>
        <pre>{{ sortUnmasked }}</pre> -->
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
// import stripeService from '@/services/generated/stripe.service.gen'
import bankAccountService from '@/services/generated/bankAccount.service.gen'
//import StripeAPI from '../../../../services/stripe.service'
//import UploadImage from '../../../../components/Shared/UploadImage'
//import moment from 'moment'
//import { PayoutStatus } from '../../../../helpers/enums'
//import ProfileAPI from '@/services/profile.service'
//import SetupPaymentsMessages from './SetupPaymentsMessages'
import _ from 'lodash'
//import InstructorAPI from '../../../../services/instructor.service'
// import { PayoutStatus } from '../../../../helpers/enums'
//import { BankPayoutStatus } from '../../../../helpers/enums'

import { mask } from 'vue-the-mask'
export default {
  directives: {
    mask
  },
  props: ['bank'],
  components: {
    //UploadImage
  },
  data() {
    return {
      sortMask: '##-##-##',
      sortValue: '',
      bankAccountNumber: '',
      e6: 1,
      validBankForm: false,
      submittedBank: false,

      isBankDisabled: true,

      isEditBankDisabled: false,

      tempBank: null,
      bankCopy: this.bank,
      bankFormSaved: false,

      bankErrors: [],
      accountToken: '',
      accountNumberRules: [v => !!v || 'Bank account number is required'],
      routingNumberRules: [v => !!v || 'Sort code is required'],
      payoutStatus: '',
      newbank: {
        bank_account: {
          country: 'GB',
          currency: 'gbp',
          // account_holder_name: '',
          account_holder_type: 'individual',
          routing_number: '',
          account_number: ''
        }
      }
    }
  },
  computed: {
    isDevelopment() {
      return process.env.NODE_ENV == 'development'
    },
    user() {
      return this.$store.state.auth.user
    },
    sortUnmasked() {
      return this.unmask(this.sortValue, this.sortMask) // str.replace('-', '')
    },
    hasBankDetails() {
      if (this.bank.accountNumber) {
        return true
      }
      return false
    }
  },
  methods: {
    unmask(maskedValue, mask) {
      let defaultTokens = ['#', 'X', 'S', 'A', 'a', '!']
      let unmaskedValue = ''
      let isToken

      for (let i = 0; i < maskedValue.length; i++) {
        isToken = false
        for (let j = 0; j < defaultTokens.length; j++) {
          if (mask[i] == defaultTokens[j]) {
            isToken = true
          }
        }

        if (isToken) {
          unmaskedValue += maskedValue[i]
        }
      }

      return unmaskedValue
    },
    saveBankData() {
      if (this.$refs.bankForm.validate()) {
        this.submittedBank = true

        bankAccountService
          .createBankAccount(this.sortValue, this.newbank.bank_account.account_number)
          .then(({ data }) => {
            console.log('daaata:', data)
            this.reloadData()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.submittedBank = false
            this.isEditDisabled = false
            this.isEditBankDisabled = false
          })
      }
    },

    makeCopies() {
      let copy = _.cloneDeep(this.bank)
      this.tempBank = copy
    },

    editBank() {
      this.makeCopies()

      this.isBankDisabled = false
      this.isEditBankDisabled = true

      // setTimeout(function() {
      //   window.scrollTo(0, document.body.scrollHeight)
      // }, 30)

      //this.cancelPersonal()
    },

    cancelBank() {
      this.modelBank = this.tempBank //.firstName +' kkk'
      this.tempBank = null
      this.isBankDisabled = true
      this.isEditBankDisabled = false
    },

    reloadData() {
      //this.$parent.reloadData()
      this.$emit('reloadData') //.$parent.reloadData()
    },

    success() {
      this.newbank.bank_account.routing_number = '108800'
      this.sortValue = '108800'

      this.newbank.bank_account.account_number = '00012345'
    }
  },
  created() {
    console.log(this.$parent)
    //this.$parent.reloadData()
    //this.reloadData()
  }
}
</script>
