//admin.service.gen.js
// import adminService from '@/services/generated/admin.service.gen'
import axios from 'axios'

export default {
  status() {
    return axios.get('/api/admin/status')
  },

  /*
					adminService.status()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getUsers(type, pageNumber, pageSize) {
    return axios.get('/api/admin/users', {
      params: {
        type: type,
        ...(pageNumber ? { pageNumber: pageNumber } : {}),
        ...(pageSize ? { pageSize: pageSize } : {})
      }
    })
  },

  /*
					adminService.getUsers(type, pageNumber, pageSize)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getUser(userId) {
    return axios.get(`/api/admin/user/${userId}`)
  },

  /*
					adminService.getUser(userId)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  banUser(userId) {
    var model = {
      userId: userId
    }
    return axios.post('/api/admin/ban-user', model)
  },

  /*
					adminService.banUser(userId)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  enablePayments(userId) {
    var model = {
      userId: userId
    }
    return axios.post('/api/admin/enable-payments', model)
  },

  /*
					adminService.enablePayments(userId)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  enableEligible(userId) {
    var model = {
      userId: userId
    }
    return axios.post('/api/admin/enable-eligible', model)
  },

  /*
					adminService.enableEligible(userId)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  disablePayments(userId) {
    var model = {
      userId: userId
    }
    return axios.post('/api/admin/disable-payments', model)
  },

  /*
					adminService.disablePayments(userId)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  modifyUserEmail(userId) {
    var model = {
      userId: userId
    }
    return axios.post('/api/admin/modify-user-email', model)
  },

  /*
					adminService.modifyUserEmail(userId)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  createCounterPartyForUser(userId) {
    var model = {
      userId: userId
    }
    return axios.post('/api/admin/create-counterparty-for-user', model)
  },

  /*
					adminService.createCounterPartyForUser(userId)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getCounterPartyForUser(userId) {
    return axios.get('/api/admin/get-counterparty-for-user', {
      params: {
        userId: userId
      }
    })
  },

  /*
					adminService.getCounterPartyForUser(userId)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  createPayment(instructorId, paymentId, studentId, amountPence) {
    var model = {
      instructorId: instructorId,
      paymentId: paymentId,
      studentId: studentId,
      amountPence: amountPence
    }
    return axios.post('/api/admin/create-payment', model)
  },

  /*
					adminService.createPayment(instructorId, paymentId, studentId, amountPence)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getInstructorPayments(instructorId, order, pageNumber, pageSize) {
    return axios.get('/api/admin/get-user-payments', {
      params: {
        instructorId: instructorId,
        order: order,
        ...(pageNumber ? { pageNumber: pageNumber } : {}),
        ...(pageSize ? { pageSize: pageSize } : {})
      }
    })
  }
  /*
					adminService.getInstructorPayments(instructorId, order, pageNumber, pageSize)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
