<template>
  <div class="" :class="contactsClassObject">
    <div class="px-4 grey lighten-2">
      <!-- style="position:absolute; top:0; left:0; right:0; z-index:2"> -->
      <v-autocomplete
        v-if="$vuetify.breakpoint.xs"
        v-model="userSelected"
        :items="contacts"
        chips
        item-text="fullName"
        item-value="userId"
        prepend-icon="fas fa-user"
        @change="userChanged"
      >
        <template v-slot:selection="data">
          <v-chip :input-value="data.selected" class="chip--select-multi">
            <v-avatar left>
              <img :src="data.item.profilePicture | asProfilePicture" />
            </v-avatar>
            {{ data.item.fullName }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <template>
            <v-list-item-avatar>
              <img :src="data.item.profilePicture | asProfilePicture" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                >{{ data.item.fullName }} <span style="float:right" v-if="data.item.unreadMessageCount > 0">({{ data.item.unreadMessageCount }})</span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </div>

    <ul class="pa-0" v-if="!$vuetify.breakpoint.xs">
      <li v-for="contact in contacts" :key="contact.userId" @click="selectContact(contact.userId)" class="pointer" :class="getClassObject(contact.userId)">
        <div class="d-flex justify-start align-center ">
          <AvatarName :name="contact.fullName" :imageUrl="contact.profilePicture" :size="'md'" :isClickable="false" :isBacked="false" />

          <v-badge color="green" overlap v-if="contact.unreadMessageCount > 0">
            <template v-slot:badge>
              <span>{{ contact.unreadMessageCount }}</span>
            </template>

            <v-icon large>far fa-envelope</v-icon>
          </v-badge>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {
    //StudentsItem
    //  StudentsPaging
  },
  data() {
    return {
      userSelected: ''
    }
  },
  computed: {
    contacts() {
      return this.$store.getters['chat/contacts']
    },
    selectedContact() {
      return this.$store.getters['chat/selectedContact']
    },

    contactsClassObject() {
      return {
        'contacts min-100 max-parent scrollable  hidden-sm-and-down': !this.$vuetify.breakpoint.xs
      }
    },
    user() {
      return this.$store.state.auth.user
    },
    isInstructor() {
      return this.user.role == 'Instructor'
    },
    isStudent() {
      return this.user.role == 'Student'
    }
  },
  methods: {
    userChanged() {
      this.selectContact(this.userSelected)
    },
    // fetchContacts() {
    //   this.$store
    //     .dispatch('chat/fetchContacts')
    //     .then(response => {
    //       //this.$store.commit("global/setLoggedIn", true);
    //       //this.$router.push({ name: "root" });
    //       console.log(response)
    //     })
    //     .catch(error => {
    //       console.log('TCL: fetchContacts -> error', error)
    //     })
    // },

    selectContact(contactId) {
      this.userSelected = contactId
      this.$store.commit('chat/resetMessages')
      this.$store.commit('chat/setSelectedContact', contactId)
      this.$store.commit('chat/setSelectedContactUnreadCount')

      this.$store.dispatch('chat/fetchConversation').then(() => {
        this.scrollDown()
      })
    },

    scrollDown() {
      setTimeout(function() {
        var objDiv = document.getElementById('conversation')
        objDiv.scrollTop = objDiv.scrollHeight
      }, 30)
    },

    getClassObject(userId) {
      return this.selectedContact == userId ? 'primary white--text' : ''
    }
  },

  mounted() {
    //this.fetchContacts()

    if (this.isStudent) {
      let instructorId = this.user.profile.instructorId
      this.selectContact(instructorId)
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.contacts {
  width: 100%;
  background: #ababab;

  li {
    padding: 10px;
    background: #ededed;
    border: solid 1px #ddd;
  }

  li:hover {
    background: #ddd;
  }
}
</style>
