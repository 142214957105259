//instructor.service.gen.js
// import instructorService from '@/services/generated/instructor.service.gen'
import axios from 'axios'

export default {
  generateCode() {
    return axios.get('/api/instructor/generate-code')
  },

  /*
					instructorService.generateCode()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getCode() {
    return axios.get('/api/instructor/code')
  },

  /*
					instructorService.getCode()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  setStatus(studentId, status) {
    var model = {
      studentId: studentId,
      status: status
    }
    return axios.post('/api/instructor/set-status', model)
  },

  /*
					instructorService.setStatus(studentId, status)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getStudents(type, order, status, pageNumber, pageSize) {
    return axios.get('/api/instructor/students', {
      params: {
        type: type,
        order: order,
        status: status,
        ...(pageNumber ? { pageNumber: pageNumber } : {}),
        ...(pageSize ? { pageSize: pageSize } : {})
      }
    })
  },

  /*
					instructorService.getStudents(type, order, status, pageNumber, pageSize)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  hasStudents() {
    return axios.get('/api/instructor/has-students')
  },

  /*
					instructorService.hasStudents()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getStudentsForAddLesson(type, order, status, pageNumber, pageSize) {
    return axios.get('/api/instructor/students-for-add-lesson', {
      params: {
        type: type,
        order: order,
        status: status,
        ...(pageNumber ? { pageNumber: pageNumber } : {}),
        ...(pageSize ? { pageSize: pageSize } : {})
      }
    })
  },

  /*
					instructorService.getStudentsForAddLesson(type, order, status, pageNumber, pageSize)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getStudent(studentId) {
    return axios.get(`/api/instructor/student/${studentId}`)
  },

  /*
					instructorService.getStudent(studentId)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  setStudentLessonCost(studentId, hourlyRate) {
    var model = {
      studentId: studentId,
      hourlyRate: hourlyRate
    }
    return axios.post('/api/instructor/set-student-lesson-cost', model)
  },

  /*
					instructorService.setStudentLessonCost(studentId, hourlyRate)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  setLicenceNumber(studentId, licenceNumber) {
    var model = {
      studentId: studentId,
      licenceNumber: licenceNumber
    }
    return axios.post('/api/instructor/set-student-licence-number', model)
  },

  /*
					instructorService.setLicenceNumber(studentId, licenceNumber)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  setPaymentStatus(isEnabled) {
    var model = {
      isEnabled: isEnabled
    }
    return axios.put('/api/instructor/set-payment-status', model)
  },

  /*
					instructorService.setPaymentStatus(isEnabled)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  setPaymentStatusRegisterInterest() {
    return axios.put('/api/instructor/set-payment-status-register-interest')
  },

  /*
					instructorService.setPaymentStatusRegisterInterest()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getPayoutStatus() {
    return axios.get('/api/instructor/payout-status')
  },

  /*
					instructorService.getPayoutStatus()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  setProgress(studentId, progessChecklistId, progressRating) {
    var model = {
      studentId: studentId,
      progessChecklistId: progessChecklistId,
      progressRating: progressRating
    }
    return axios.post('/api/instructor/set-student-progress', model)
  }
  /*
					instructorService.setProgress(studentId, progessChecklistId, progressRating)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
