<template>
  <div class="">
    <v-navigation-drawer class="accent" dark fixed v-model="drawer" app width="300">
      <SideBar />
    </v-navigation-drawer>

    <Toolbar>
      <template #bar>
        <v-app-bar-nav-icon size="10" style="font-size:10px" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </template>
    </Toolbar>

    <ContentPanel />
    <v-snackbar :top="true" :color="'error'" v-model="hasErrors" :timeout="5000" :multi-line="true">
      {{ errorMessage }}
    </v-snackbar>
    <!-- <v-footer color="grey lighten-2" app inset>
      <span class="white--text">&copy; 2017</span>
    </v-footer> -->
  </div>
</template>

<script>
import SideBar from '../components/Dashboard/SideBar'
import ContentPanel from '../components/Dashboard/ContentPanel'
import Toolbar from '../components/Dashboard/Toolbar'

export default {
  components: {
    SideBar,
    ContentPanel,
    Toolbar
  },
  data() {
    return {
      drawer: null
    }
  },
  computed: {
    hasErrors() {
      return this.$store.getters['global/hasError']
    },
    errorMessage() {
      return this.$store.getters['global/errorMessage']
    }
  },
  methods: {
    toggleDrawer() {
      console.log('aaaa')
      //this.toggleDrawer
      this.$store.commit('global/toggleDrawer')
    }
  }
}
</script>
