import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store/index'

//import axios from 'axios'

// or for a single instance
import infiniteScroll from 'vue-infinite-scroll'

//Vue.use(axios)
Vue.use(infiniteScroll)

import '@/assets/scss/_global.scss'

Vue.filter('asMoney', function(value, hideSign) {
  if (!value) return ''
  //value = value.toString()
  var price = parseFloat(Math.round(value * 100) / 100).toFixed(2)
  let poundSign = hideSign ? '' : '£'
  return poundSign + price.replace(/\.00$/, '')
})

Vue.filter('penceAsMoney', function(value) {
  if (!value) return ''
  //value = value.toString()
  var price = parseFloat(Math.round(value) / 100).toFixed(2)

  return '£' + price.replace(/\.00$/, '')
})

Vue.filter('asProfilePicture', function(value) {
  //console.log('TCL: value', value)
  if (value == null || value == '') {
    return require('./assets/images/user.png')
  }

  if (value && value.indexOf('random') !== -1) {
    return value
  }
  let path = 'https://instructorpay-uploads.s3.eu-west-2.amazonaws.com'
  return `${path}/${value}`

  //return `${location.protocol}//${location.host}/Avatars/${value}`
})

// Vue.use(Vuetify, {
//   theme: {
//     primary: '#FF5722',//colors.deepOrange,
//     secondary: colors.deepOrange.lighten4,
//     accent: '#000051' //'#0F1249',//colors.blue
//     // error: '#f44336',
//     // warning: '#ffeb3b',
//     // info: '#2196f3',
//     // success: '#4caf50'
//   },
//   options: {
//     customProperties: true
//   },
//   iconfont: 'fa'
// })
//axios.defaults.baseURL = 'https://localhost:44339'
import interceptor from './helpers/interceptor.js'
interceptor()

import '@/components'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
