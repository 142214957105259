<template>
  <MiscWrapper>
    <template #content>
      <v-layout row wrap justify-center>
        <v-flex xs12 lg8>
          <h1 class="mb-4">Contact Us</h1>

          <div v-if="!sent">
            <FormErrors :errors="errors" />
            <div>
              <v-form v-model="valid" ref="form">
                <v-text-field label="Enter your e-mail address" v-model="form.email" :rules="emailRules" required :disabled="submitted"></v-text-field>
                <v-textarea label="Message" v-model="form.message" :rules="messageRules" required :disabled="submitted"></v-textarea>
                <v-layout align-center>
                  <v-btn @click="submit" :disabled="submitted" class="mt-4" :class="{ 'primary white--text': valid, disabled: !valid }">Submit</v-btn>
                  <v-progress-circular size="18" width="2" v-if="submitted" indeterminate color="primary"></v-progress-circular>
                  <v-spacer></v-spacer>
                </v-layout>
              </v-form>
            </div>
          </div>

          <div class="pa-12 text-md-center" v-if="sent">
            <span class="title font-weight-light py-6">Thank you for getting in touch. We will aim to respond within two days.</span>
          </div>
          <!-- <h2>texst</h2>
          <pre>{{ test }}</pre> -->
        </v-flex>
      </v-layout>
    </template>
  </MiscWrapper>
</template>

<script>
import MiscWrapper from './MiscWrapper'
//import AuthAPI from '../../services/auth.service'
import authService from '@/services/generated/auth.service.gen'

//import axios from 'axios'

export default {
  components: {
    MiscWrapper
  },
  data() {
    return {
      sent: false,
      valid: false,
      e1: false,
      //form: { email: 'adamsolkar@gmail.com', message: 'some text' },
      form: { email: '', message: '' },
      errors: [],
      //emailRules: [v => !!v || 'E-mail is required', v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'],
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid'],
      messageRules: [v => !!v || 'A message is required'],
      submitted: false,
      test: []
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.submitted = true

        //AuthAPI.contact(this.form)
        authService
          .contact(this.form.email, this.form.message)
          .then(() => {
            this.sent = true
          })
          .catch(({ error }) => {
            this.submitted = false
            this.errors = error.data.errors
            console.log(error.data.errors)
          })
      }
    }
  },
  mounted() {
    // axios.get('/api/webhook/test').then(response => {
    //   this.test = response.data
    // })
  }
}
</script>
