<template>
  <v-layout accent wrap>
    <v-flex lg8 offset-lg2 xs12 pa-2>
      <router-link to="/">
        <img src="@/assets/images/Logo.png" style="width: 250px; height: auto;" />
      </router-link>

      <v-layout row wrap white pa-12>
        <slot name="content"></slot>
      </v-layout>
    </v-flex>
    <v-flex lg12>
      <Footer />
    </v-flex>
    <v-snackbar :top="true" :color="'error'" v-model="hasErrors" :timeout="5000" :multi-line="true">
      {{ errorMessage }}
    </v-snackbar>
  </v-layout>
</template>

<script>
import Footer from '../../components/Home/Footer'

export default {
  components: {
    Footer
  },
  computed: {
    hasErrors() {
      return this.$store.getters['global/hasError']
    },
    errorMessage() {
      return this.$store.getters['global/errorMessage']
    }
  }
}
</script>

<style lang="scss" scoped></style>
