<template>
  <v-layout justify-center class="header">
    <v-navigation-drawer class="accent" dark v-model="drawer" fixed app temporary>
      <v-list class="link-list">
        <v-list-item @click="scroll('#home')">
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="scroll('#demo')">
          <v-list-item-content>
            <v-list-item-title>Features</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="scroll('#pricing')">
          <v-list-item-content>
            <v-list-item-title>Pricing</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <div class="ma-6">
        <v-btn rounded to="/sign-up" color="white" class="mb-6 primary--text" block>Sign Up</v-btn>
        <v-btn rounded to="/login" color="primary" block>Login</v-btn>
      </div>
    </v-navigation-drawer>
    <!-- <v-app-bar appz scroll-target="#playground-example" color="transparent" class="elevation-0"  >
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon style="postition:absolute; left:0; top:0" class="primary" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar> -->

    <!-- <router-link to="/">
      <img src="@/assets/images/Logo.png" class="hidden-sm-and-up" style="width: 150px; height: auto;" />
      <img src="@/assets/images/Logo.png" class="hidden-xs-only" style="width: 250px; height: auto;" />
    </router-link> -->

    <v-spacer></v-spacer>

    <div class="mr-0">
      <v-btn
        rounded
        to="/sign-up"
        color="white"
        :small="$vuetify.breakpoint.xs"
        class="primary--text"
        lighten-3
        :class="{ 'mr-2': $vuetify.breakpoint.xs, 'mr-6': !$vuetify.breakpoint.xs }"
        >Sign Up</v-btn
      >
      <v-btn rounded to="/login" color="primary" class="mr-0" :small="$vuetify.breakpoint.xs">Login</v-btn>
    </div>
    <!-- <v-app-bar-nav-icon class="primary" style="visibility:hidden" @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->

    <div class="header1">
      <v-app-bar-nav-icon class="primary white--text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </div>
  </v-layout>

  <!-- <v-app-bar class="elevation-0" app   elevate-on-scroll
  
    color="accent"
      dark
      shrink-on-scroll
      prominent
      
      fade-img-on-scroll
  >
    <v-layout justify-center class="header">
      <router-link to="/">
        <img src="@/assets/images/Logo.png" class="hidden-sm-and-up" style="width: 150px; height: auto;" />
        <img src="@/assets/images/Logo.png" class="hidden-xs-only" style="width: 250px; height: auto;" />
      </router-link>

      <v-spacer></v-spacer>

      <v-btn rounded to="/sign-up" color="accent" :small="$vuetify.breakpoint.xs" lighten-3 :class="{ 'mr-2': $vuetify.breakpoint.xs, 'mr-6': !$vuetify.breakpoint.xs }"
        >Sign Up</v-btn
      >
      <v-btn rounded to="/login" color="primary" :small="$vuetify.breakpoint.xs">Login</v-btn>
    </v-layout>
  </v-app-bar> -->
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      items: [
        { title: 'Home', icon: 'dashboard' },
        { title: 'About', icon: 'question_answer' }
      ],
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic'
    }
  },
  methods: {
    scroll(target) {
      this.$vuetify.goTo(target, this.options)
      this.drawer = !this.drawer
    }
  },
  computed: {
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing
      }
    }
  }
}
</script>

<style scoped>
.link-list {
  margin-top: 100px;
  text-align: center;
  padding: 15px;
}

.header1 {
  position: fixed;
  padding: 12px;

  top: 0;
  left: 0;
  z-index: 99;
}
.header {
}
</style>
