<template>
  <div>
    <SuccessTick v-if="paidSuccessful" :isMini="true">Payment completed successfully</SuccessTick>

    <v-layout v-if="!paidSuccessful" row wrap grey lighten-3 rounded class="mt-4 pa-4">
      <v-flex lg12 xs12 class="pa-2">
        <label class="primary--text text--darken-4n cc-label" for="card-element">Credit or debit card</label>
      </v-flex>

      <!-- <v-flex v-if="paymentMethods" lg8 xs12 class="pa-2 d-flex column align-center">
        <pre>{{ this.paymentMethods }}</pre>
      </v-flex>
      <v-flex lg4 xs12 class="pa-2" v-if="paymentMethods">
        <v-btn
          id="card-button"
          block
          @click="payForLessonWithMethod"
          :disabled="submitted"
          :class="{ 'success white--text': hasLessonsSelected, disabled: !hasLessonsSelected }"
          >Pay With Method
          <v-spacer v-if="submitted"></v-spacer>
          <v-progress-circular v-if="submitted" size="16" width="2" indeterminate color="primary"></v-progress-circular>
        </v-btn>
      </v-flex> -->

      <v-flex lg8 xs12 class="pa-2 d-flex column align-center">
        <div id="card-element" style="width:100%">
          <!-- A Stripe Element will be inserted here. -->
        </div>

        <!-- Used to display form errors. -->
      </v-flex>
      <v-flex lg4 xs12 class="pa-2">
        <v-btn block @click="payForLesson" :disabled="submitted" :class="{ 'primary white--text': hasLessonsSelected, disabled: !hasLessonsSelected }"
          >Pay <span class="ml-4" v-if="amount">{{ amount | asMoney }}</span>
          <v-spacer v-if="submitted"></v-spacer>
          <v-progress-circular v-if="submitted" size="16" width="2" indeterminate color="primary"></v-progress-circular>
        </v-btn>
      </v-flex>

      <v-flex lg12>
        <FormErrors :errors="errors" />
      </v-flex>

      <!-- <v-flex lg12>
      <v-alert v-show="hasCardErrors" :value="true" type="error">
        <div id="card-errors" role="alert"></div>
      </v-alert>
      </v-flex>-->
      <!-- <pre>{{lessons}}</pre> -->
      <!-- <pre>{{ this.valid }}</pre> -->

      <!-- <button @click="payForLesson">Submit Payment</button> -->
    </v-layout>
  </div>
</template>

<script>
import stripeService from '@/services/generated/stripe.service.gen'

//import StripeAPI from '@/services/stripe.service'

//card = undefined

export default {
  props: ['lessons', 'amount'],
  components: {},
  data() {
    return {
      lessonData: null,
      //lessons: [],
      stripe: {},
      card: {},
      hasCardErrors: false,
      errors: [],
      paidSuccessful: false,
      submitted: false,
      paymentMethods: [],
      selectedPaymentMethod: ''
    }
  },
  computed: {
    hasLessonsSelected() {
      return this.lessons && this.lessons.length > 0
    }
  },
  watch: {
    lessons() {
      this.errors = []
    }
  },
  methods: {
    payForLesson() {
      if (!this.hasLessonsSelected) {
        this.errors = ['Please select a lesson before attempting to pay']
        return
      }

      this.submitted = true
      this.$emit('submitting')

      this.stripe.createPaymentMethod('card', this.card).then(result => {
        console.log('1TCL: payForLesson -> result', result)

        if (result.error) {
          this.errors = [result.error.message]
          this.submitted = false
        } else {
          // Send paymentMethod.id to server
          // var model = {
          //   payment_method_id: result.paymentMethod.id,
          //   lessons: this.lessons
          // }
          //StripeAPI.confirmPayment(model).then(result => {
          stripeService.confirmPayment(result.paymentMethod.id, null, this.lessons, false).then(result => {
            console.log('2TCL: payForLesson -> result', result)
            // Handle server response (see Step 3)
            //result.json().then(json => {
            //  console.log('TCL: payForLesson -> json', json)
            this.handleServerResponse(result)
            //})
          })
        }
      })
    },

    payForLessonWithMethod() {
      if (!this.hasLessonsSelected) {
        this.errors = ['Please select a lesson before attempting to pay']
        return
      }

      this.submitted = true
      this.$emit('submitting')

      this.selectedPaymentMethod = this.paymentMethods[0].paymentMethodId
      // var model = {
      //   payment_method_id: this.selectedPaymentMethod,
      //   lessons: this.lessons,
      //   isExistingPaymentMethod: true
      // }
      //StripeAPI.confirmPayment(model).then(result => {
      stripeService.confirmPayment(this.selectedPaymentMethod, null, this.lessons, true).then(result => {
        console.log('2TCL: payForLesson -> result', result)
        // Handle server response (see Step 3)
        //result.json().then(json => {
        //  console.log('TCL: payForLesson -> json', json)
        this.handleServerResponse(result)
        //})
      })
    },

    handleServerResponse(response) {
      console.log('3TCL: handleServerResponse -> response', response)
      if (response.data.error) {
        // Show error from server on payment form
        this.errors = [response.data.error]
        //this.errors = [response.data.error.message]

        this.submitted = false
      } else if (response.data.requires_action) {
        console.log('TCL: handleServerResponse -> response.requires_action', response.data.requires_action)
        // Use Stripe.js to handle required card action
        this.handleAction(response.data)
      } else {
        // Show success message

        //-------------------

        //--------------------

        this.paidSuccessful = true
        setTimeout(() => {
          this.$emit('finished')
          this.paidSuccessful = false
        }, 3000)
      }
    },

    handleAction(response) {
      this.stripe.handleCardAction(response.payment_intent_client_secret).then(result => {
        console.log('4TCL: handleAction -> result', result)
        if (result.error) {
          // Show error in payment form
          this.errors = [result.error.message]
          this.submitted = false
        } else {
          // The card action has been handled
          // The PaymentIntent can be confirmed again on the server
          // var model = {
          //   payment_intent_id: result.paymentIntent.id,
          //   //payment_method_id: result.paymentIntent.payment_method,
          //   lessons: this.lessons
          // }
          //StripeAPI.confirmPayment(model)
          stripeService.confirmPayment(null, result.paymentIntent.id, this.lessons, false).then(confirmResult => {
            console.log('5TCL: handleAction -> confirmResult', confirmResult)
            this.handleServerResponse(confirmResult)
          })
        }
      })
    }
  },
  mounted() {
    // StripeAPI.getPaymentMethods().then(result => {
    //   console.log('TCL: mounted -> result', result)

    //   if (result.data.hasPaymentMethods) {
    //     this.paymentMethods = result.data.paymentMethods
    //   }
    // })

    var style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }

    // eslint-disable-next-line no-undef
    this.stripe = Stripe(process.env.VUE_APP_STRIPE_PK)
    var elements = this.stripe.elements()

    // Create an instance of the card Element.
    this.card = elements.create('card', { style: style })

    // Add an instance of the card Element into the `card-element` <div>.
    this.card.mount('#card-element')

    // Handle real-time validation errors from the card Element.
    this.card.addEventListener('change', event => {
      //this.hasCardErrors = false
      this.errors = []
      //var displayError = document.getElementById('card-errors')
      if (event.error) {
        //displayError.textContent = event.error.message
        //this.hasCardErrors = true
        this.errors = [event.error.message]
      } else {
        //displayError.textContent = ''
        this.errors = []
      }
    })
  }
}
</script>

<style scoped>
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.rounded {
  border-radius: 5px;
}

.cc-label {
  font-weight: bold;
}
</style>
