// export const PURGE_AUTH = "logOut";
// export const SET_AUTH = "setUser";
// export const SET_ERROR = "setError";
// export const UPDATE_TOKENS = "updateTokens";

export const Mutations = {
  PURGE_AUTH: 'purgeAuth',
  SET_AUTH: 'setAuth',
  SET_ERROR: 'setError',
  UPDATE_TOKENS: 'updateTokens',

  //LESSONS

  SET_LESSONS: 'setLessons',
  SET_STUDENT_LESSONS: 'setLessonsStudent',
  SET_PROFILE: 'setProfile',
  SET_PROFILE_PICTURE: 'setProfilePicture',
  SET_CODE: 'setCode',
  SET_SUBSCRIPTION_STATUS: 'setSubscriptionStatus',
  SET_SUBSCRIPTION_STATUS_STRIPE: 'setSubscriptionStatusStripe',
  SET_OVERALL_PROGRESS: 'setOverallProgress',

  SET_STUDENTS: 'setStudents',
  SET_PAYMENTS: 'setPayments',

  PUSH_PAYMENTID: 'pushPaymentId',
  POP_PAYMENTID: 'popPaymentId',

  SET_PAYOUT_STATUS: 'setPayoutStatus'
}
