<template>
  <v-layout wrap pa-2>
    <v-flex sm9 xs9 @click.self="viewLesson">
      <AvatarName :name="lesson.studentName" :imageUrl="lesson.profilePicture" :userId="lesson.studentId" :size="'md'" />
    </v-flex>
    <v-flex sm3 xs3 style="text-align:right" @click="viewLesson">
      <span class="title">{{ lesson.justTime24 }}</span>
      <br />
      <span>{{ lesson.justDateSlash }}</span>
    </v-flex>

    <v-flex sm12 class="pointer boxes" @click="viewLesson" mt-2>
      <v-layout align-center justify-space-between>
        <div class="secondaryd lighten-1 text-center subtitle-2">{{ lesson.friendlyDuration }} / {{ lesson.lessonCost | asMoney }}</div>
        <div class="">
          <v-btn class="white-button" v-if="canEdit && !lesson.isPaid" outlined small color="primary" :to="{ name: 'UpdateLesson', params: { lessonId: lessonId } }">
            edit <v-icon dark>fas fa-pen small-icon pl-2</v-icon>
          </v-btn>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn v-if="canEdit && lesson.isPaid" outlined small color="primary" disabled=""> Edit <v-icon dark>fas fa-pen small-icon pl-2</v-icon> </v-btn>
              </div>
            </template>
            <span>Unable to edit, as this lesson has been paid</span>
          </v-tooltip>
          <v-chip color="red" v-if="!lesson.isPaid && !canEdit" class="ip-chip" text-color="white" @click="viewLesson">
            <v-icon left>fas fa-times-circle</v-icon> Unpaid
          </v-chip>

          <v-chip color="green" v-if="lesson.isPaid && !canEdit" class="ip-chip" text-color="white" @click="viewLesson">
            <v-icon left>fas fa-check-circle </v-icon> Paid
          </v-chip>
        </div>
      </v-layout>
    </v-flex>

    <!-- <pre>{{lesson}}</pre> -->
  </v-layout>
</template>

<script>
import moment from 'moment'
export default {
  components: {},
  props: {
    lesson: {
      type: Object
    }
  },
  data() {
    return {
      type: ''
    }
  },
  computed: {
    canEdit() {
      return (this.type == '' || this.type == 'upcoming') && this.isInstructor
    },
    lessonId() {
      return this.lesson.id
    },
    date() {
      return moment(this.lesson.localStartDateTime).calendar()
    },
    startTime() {
      return this.lesson.id
    },
    user() {
      return this.$store.state.auth.user
    },
    isInstructor() {
      return this.user.role == 'Instructor'
    },
    isStudent() {
      return this.user.role == 'Student'
    }
  },
  methods: {
    viewLesson() {
      this.$router.push({ name: 'ViewLesson', params: { lessonId: this.lessonId } })
    }
  },
  created() {
    this.type = this.$route.query.type ? this.$route.query.type : ''
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'digital-clock-font';
  src: url('../../../../assets/fonts/digital/digital-7.ttf');
}

.boxes {
  font-size: 1.2em;
  font-weight: bold;
}

.standard .boxes {
  font-size: 1.5em;
  font-weight: bold;
}
</style>
