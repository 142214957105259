<template>
  <v-container class="home-heroX white" container--fluid :class="home2" fill-heightX :align="'center'">
    <v-layout text-center wrap class="content-block">
      <v-flex xs12 sm6 md6 data-aos="fade-up-right">
        <v-layout class="ip-text" column fill-height justify-center align-end="">
          <h2>Lets Start this Journey</h2>
          <h4 class="display-1 mt-2">
            Sign up today or login if you already have an account
          </h4>
          <div class="mt-12">
            <v-btn rounded to="/login" large color="primary" class="mr-4">Login</v-btn>
            <v-btn rounded to="/sign-up" large color="accent" class="ml-4">Sign Up</v-btn>
          </div>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm6 md6 pa-12 data-aos="fade-up-left">
        <img src="@/assets/images/undraw_by_my_car_ttge.svg" />
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
//import SVGImage from './SVG/Home1'
//import HomeRight from './HomeRight'

export default {
  data: () => ({}),
  computed: {
    home2() {
      return {
        'home2-image-xs': this.$vuetify.breakpoint.smAndDown,
        'home2-image': this.$vuetify.breakpoint.mdAndUp
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-heroX {
  padding: 100px 0px;
}

.home2-image-xs {
  text-align: center;
  img {
    width: 100%;
    height: auto;
  }
  .ip-text {
    h2 {
      font-size: 2rem;
    }

    h4 {
      font-size: 1.4rem;
    }
  }
}
.home2-image {
  img {
    width: 80%;
    height: auto;
  }
  .ip-text {
    text-align: left;
    h2 {
      text-align: left;
      font-size: 3.75rem;
    }

    h4 {
      font-size: 2.1rem;
      margin-right: 20px;
    }
  }
}

.home-hero {
  min-height: 100vh;
  width: 100vw;
  background: #fff;
}

.fas {
  font-size: 30vw;
}
</style>
