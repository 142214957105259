<template>
  <v-col lg="6" sm="12" style="border-bottom: 1px dashed #ddd">
    <div class="d-flex justify-start align-center pa-2">
      <div style="width:320px;" class="text-right	subtitle-2 ">
        {{ text }}
      </div>

      <v-rating
        v-model="rating"
        :length="5"
        :empty-icon="emptyIcon"
        :full-icon="fullIcon"
        :size="24"
        :dense="false"
        :color="getColor(studentProgressListItem.progressRatingValue)"
        :background-color="bgColor"
        @input="ratingSelected"
        :readonly="readonly"
        :hover="hover"
      >
      </v-rating>
    </div>
  </v-col>
</template>

<script>
//import InstructorAPI from '@/services/instructor.service'
import { Actions } from '@/store/actions.type'

export default {
  props: ['studentProgressListItem', 'studentId'],

  data: () => ({
    rating: 0,
    emptyIcon: 'far fa-star',
    fullIcon: 'fas fa-star',
    colors: ['red', 'orange', '#ead84d', '#abc123', 'green'],
    bgColor: 'grey lighten-1',
    progressText: ['Introduced', 'Under Full Instruction', 'Prompted', 'Seldom Prompted', 'Independant']
  }),
  computed: {
    readonly() {
      return this.isStudent
    },
    hover() {
      return this.isInstructor
    },
    user() {
      return this.$store.state.auth.user
    },
    isInstructor() {
      return this.user.role == 'Instructor'
    },
    isStudent() {
      return this.user.role == 'Student'
    },
    text() {
      return this.toTitleCase(this.studentProgressListItem.text)
    }
  },
  methods: {
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    },
    getColor() {
      return this.colors[this.rating - 1]
    },
    ratingSelected(rating) {
      if (this.isInstructor) {
        let checkListId = this.studentProgressListItem.checkListId

        var model = {}
        model.studentId = this.studentId
        model.progessChecklistId = checkListId
        model.progressRating = rating

        this.$store
          .dispatch('global/' + Actions.SET_PROGRESS, model)
          .then(response => {
            console.log(response)
          })
          .catch(error => {
            console.log(error.response)
          })
      }
    }
  },
  created() {
    this.rating = this.studentProgressListItem.progressRatingValue
  }
}
</script>

<style lang="scss" scoped></style>
