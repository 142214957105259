<template>
  <v-container class="home-hero full-parent" container--fluid style="position:relative; " :class="home" id="pricing">
    <v-layout align-center justify-center row wrap>
      <div class="slanted-bg">
        <v-layout wrap class="unslant">
          <v-flex xs12 sm6 md6 lg6 class="ip-img  hidden-xs-only hidden-md-only " align-center>
            <v-layout justify-center :fill-height="$vuetify.breakpoint.xs" data-aos="fade-up-right">
              <img class="" src="@/assets/images/undraw_mobile_pay_9abj.svg" />
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 md12 lg6 order-xs2 order-sm1 pa-6>
            <v-layout class="ip-text" column fill-height justify-centerz align-center>
              <div class="d-flex flex-column justify-start align-center text-center" data-aos="fade-up-left">
                <h2 class="white--text font-weight-bold mb-2 ">Pricing</h2>

                <v-row justify-center>
                  <v-col cols="12" md="6" lg="6" xl="6">
                    <v-card class="pricing-card text-center" max-width="444" :class="pricing">
                      <v-card-text>
                        <p class="display-1 text--primary">
                          Instructors
                        </p>
                        <p class=" price display-4 text--primary">{{ price }}</p>
                        <p class="text--primary subtitle-2">per month (after free 30 day trial)</p>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn block large color="primary block" to="/sign-up">
                          SIGN UP For Free 30 day trial
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6" lg="6" xl="6">
                    <v-card class="pricing-card text-center" max-width="444" :class="pricing">
                      <v-card-text>
                        <p class=" display-1 text--primary">
                          Learners
                        </p>
                        <p class=" price display-4 text--primary">FREE</p>
                        <p class="text--primary subtitle-2">Always</p>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn block large color="primary block" to="/sign-up">
                          SIGN UP
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-layout>
          </v-flex>

          <!-- <v-flex sm6 lg6 xs12 order-xs2 order-sm1 pa-6>
            <v-layout class="ip-text" column fill-height justify-center>
              <h2 class="white--text font-weight-bold">Progression</h2>
              <h4 class="white--text mt-2">
                Keep track of progress with learners. Create notes against lessons and know when lessons have been paid
              </h4>
            </v-layout>
          </v-flex>
          <v-flex sm6 lg6 xs12 order-xs1 order-sm2 class="ip-img" align-center="">
            <v-layout justify-center :fill-height="$vuetify.breakpoint.xs">
              <img src="@/assets/images/undraw_booking_33fn.svg" />
            </v-layout>
          </v-flex> -->
        </v-layout>
      </div>
    </v-layout>
  </v-container>
</template>
<script>
//import Header from '../../components/Home/Header'

export default {
  components: {
    //Header
  },
  data: () => ({}),
  computed: {
    home() {
      return {
        'home-image-xs': this.$vuetify.breakpoint.smAndDown,
        'home-image': this.$vuetify.breakpoint.mdAndUp
      }
    },
    pricing() {
      return {
        xs: this.$vuetify.breakpoint.smAndDown
      }
    },
    price() {
      return process.env.VUE_APP_MONTHLY_COST
    }
  }
}
</script>

<style lang="scss" scoped>
.pricing-card {
  min-width: 360px;
  padding: 0px;
  .display-1 {
    padding: 0px;
    margin: 40px 0px;
  }
  .price.display-4 {
    padding: 20px;
    margin: 60px 0px;
  }
  &.xs {
    .display-1 {
      padding: 0px;
      margin: 0px 0px;
    }
    .display-4 {
      padding: 0px;
      margin: 0px 0px;
      font-size: 3rem !important;
      color: #15476b;
    }
  }
}
.home-image-xs {
  text-align: center;
  .ip-img {
  }
  img {
    width: 80%;
    height: auto;
    margin-top: 30px;
  }
  .ip-text {
    h2 {
      font-size: 2rem;
    }

    h4 {
      font-size: 1.4rem;
    }
  }
}
.home-image {
  img {
    width: 80%;
    height: auto;
  }
  .ip-text {
    text-align: left;
    padding: 30px;

    h2 {
      font-size: 3.75rem;
    }

    h4 {
      font-size: 2.1rem;
    }
  }
}
.home-hero {
  background: #fff;
}

.full-parent {
  min-height: 100vh;
}

.svg-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.slanted-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-image: linear-gradient(150deg, #FF8A65 15%, 70%, #FF5722 94%);
  background-image: linear-gradient(150deg, #0f1249 15%, 70%, #15476b 94%);

  /* -webkit-transform: skewY(-12deg); */
  transform: skewY(-12deg);
  -webkit-animation: Gradient 52s ease infinite;
  animation: Gradient 52s ease infinite;
}

.unslant {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: skewY(12deg);
}
</style>
