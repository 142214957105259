//bankAccount.service.gen.js
// import bankAccountService from '@/services/generated/bankAccount.service.gen'
import axios from 'axios'

export default {
  getBankAccount() {
    return axios.get('/api/bankaccount')
  },

  /*
					bankAccountService.getBankAccount()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  updateBankAccount(sortCode, accountNumber) {
    var model = {
      sortCode: sortCode,
      accountNumber: accountNumber
    }
    return axios.put('/api/bankaccount', model)
  },

  /*
					bankAccountService.updateBankAccount(sortCode, accountNumber)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  createBankAccount(sortCode, accountNumber) {
    var model = {
      sortCode: sortCode,
      accountNumber: accountNumber
    }
    return axios.post('/api/bankaccount', model)
  }
  /*
					bankAccountService.createBankAccount(sortCode, accountNumber)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
