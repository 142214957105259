// export const LOGIN = "login";
// export const LOGOUT = "logout";
// export const REGISTER = "register";
// export const UPDATE_USER = "updateUser";
// export const REFRESH = "refresh";
// export const CHANGE_PASSWORD = "changePassword";
// export const CONFIRM_EMAIL = "confirmEmail";
// export const FORGOT_PASSWORD = "forgotPassword";
// export const RESET_PASSWORD = "resetPassword";

// const nameSpaces = ["/auth", "/lesson"];

export const Actions = {
  // <-- public
  // getters: {
  //   //GET_FIRST_THING: "things/GET_FIRST_THING",
  //   //GET_SECOND_THING: "things/GET_SECOND_THING"
  // },
  // mutations: {
  //   // AUTH
  //   PURGE_AUTH: "purgeAuth",
  //   SET_AUTH: "setAuth",
  //   SET_ERROR: "setError",
  //   UPDATE_TOKENS: "updateTokens"
  // },
  //actions: {
  // AUTH
  LOGIN: 'login',
  LOGOUT: 'logout',
  REGISTER: 'register',
  UPDATE_USER: 'updateUser',
  REFRESH: 'refresh',
  CHANGE_PASSWORD: 'changePassword',
  CONFIRM_EMAIL: 'confirmEmail',
  FORGOT_PASSWORD: 'forgotPassword',
  RESET_PASSWORD: 'resetPassword',
  GET_SUBSCRIPTION: 'getSubscription',

  //}

  // LESSONS

  GET_INSTRUCTOR_LESSONS: 'getInstructorLesssons',
  ADD_LESSON: 'addLesson',
  GET_STUDENT_LESSONS: 'getStudentLesssons',

  //GET_INSTRUCTOR_CALENDAR:"getInstructorCalendar"

  //INSTRUCTOR
  GET_STUDENTS: 'getStudents',
  GET_STUDENT: 'getStudent',
  SET_PROGRESS: 'setProgress',

  //STUDENT
  PAIR_WITH_INSTRUCTOR: 'pairWithInstructor',

  GET_PAYMENTS: 'getPayments',
  GET_PAYMENTS_BY_STUDENT: 'getPaymentsBySTudent',
  GET_STUDENT_PAYMENTS_SELF: 'getPaymentsByStudent'
}

// export const _types = removeNamespace("things/", Types);

// function removeNamespace(namespace, types) {
//   return _.reduce(
//     types,
//     (typeObj, typeValue, typeName) => {
//       typeObj[typeName] = _.reduce(
//         typeValue,
//         (obj, v, k) => {
//           nameSpaces.forEach(element => {
//             obj[k] = v.replace(element, "");
//           });
//           //obj[k] = v.replace(namespace, "");
//           return obj;
//         },
//         {}
//       );
//       return typeObj;
//     },
//     {}
//   );
//}
