<template>
  <v-flex md4 sm6 xs12>
    <v-card class="pa-6 student-item" @click="viewStudent(studentId)">
      <!-- <pre>{{this.student}}</pre> -->
      <v-layout justify-space-between align-center>
        <div class="ml-4 my-4">
          <AvatarName :userId="student.studentId" :name="student.fullName" :size="'md'" :imageUrl="student.profilePicture" />
        </div>
        <v-chip class="ma-0 pointer" v-if="student.isNew" color="green" text-color="white">New</v-chip>
      </v-layout>
      <!-- <h4 class="ml-6">Next Lesson: {{nextLessonDate}}</h4> -->
      <v-divider class="dark my-4"></v-divider>

      <!-- <h4>Telephone: {{student.telephone}}</h4> -->
      <v-layout align-center justify-space-between row fill-height>
        <!-- <v-icon>fas fa-phone</v-icon> -->
        <h4>{{ student.telephone }}</h4>
        <!-- <v-btn v-if="isActiveStudent" class="mr-0" color="primary" outlined small :to="{ name: 'AddLesson', query: { student: student.studentId } }">
          <v-icon class="mr-4 plus-icon">fas fa-plus small-icon</v-icon>Add Lesson
        </v-btn> -->

        <LessonsAddButton v-if="isActiveStudent" :outlined="true" :small="true" :studentId="student.studentId" />
      </v-layout>

      <!-- 
      <div v-if="showStatusButtons" class="d-flex pa-2">
        <v-btn color="primary" text small @click="setStatus(1)">Approve</v-btn>
        <v-btn color="primary" text small @click="setStatus(3)">Deny</v-btn>
      </div>-->
    </v-card>
  </v-flex>
</template>

<script>
// 0    Pending,
// 1   Active,
// 2   Passed,
// 3   Removed,
// 4   StudentDeleted
//import InstructorAPI from '@/services/instructor.service'
import instructorService from '@/services/generated/instructor.service.gen'

import moment from 'moment'
import router from '@/router'
import LessonsAddButton from '../../Instructor/Lessons/LessonsAddButton'

export default {
  components: {
    LessonsAddButton
  },
  props: {
    student: {
      type: Object
    },
    status
  },
  data() {
    return {}
  },
  computed: {
    studentId() {
      return this.student.studentId
    },
    datePaired() {
      return moment(this.student.datePaired).calendar()
    },
    nextLessonDate() {
      return moment(this.student.nextLessonDateTime).calendar()
    },
    showStatusButtons() {
      return this.status == 'Pending'
    },

    isActiveStudent() {
      return this.status == 'Active' || this.status == ''
    }
    //  isNewStudents() {
    //   return moment(this.student.nextLessonDateTime).calendar()
    // },
  },
  methods: {
    viewStudent(studentId) {
      router.push({ name: 'StudentView', params: { studentId: studentId } })
    },
    setStatus(status) {
      // let model = {
      //   studentId: this.studentId,
      //   status: status
      // }
      //InstructorAPI.setStatus(model)
      instructorService
        .setStatus(this.studentId, status)
        .then(({ data }) => {
          console.log(data)

          alert('status set')
        })
        .catch(({ response }) => {
          console.log(response)
          alert('error')
        })
    }
  }
}
</script>

<style scoped>
.student-item {
  cursor: pointer;
}
</style>
