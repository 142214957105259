//import InstructorAPI from '@/services/instructor.service'
import { Actions } from './actions.type'
import { Mutations } from './mutations.type'
import instructorService from '@/services/generated/instructor.service.gen'

// initial state
const state = {
  drawer: true,
  activeDrawerItem: '',
  showCopiedCode: false,
  errorMessage: '',
  errorMessageDev: '',
  overallProgress: 0

  //isLoggedIn: false
}

// getters
const getters = {
  drawer: state => state.drawer,
  showCopiedCode: state => state.showCopiedCode,
  errorMessage: state => state.errorMessage,
  errorMessageDev: state => state.errorMessageDev,

  hasError(state) {
    return state.errorMessage != ''
  },
  overallProgress(state) {
    return state.overallProgress
  }
}

// actions
const actions = {
  [Actions.SET_PROGRESS](context, model) {
    return new Promise((resolve, reject) => {
      //InstructorAPI.setStudentProgress(model)
      instructorService
        .setProgress(model.studentId, model.progessChecklistId, model.progressRating)
        .then(({ data }) => {
          console.log(data)

          context.commit(Mutations.SET_OVERALL_PROGRESS, data.overallProgress)
          resolve(data)
        })
        .catch(({ response }) => {
          console.log(response)
          reject(response)
        })
    })
  }
}

// mutations
const mutations = {
  toggleDrawer(state, val) {
    state.drawer = val
    console.log('bbbb')
    //Vue.set(state, "drawer", val);
  },
  showCopiedCode(state, val) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(val)
      return
    }
    navigator.clipboard.writeText(val).then(
      () => {
        console.log('Async: Copying to clipboard was successful!')
        state.showCopiedCode = true
        setTimeout(() => {
          state.showCopiedCode = false
        }, 3000)
      },
      function(err) {
        console.error('Async: Could not copy text: ', err)
      }
    )
  },
  setError(state, errorMessage) {
    state.errorMessage = errorMessage
  },
  setErrorDev(state, errorMessage) {
    state.errorMessageDev = errorMessage
  },
  [Mutations.SET_OVERALL_PROGRESS](state, data) {
    //debugger
    state.overallProgress = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
