import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

//import Home from "@/views/Home.vue";
//import Dasboard from "@/views/Dasboard.vue";

import SignUp from '@/views/Auth/SignUp.vue'
import Login from '@/views/Auth/Login.vue'
import ForgotPassword from '@/views/Auth/ForgotPassword.vue'
import ResetPassword from '@/views/Auth/ResetPassword.vue'
import ChangePassword from '@/views/Auth/ChangePassword.vue'
import ConfirmEmail from '@/views/Auth/ConfirmEmail.vue'

import Main from '@/components/Home/Main.vue'

import GenericHome from '@/views/Dashboard/GenericHome'
import LessonAdd from '@/views/Dashboard/Instructor/Lessons/LessonAdd'
import LessonUpdate from '@/views/Dashboard/Instructor/Lessons/LessonUpdate'
import LessonView from '@/views/Dashboard/Common/LessonView'
import Calendar from '@/views/Dashboard/Common/Calendar'
import ChatView from '@/views/Dashboard/Common/Chat/ChatView'

import Profile from '@/views/Dashboard/Common/Profile'
import ProfileIndex from '@/views/Dashboard/UserDetails/Index'

import StudentsList from '@/views/Dashboard/Instructor/Students/Students'
// import StudentView from '@/views/Dashboard/Instructor/Students/StudentView'
import StudentView from '@/views/Dashboard/Common/StudentPage/StudentView'

import Code from '@/views/Dashboard/Instructor/Code'
import Subscription from '@/views/Dashboard/Instructor/Subscription'

import PairWithInstructor from '@/views/Dashboard/Student/PairWithInstructor'
import InstructorProfile from '@/views/Dashboard/Student/InstructorProfile'
import MakePayments from '@/views/Dashboard/Student/MakePayments'

import PaymentsList from '@/views/Dashboard/Instructor/Payments/Payments'
//import StudentPaymentsList from '@/views/Dashboard/Common/SpecificPayments/Payments'

//import StudentDetails from '@/views/Dashboard/Common/StudentPage/StudentView'

//import ViewPayment from "@/views/Dashboard/Instructor/Payments/ViewPayment";

//import SetupPayments from '@/views/Dashboard/Instructor/Payments/SetupPayments'
//import SetupPaymentsOnboarding from '@/views/Dashboard/Instructor/Payments/SetupPaymentsOnboarding'
import SetupPaymentsOnboardingSimple from '@/views/Dashboard/Instructor/Payments/SetupPaymentsOnboardingSimple'

import SetupPaymentsOnboardingSuccess from '@/views/Dashboard/Instructor/Payments/SetupPaymentsOnboardingSuccess'
import SetupPaymentsOnboardingFailure from '@/views/Dashboard/Instructor/Payments/SetupPaymentsOnboardingFailure'

import CompleteProfile from '@/views/Dashboard/UserDetails/CompleteProfile'
import Terms from '@/views/Misc/Terms'
import Privacy from '@/views/Misc/Privacy'
import Contact from '@/views/Misc/Contact'

import ErrorsPage from '@/views/Misc/ErrorsPage'
import Test from '@/views/Misc/Test'
import YCLogin from '@/views/Misc/YCLogin'

import AdminView from '@/views/Admin/AdminView'
import AdminUsers from '@/views/Admin/Users'
import AdminUser from '@/views/Admin/UserView'

//import AdminAPI from '@/services/admin.service'
import adminService from '@/services/generated/admin.service.gen'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

var routes = [
  {
    path: '/confirm-email',
    name: 'ConfirmEmail',
    components: { home: ConfirmEmail },
    meta: { isConfirmEmail: true }
  },
  { path: '/sign-up', name: 'SignUp', components: { home: SignUp } },
  { path: '/login', name: 'Login', components: { home: Login } },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    components: { home: ForgotPassword }
  },

  { path: '/reset-password', components: { home: ResetPassword } },

  // DASHBOARD
  // { path: '/profile/change-password', components: { dash: ChangePassword } },
  {
    path: '/lessons',
    name: 'Lessons',
    components: { dash: GenericHome },
    meta: { requiresAuth: true }
  },
  {
    path: '/lessons/add',
    name: 'AddLesson',
    components: { dash: LessonAdd },
    meta: { requiresAuth: true }
  },
  {
    path: '/update-lesson/:lessonId',
    name: 'UpdateLesson',
    components: { dash: LessonUpdate },
    meta: { requiresAuth: true }
  },

  {
    path: '/lesson/:lessonId',
    name: 'ViewLesson',
    components: { dash: LessonView },
    meta: { requiresAuth: true }
  },

  {
    path: '/student/:studentId',
    name: 'StudentView',
    components: { dash: StudentView },
    meta: { requiresAuth: true }
  },

  // {
  //   path: "/Payment/:paymentId",
  //   name: 'ViewPayment',
  //   components: { dash: ViewPayment }
  // },

  {
    path: '/settings',
    components: { dash: ProfileIndex },
    children: [
      // UserHome will be rendered inside User's <router-view>
      // when /user/:id is matched
      { path: '', component: Profile },
      {
        path: 'change-password',
        name: 'ChangePassword',
        component: ChangePassword
      },
      { path: 'code', name: 'Code', component: Code },
      { path: 'subscription', name: 'Subscription', component: Subscription }

      // ...other sub routes
    ],

    meta: { requiresAuth: true }
  },

  {
    path: '/students',
    name: 'Students',
    components: { dash: StudentsList },
    meta: { requiresAuth: true }
  },

  {
    path: '/payments',
    name: 'Payments',
    components: { dash: PaymentsList },
    meta: { requiresAuth: true }
  },
  //{ path: '/payments/:studentId', name: 'ViewStudentPayment', components: { dash: StudentPaymentsList }, meta: { requiresAuth: true } },
  //{ path: '/my-payments', name: 'ViewStudentPaymentSelf', components: { dash: StudentPaymentsList }, meta: { requiresAuth: true } },

  // { path: '/code', name: 'Code', components: { dash: Code }, meta: { requiresAuth: true } },
  {
    path: '/pair-with-instructor',
    name: 'PairWithInstructor',
    components: { dash: PairWithInstructor },
    meta: { requiresAuth: true }
  },
  {
    path: '/make-payments',
    name: 'MakePayments',
    components: { dash: MakePayments },
    meta: { requiresAuth: true }
  },

  {
    path: '/instructor',
    name: 'Instructor',
    components: { dash: InstructorProfile },
    meta: { requiresAuth: true }
  },

  {
    path: '/calendar',
    name: 'Calendar',
    components: { dash: Calendar },
    meta: { requiresAuth: true }
  },

  {
    path: '/messages',
    name: 'ChatView',
    components: { dash: ChatView },
    meta: { requiresAuth: true }
  },

  // {
  //   path: '/set-up-payments',
  //   name: 'SetupPayments',
  //   components: { dash: SetupPayments },
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '/set-up-payments',
  //   name: 'SetupPaymentsOnboarding',
  //   components: { dash: SetupPaymentsOnboarding },
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/set-up-payments',
    name: 'SetupPaymentsOnboardingSimple',
    components: { dash: SetupPaymentsOnboardingSimple },
    meta: { requiresAuth: true }
  },
  {
    path: '/set-up-payments/success',
    name: 'SetupPaymentsOnboardingSuccess',
    components: { dash: SetupPaymentsOnboardingSuccess },
    meta: { requiresAuth: true }
  },
  {
    path: '/set-up-payments/failure',
    name: 'SetupPaymentsOnboardingFailure',
    components: { dash: SetupPaymentsOnboardingFailure },
    meta: { requiresAuth: true }
  },
  {
    path: '/complete-profile',
    name: 'CompleteProfile',
    components: { dash: CompleteProfile },
    meta: { requiresAuth: true, isCompleteProfile: true }
  },

  {
    path: '/terms',
    name: 'Terms',
    components: { misc: Terms },
    meta: { isMisc: true }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    components: { misc: Privacy },
    meta: { isMisc: true }
  },
  {
    path: '/contact',
    name: 'Contact',
    components: { misc: Contact },
    meta: { isMisc: true }
  },

  {
    path: '/errors',
    name: 'Errors',
    components: { misc: ErrorsPage },
    meta: { isMisc: true }
  },
  {
    path: '/test',
    name: 'test',
    components: { misc: Test },
    meta: { isMisc: true }
  },
  {
    path: '/yclogin',
    name: 'test',
    components: { misc: YCLogin },
    meta: { isMisc: true }
  },

  {
    path: '/admin',
    name: 'AdminView',
    components: { dash: AdminView },
    meta: { requiresAuth: true, isAdminPage: true }
  },
  {
    path: '/admin/users',
    name: 'AdminUsers',
    components: { dash: AdminUsers },
    meta: { requiresAuth: true, isAdminPage: true }
  },
  {
    path: '/admin/users/:userId',
    name: 'ViewAdminUser',
    components: { dash: AdminUser },
    meta: { requiresAuth: true, isAdminPage: true }
  },

  //{ path: "/Dashboard", name: "home", component: Home },

  //{ path: "/", name: "home", component: Home },

  {
    path: '/',
    name: 'root',
    components: {
      //default: Foo,

      home: Main,
      dash: GenericHome
    },
    meta: { isRoot: true }
  },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  }
]

var router = new Router({
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  // const { authorize } = to.meta;
  // const currentUser = authenticationService.currentUserValue;

  // if (authorize) {
  //     if (!currentUser) {
  //         // not logged in so redirect to login page with the return url
  //         return next({ path: '/login', query: { returnUrl: to.path } });
  //     }

  //     // check if route is restricted by role
  //     if (authorize.length && !authorize.includes(currentUser.role)) {
  //         // role not authorised so redirect to home page
  //         return next({ path: '/' });
  //     }
  // }

  let isCompletePage = to.matched.some(record => record.meta.isCompleteProfile)
  let isConfirmEmail = to.matched.some(record => record.meta.isConfirmEmail)
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  let isRoot = to.matched.some(record => record.meta.isRoot)
  let isAuthenticated = store.state.auth.isAuthenticated
  let isProfileComplete = isAuthenticated && store.state.auth.user.profile.isProfileComplete

  let isAdminPage = to.matched.some(record => record.meta.isAdminPage)
  if (isAdminPage) {
    //AdminAPI.getStatus()
    adminService
      .status()
      .then(() => {
        next()
      })
      .catch(() => {
        next({
          name: 'root'
        })
      })
  } else {
    if (requiresAuth && isCompletePage && isAuthenticated) {
      next()
      return
    }

    if ((isRoot && isAuthenticated && !isProfileComplete) || (isConfirmEmail && isAuthenticated)) {
      next('/complete-profile')
      return
    }

    if (requiresAuth) {
      if (isAuthenticated) {
        if (isProfileComplete) {
          next()
          return
        } else {
          next('/complete-profile')
          return
        }
      } else {
        const loginpath = window.location.pathname
        next({ name: 'Login', query: { from: loginpath } })
        //next('/login')
      }
    } else {
      next()
    }
  }
})

export default router

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth) && !Auth.loggedIn) {
//     next({ path: '/login', query: { redirect: to.fullPath }});
//   } else {
//     next();
//   }
// });
