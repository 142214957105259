<template>
  <v-layout justify-center>
    <v-flex xs12 sm9 lg6>
      <v-card>
        <FormTitle>Profile Details</FormTitle>

        <FormErrors :errors="errors" />
        <div class="pa-4">
          <v-layout>
            <v-flex xs12>
              <v-alert :value="true" v-if="profileUpdated" type="success">This is a success alert.</v-alert>

              <v-form v-model="valid" ref="form">
                <v-text-field
                  :disabled="submitted"
                  id="firstName"
                  label="First Name"
                  prepend-icon="fas fa-address-card small-icon"
                  v-model="form.firstName"
                  :rules="firstNameRules"
                  required
                ></v-text-field>

                <v-text-field
                  :disabled="submitted"
                  id="lastName"
                  label="Last Name"
                  prepend-icon="fas fa-address-card small-icon"
                  v-model="form.lastName"
                  :rules="lastNameRules"
                  required
                ></v-text-field>

                <v-text-field
                  :disabled="submitted"
                  id="telephoneNumber"
                  label="Telephone Number"
                  v-model="form.telephoneNumber"
                  :rules="telephoneRules"
                  prepend-icon="fas fa-phone-square  small-icon"
                  required
                ></v-text-field>

                <!-- <v-text-field
                  v-if="isStudent"
                  label="Instructor Code"
                  v-model="form.instructorCode"
                  :rules="instructorCodeRules"
                  required
                ></v-text-field>-->

                <v-text-field
                  :disabled="submitted"
                  v-if="isInstructor"
                  type="number"
                  label="Standard hourly rate for lesson"
                  v-model="standardLessonFee"
                  :rules="hourlyRateRules"
                  prepend-icon="fas fa-pound-sign small-icon"
                  required
                ></v-text-field>

                <v-text-field
                  :disabled="submitted"
                  v-if="isInstructor"
                  label="Driving School Name"
                  v-model="drivingSchoolName"
                  prepend-icon="fas fa-car-side  small-icon"
                  required
                ></v-text-field>

                <v-radio-group v-if="isInstructor" v-model="transmission" label="Transmission" prepend-icon="fas fa-car  small-icon" :rules="transmissionRules">
                  <v-radio label="Manual" value="0"></v-radio>
                  <v-radio label="Automatic" value="1"></v-radio>
                  <v-radio label="Both" value="2"></v-radio>
                </v-radio-group>

                <v-layout justify-space-between>
                  <v-btn :disabled="submitted" @click="submit" :class="{ 'primary  white--text': valid, disabled: !valid }">Save</v-btn>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </div>
      </v-card>
      <v-btn v-if="isDevelopment" @click="quickFill">quick fill</v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
//import AuthService from "../../services/auth";
//import { LOGIN } from "@/store/actions.type";
//import { Types } from "../../store/actions.type";
import { Mutations } from '@/store/mutations.type'

// import DurationsDropdownList from '@/components/Shared/DurationDropDownList'
//import ProfileAPI from '@/services/profile.service'
import profileStudentService from '@/services/generated/profileStudent.service.gen'
import profileInstructorService from '@/services/generated/profileInstructor.service.gen'

import { mapGetters } from 'vuex'

export default {
  components: {
    // DurationsDropdownList,
  },
  data() {
    return {
      valid: false,
      submitted: false,
      e1: false,
      form: {
        firstName: '',
        lastName: '',
        telephoneNumber: ''
        //instructorCode: ''
      },

      firstNameRules: [v => !!v || 'First name is required'],
      lastNameRules: [v => !!v || 'Last name is required'],
      telephoneRules: [v => !!v || 'Telephone number is required'],
      instructorCodeRules: [v => !!v || 'Instructor code is required'],
      transmissionRules: [v => !!v || 'Select the transmission of the vehicle(s) you teach with'],

      hourlyRateRules: [v => !!v || 'Amount must be set', v => v > 10 || 'Amount must be more than 10', v => v < 35 || 'Amount must be less than 35'],
      errors: [],
      profileUpdated: false,

      ////////////////////////////////////////
      standardLessonFee: '',
      //standardLessonDuration: '',
      drivingSchoolName: '',
      transmission: ''
      ////////////////////////////////////////

      ///////////////////////////////////////
    }
  },
  computed: {
    isDevelopment() {
      return process.env.NODE_ENV == 'development'
    },
    HasErrors() {
      return this.errors.length != 0
    },
    ...mapGetters(['profile', 'isInstructor', 'isStudent'])
  },
  methods: {
    quickFill() {
      this.form.firstName = 'Tom'
      this.form.lastName = 'Jones'
      this.form.telephoneNumber = '01254 685456'

      this.standardLessonFee = '26.50'
      this.drivingSchoolName = 'Unusual school'

      this.submit()
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.submitted = true

        this.errors = []

        // var model = {
        //   firstName: this.form.firstName,
        //   lastName: this.form.lastName,
        //   telephoneNumber: this.form.telephone,
        //   standardLessonFee: this.form.hourlyRate,
        //   standardLessonDuration: this.form.duration,
        //   //profilePicture: this.form.profilePicture
        // };

        if (this.isInstructor) {
          this.form.standardLessonFee = this.standardLessonFee
          //this.form.standardLessonDuration = this.standardLessonDuration
          this.form.drivingSchoolName = this.drivingSchoolName
          this.form.transmission = this.transmission

          //ProfileAPI.updateInstructorProfile(this.form) //model)
          profileInstructorService
            .updateProfile(
              this.standardLessonFee,
              null,
              this.drivingSchoolName,
              this.transmission,
              this.form.firstName,
              this.form.lastName,
              this.form.telephoneNumber,
              null
            )

            .then(({ data }) => {
              console.log(data)
              this.profileUpdated = true
              this.$store.commit(Mutations.SET_PROFILE, data)
              this.$router.push({ name: 'root' })
              // this.$router.push({ name: 'root' })

              //this.lessonCreated = true;
              //this.students = data.students;
              //this.pageMetaData = data.pageMetaData
            })
            .catch(error => {
              this.submitted = false
              this.errors = error.response.data.errors
            })
        } else {
          //ProfileAPI.updateStudentProfile(this.form) //model)

          profileStudentService
            .updateProfile(false, this.form.firstName, this.form.lastName, this.form.telephoneNumber, null)
            .then(({ data }) => {
              console.log(data)

              this.profileUpdated = true
              this.$store.commit(Mutations.SET_PROFILE, data)
              this.$router.push({ name: 'root' })
            })
            .catch(error => {
              this.submitted = false
              this.errors = error.response.data.errors
            })
        }
      }
    }
  },
  created() {}
}
</script>

<style scoped></style>
