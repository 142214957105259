<template>
  <div>
    <v-list class="my-12">
      <SidebarItem icon="fas fa-car medium-icon" text="Dashboard" link="/" />
      <!-- <SidebarItem icon="fas fa-car" text="Lessons" link="/lessons"/>
      <SidebarItem icon="fas fa-pound-sign" text="Payments" link="/My/Payments"/> -->
      <SidebarItem icon="far fa-user-circle medium-icon" text="Settings" link="/settings" />
      <!-- <SidebarItem icon="far fa-address-book medium-icon" text="My Instructor" link="/instructor" v-if="hasInstructor" /> -->
      <SidebarItem icon="far fa-envelope medium-icon" text="Messages" link="/messages" v-if="hasInstructor" />
    </v-list>
    <v-divider></v-divider>
    <v-list class="my-12" v-if="!hasInstructor">
      <SidebarItem icon="fas fa-link medium-icon" text="Pair With Instructor" link="/pair-with-instructor" />
    </v-list>
  </div>
</template>

<script>
//import { LOGOUT, UPDATE_USER } from "@/store/actions.type";

import SidebarItem from '../../components/Shared/SidebarItem'

import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

export default {
  components: {
    SidebarItem
  },
  data() {
    return {
      items: [
        { icon: 'trending_up', text: 'Student' },
        { icon: 'subscriptions', text: 'Subscriptions' },
        { icon: 'history', text: 'History' },
        { icon: 'featured_play_list', text: 'Playlists' },
        { icon: 'watch_later', text: 'Watch Later' }
      ]
    }
  },
  methods: {},

  computed: {
    ...mapState('global', ['drawer']),
    ...mapGetters(['hasInstructor'])
  }
}
</script>

<style scoped></style>
