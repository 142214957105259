<template>
  <!-- <Header /> -->

  <!-- <router-view></router-view> -->
  <!-- <Header /> -->
  <router-view name="home"></router-view>
</template>

<script>
// import Main from '../components/Home/Main'
// import Dashboard from './Dashboard'
// import Header from '../components/Home/Header'

export default {
  components: {
    // Main,
    // Dashboard,
    // Header
  },
  data: () => ({
    //isLoggedIn: true
  })
}
</script>
