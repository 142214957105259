<template>
  <v-dialog v-model="show" width="500" :persistent="isPersistent">
    <v-card>
      <v-card-title class="title primary white--text" primary-title>
        <slot name="modalTitle">Title Text</slot>
      </v-card-title>
      <div :class="padding">
        <slot name="modalBody" class="body-2">Body Text</slot>
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color text @click.stop="show = false">Close</v-btn>
        <slot name="okButton"></slot>
        <!-- <v-btn color="primary" text @click="$emit('okModalClicked')">
          <slot name="modalOkText">Ok</slot>
        </v-btn>-->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    isPersistent: {
      type: Boolean,
      default: false
    },
    padding: {
      type: String,
      default: 'pa-8'
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped></style>
