<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm12 lg8>
      <!-- <PageHeading title="Add Lesson"/> -->
      <!-- <transition name="saved-alert" enter-active-class="animated bounceInDown">
        <v-alert :value="true" v-if="lessonCreated" type="success">This is a success alert.</v-alert>
      </transition>-->
      <SuccessTick v-if="lessonCreated">Lesson {{ isEditMode ? 'Updated' : 'Added' }}</SuccessTick>
      <!-- <SuccessTick v-if="lessonCreated && isEditMode">Lesson Updated</SuccessTick> -->

      <v-card v-if="!lessonCreated">
        <FormTitle v-if="!lessonCreated">{{ title }}</FormTitle>

        <!-- <pre>{{lessonData}}</pre> -->

        <FormErrors :errors="errors" />

        <v-layout row wrap>
          <v-flex>
            <v-card-text class="pa-6 add-lesson-form">
              <v-form v-model="valid" ref="form">
                <v-autocomplete
                  v-model="studentSelected"
                  :items="students"
                  label="Student"
                  chips
                  item-text="fullName"
                  item-value="studentId"
                  prepend-icon="fas fa-user"
                  persistent-hint
                  class="pa-6"
                  :rules="studentRules"
                  :disabled="submitted"
                  @change="studentChanged"
                >
                  <template v-slot:selection="data">
                    <v-chip :input-value="data.selected" class="chip--select-multi">
                      <v-avatar left>
                        <img :src="data.item.profilePicture | asProfilePicture" />
                      </v-avatar>
                      {{ data.item.fullName }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-avatar>
                        <img :src="data.item.profilePicture | asProfilePicture" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.fullName"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-menu :close-on-content-click="false" v-model="menu2" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="computedDateFormatted"
                      label="Date for the lesson"
                      prepend-icon="far fa-calendar-alt"
                      :rules="dateRules"
                      readonly
                      class="pa-6"
                      :disabled="submitted"
                    ></v-text-field>
                  </template>
                  <v-date-picker :min="today" v-model="date" @input="dateSelected"></v-date-picker>
                </v-menu>

                <v-dialog v-if="date" ref="dialog" v-model="modal2" :return-value.sync="time" persistent width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :disabled="submitted"
                      v-on="on"
                      v-model="time"
                      :rules="timeRules"
                      label="Time for the lesson"
                      prepend-icon="far fa-clock"
                      readonly
                      class="pa-6"
                    ></v-text-field>
                  </template>

                  <v-time-picker v-if="modal2" v-model="time" format="24hr" :allowed-minutes="allowedStep" @input="timeSelected">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">Cancel</v-btn>
                    <v-btn text :class="{ 'primary white--text': valid, disabled: !valid }" @click="$refs.dialog.save(time)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>

                <v-select
                  v-if="date"
                  :items="durations"
                  item-text="text"
                  item-value="val"
                  v-model="durationSelected"
                  menu-props="auto"
                  prepend-icon="fas fa-hourglass-start"
                  label="Duration of the lesson"
                  persistent-hint
                  class="pa-6"
                  :rules="durationRules"
                  :disabled="submitted"
                  @change="durationChanged"
                ></v-select>

                <v-select
                  v-if="date"
                  :items="lessonTypes"
                  item-text="text"
                  item-value="val"
                  v-model="lessonTypeSelected"
                  menu-props="auto"
                  prepend-icon="fas fa-car"
                  label="Type of lesson"
                  persistent-hint
                  class="pa-6"
                  :rules="lessonTypesRules"
                  :disabled="submitted"
                ></v-select>

                <!-- <v-select
              :items="students"
              item-text="fullName"
              item-value="studentId"
              :rules="studentRules"
              v-model="studentSelected"
              menu-props="auto"
              prepend-icon="fas fa-user"
              hint="Student"
              persistent-hint
              class="pa-6"
                ></v-select>-->

                <!-- <v-text-field
                      label="Pre-Lesson Notes"
                      v-model="form.preLessonNotes"
                      required
                ></v-text-field>-->

                <v-text-field
                  v-if="date"
                  :disabled="submitted"
                  v-model="lessonCost"
                  :rules="lessonCostRules"
                  type="number"
                  label="Cost of the lesson"
                  prepend-icon="fas fa-pound-sign"
                  class="pa-6"
                ></v-text-field>

                <v-layout align-center class="ma-4">
                  <v-btn @click="submit" :disabled="submitted" block :class="{ 'primary white--text': valid, disabled: !valid }">{{ buttonText }}</v-btn>
                  <v-progress-circular size="18" width="2" v-if="submitted" indeterminate color="primary"></v-progress-circular>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-flex>

          <v-flex lg4 v-if="date" d-flex>
            <v-divider vertical></v-divider>

            <div class="px-8"><LessonsOnDay class="mt-4" :date="date" :mini="true" /></div>
          </v-flex>
        </v-layout>
        <!-- <pre>{{students}}</pre> -->
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
//import AuthService from "@/services/auth";
//import { REGISTER } from "@/store/actions.type";

//import LessonsAPI from '@/services/lessons.service'
import lessonService from '@/services/generated/lesson.service.gen'
import instructorService from '@/services/generated/instructor.service.gen'
import lessonInstructorService from '@/services/generated/lessonInstructor.service.gen'

import LessonsOnDay from './LessonsOnDay'
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  components: {
    LessonsOnDay
  },
  data() {
    return {
      valid: false,

      form: {
        preLessonNotes: ''
      },
      dateRules: [v => !!v || 'Date of lesson required'],
      timeRules: [v => !!v || 'Time of lesson is  required'],
      studentRules: [v => !!v || 'Select a student'],
      durationRules: [v => !!v || 'Select duration of the lesson'],
      lessonCostRules: [v => !!v || 'Lesson cost must be specified'],
      lessonTypesRules: [v => !!v || 'Select the type of lesson'],

      errors: [],
      menu2: false,
      time: null,
      lessonCost: null,
      date: null,
      menu3: false,
      modal2: false,
      picker: null,
      students: [],
      //durations: [1,1.25,1.5,2,2.5,3]
      durations: [
        { val: 1, text: '1 hour' },
        { val: 1.25, text: '1 hour, 15 mins' },
        { val: 1.5, text: '1 hour, 30 mins' },
        { val: 2, text: '2 hours' },
        { val: 2.5, text: '2 hours, 30 mins' },
        { val: 3, text: '3 hours' }
      ],
      durationSelected: 1, //{ val: 1, text: '1 hour' },
      lessonTypes: [
        { val: 1, text: 'Standard Lesson' },
        { val: 2, text: 'Driving Test' },
        { val: 3, text: 'Pass Plus' }
      ],
      lessonTypeSelected: 1,
      studentSelected: null,
      lessonCreated: false,
      //snackbar: false,
      timeout: 6000,

      lessonId: '',
      oldSelected: null,
      submitted: false,
      fromStudent: '',
      lessonData: null

      //  people: [
      //     //{ header: 'Group 1' },
      //     { name: 'Sandra Adams', group: 'Group 1', avatar: srcs[1] },
      //     { name: 'Ali Connors', group: 'Group 1', avatar: srcs[2] },
      //     { name: 'Trevor Hansen', group: 'Group 1', avatar: srcs[3] },
      //     { name: 'Tucker Smith', group: 'Group 1', avatar: srcs[2] },

      //   ],
    }
  },
  computed: {
    computedDateFormatted() {
      return this.date ? moment(this.date).format('DD/MM/YYYY') : null
    },
    today() {
      return moment().format('YYYY-MM-DD')
    },

    HasErrors() {
      return this.errors.length != 0
    },
    startDateTimeUTC() {
      let dateText = this.date + ' ' + this.time
      console.log('dateText' + dateText)
      return new Date(dateText).toISOString()

      // var dateTime = moment(this.computedDateFormatted + ' ' + this.time, 'DD/MM/YYYY HH:mm')
      // var utcDate = moment.tz("Europe/London").format();
      // console.log("utcDate"+utcDate);

      //return dateTime
    },
    currentRouteName() {
      return this.$route.path.toLowerCase()
    },
    formType() {
      if (_.includes(this.currentRouteName, 'update-lesson')) {
        //};this.currentRouteName == 'updatelesson') {
        return 'edit'
      }
      return 'add'
    },
    isEditMode() {
      return this.formType == 'edit'
    },
    title() {
      if (this.isEditMode) {
        return 'Edit Lesson'
      }
      return 'Add Lesson'
    },
    buttonText() {
      return this.isEditMode ? 'Update Lesson' : 'Add Lesson'
    },
    ...mapGetters(['hasAccess'])
  },
  methods: {
    // getImageUrl(profilePicture) {
    //   if (profilePicture && profilePicture.indexOf('random') !== -1) {
    //     return profilePicture
    //   }
    //   return `${location.protocol}//${location.host}/Avatars/${profilePicture}`
    // },
    //allowedDates: val => parseInt(val.split('-')[2], 10) % 2 === 0
    allowedStep: m => m % 5 === 0,
    timeSelected() {
      console.log(this.time)
    },
    studentChanged() {
      this.calculateCost()
    },
    durationChanged() {
      this.calculateCost()
    },
    calculateCost() {
      console.log(this.studentSelected)
      console.log(this.durationSelected)
      let student = _.find(this.students, { studentId: this.studentSelected })
      let calculatedCost = parseFloat(student.hourlyRate) * parseFloat(this.durationSelected)
      this.lessonCost = this.$options.filters.asMoney(calculatedCost, true) //parseFloat(Math.round(calculatedCost * 100) / 100).toFixed(2)
    },
    dateSelected() {
      this.menu2 = false
      console.log(this.date)
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.submitted = true
        this.errors = []
        // var model = {
        //   studentId: this.studentSelected,
        //   startDateTime: this.startDateTimeUTC,
        //   duration: this.durationSelected,
        //   //preLessonNotes: 'string',
        //   lessonCost: this.lessonCost,
        //   lessonType: this.lessonTypeSelected
        // }
        if (this.formType == 'edit') {
          //LessonsAPI.updateLesson(model, this.lessonId)
          lessonInstructorService
            .updateLesson(this.lessonId, this.studentSelected, this.startDateTimeUTC, this.durationSelected, this.lessonCost, null, this.lessonTypeSelected)
            .then(({ data }) => {
              console.log(data)
              this.lessonCreated = true
              //this.snackbar = true

              //alert('status set')
              setTimeout(() => {
                this.$router.push({ name: 'ViewLesson', params: { lessonId: this.lessonId } })
              }, 3000)
            })
            .catch(error => {
              this.submitted = false

              this.errors = error.response.data.errors
              //console.log(error.data.errors)
            })
        } else {
          lessonInstructorService
            .createLesson(this.studentSelected, this.startDateTimeUTC, this.durationSelected, null, this.lessonCost, this.lessonTypeSelected)
            .then(({ data }) => {
              console.log('TCL: submit -> data', data)
              this.lessonCreated = true
              let newLessonId = data.id
              //debugger

              setTimeout(() => {
                this.lessonCreated = false
                // debugger
                this.$router.push({ name: 'ViewLesson', params: { lessonId: newLessonId } })
              }, 3000)
            })
            .catch(error => {
              console.log(error)
              this.submitted = false
              this.errors = error.response.data.errors
              //this.errors = ['Unknown error occurred']
              // if (error.response.status == 403) {
              //   this.errors = ["You don't have permission to do this, check your subription status in your profile."]
              // } else {
              //   this.errors = ['Unknown error occurred']
              // }
            })
        }
      }
    },
    load() {
      instructorService
        .getStudentsForAddLesson('', '', '', '', '')
        .then(({ data }) => {
          console.log(data)
          this.students = data.students

          let studentId = this.$route.query.student ? this.$route.query.student : ''
          if (studentId) {
            this.studentSelected = studentId
            this.calculateCost()
          }
          //this.pageMetaData = data.pageMetaData
        })
        .catch(({ response }) => {
          console.log(response)
        })

      if (this.formType == 'edit') {
        let lessonId = this.$route.params.lessonId
        this.lessonId = lessonId
        console.log('lessonId' + lessonId)
        lessonService
          .getLesson(lessonId)
          .then(response => {
            this.lessonData = response.data
            let isAfter = moment(this.lessonData.localStartDateTime).isAfter(moment())
            if (!isAfter) {
              this.$router.replace({ name: 'ViewLesson', params: { lessonId: this.lessonData.id } })
            }

            if (this.lessonData.isPaid) {
              this.$router.replace({ name: 'ViewLesson', params: { lessonId: this.lessonData.id } })
            }
            this.studentSelected = this.lessonData.studentId
            this.date = this.lessonData.year + '-' + this.lessonData.month + '-' + this.lessonData.day //.localStartDateTime.toISOString().substr(0, 10);
            let d = _.find(this.durations, { val: this.lessonData.hours })
            this.durationSelected = d.val
            this.time = this.lessonData.hour + ':' + this.lessonData.minute
            this.oldSelected = this.lessonData.year + '-' + this.lessonData.month + '-' + this.lessonData.day + ' ' + this.lessonData.hour + ':' + this.lessonData.minute

            this.lessonCost = this.$options.filters.asMoney(this.lessonData.lessonCost, true)
            //this.pageMetaData = data.pageMetaData
            this.lessonTypeSelected = this.lessonData.lessonType
          })
          .catch(({ response }) => {
            console.log(response)
          })
      }
    }
  },
  created() {
    if (this.hasAccess) {
      this.load()
    } else {
      this.$router.push({ name: 'Subscription' })
    }
  }
}
</script>

<style scoped></style>
