<template>
  <div>
    <div>
      <h2 v-if="errorMessage.error && errorMessage.error.Message">{{ errorMessage.error.Message }}</h2>
      <pre v-if="errorMessage.error && errorMessage.error.StackTraceString">
        {{ errorMessage.error.StackTraceString }}
      </pre>
    </div>

    <div v-if="errorMessage">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  components: {},

  computed: {
    errorMessage() {
      return this.$store.getters['global/errorMessageDev']
    }
  }
}
</script>
