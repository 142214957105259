//address.service.gen.js
// import addressService from '@/services/generated/address.service.gen'
import axios from 'axios'

export default {
  getAddress() {
    return axios.get('/api/address/getaddress')
  },

  /*
					addressService.getAddress()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  createAddress(addressLine1, addressLine2, addressLine3, postCode) {
    var model = {
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      addressLine3: addressLine3,
      postCode: postCode
    }
    return axios.post('/api/address', model)
  }
  /*
					addressService.createAddress(addressLine1, addressLine2, addressLine3, postCode)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
