import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  theme: {
    themes: {
      light: {
        primary: '#FF5722', //colors.deepOrange,
        //secondary: colors.deepOrange.lighten4,
        accent: '#000051' //'#0F1249',//colors.blue
      }
    }
  }
})
