<template>
  <AuthWrapper>
    <template #form>
      <v-flex xs12 elevation-6>
        <v-card>
          <FormTitle>Reset Password</FormTitle>

          <v-card-text class="pt-6">
            <FormErrors :errors="errors" />

            <SuccessTick v-if="successSubmit">Password has been reset successfully</SuccessTick>
            <div>
              <v-form v-if="!successSubmit" v-model="valid" ref="form">
                <v-text-field
                  label="Enter your new password"
                  v-model="form.password"
                  min="8"
                  :append-icon="e1 ? 'fas fa-eye' : 'fas fa-eye-slash'"
                  @click:append="() => (e1 = !e1)"
                  :type="e1 ? 'text' : 'password'"
                  :rules="newPasswordRules"
                  counter
                  required
                  :disabled="submitted"
                ></v-text-field>
                <v-text-field
                  label="Confirm your new password"
                  v-model="form.confirmPassword"
                  min="8"
                  :append-icon="e1 ? 'fas fa-eye' : 'fas fa-eye-slash'"
                  @click:append="() => (e1 = !e1)"
                  :type="e1 ? 'text' : 'password'"
                  :rules="confirmPasswordRules"
                  counter
                  required
                  :disabled="submitted"
                ></v-text-field>

                <v-layout align-center>
                  <v-btn @click="submit" :class="{ 'primary white--text': valid, disabled: !valid }" :disabled="submitted">Save</v-btn>
                  <v-progress-circular size="18" width="2" v-if="submitted" indeterminate color="primary"></v-progress-circular>
                </v-layout>
              </v-form>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </template>
    <template #rightside>
      <img src="@/assets/images/undraw_authentication_fsn5.svg" class="animated flipInY slow" style="width: 50%; height: auto;" />
    </template>
  </AuthWrapper>
</template>

<script>
//import * as TYPES from "../../store/actions.type";
import { Actions } from '../../store/actions.type'
import AuthWrapper from './AuthWrapper'

export default {
  components: {
    AuthWrapper
  },
  data() {
    return {
      form: {
        email: this.$route.query.email,
        password: '',
        confirmPassword: '',
        code: this.$route.query.code
      },
      valid: false,
      e1: false,
      errors: [],
      newPasswordRules: [v => !!v || 'New password is required'],
      confirmPasswordRules: [v => !!v || 'New password confirmation is required', v => v == this.form.password || 'Password must match'],
      submitted: false,
      successSubmit: false
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.submitted = true
        this.$store
          .dispatch(Actions.RESET_PASSWORD, this.form)
          .then(() => {
            //alert('paswword reset')
            //this.$store.commit("global/setLoggedIn", true);
            this.successSubmit = true
            setTimeout(() => {
              this.successSubmit = false
              this.$router.push({ name: 'Login' })
            }, 3000)
          })
          .catch(error => {
            this.submitted = false

            this.errors = error.data.errors
            //console.log(error.data)
          })
      }
    },
    clear() {
      this.$refs.form.reset()
    }
  }
}
</script>
