<template>
  <v-layout row wrap justify-center ma-12 pa-12 v-show="isLoading">
    <v-icon style="font-size:3em ">fas fa-cog fa-spin</v-icon>
  </v-layout>
</template>

<script>
export default {
  props: ['isLoading']
}
</script>

<style scoped></style>
