<template>
  <v-layout align-center secondary class="fullscreen">
    <div class="text-center">
      <v-dialog v-model="dialog" hide-overlay persistent width="300">
        <v-snackbar :top="true" :color="'error'" v-model="hasErrors" :timeout="5000" :multi-line="true">
          {{ errorMessage }}
        </v-snackbar>
        <v-card color="primary" dark>
          <v-card-text>
            Confirming email
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-layout>
</template>

<script>
//import AuthService from "@/services/auth";
//import { CONFIRM_EMAIL } from "@/store/actions.type";
import { Actions } from '../../store/actions.type'

// function getParameterByName(name, url) {
//   if (!url) url = window.location.href
//   name = name.replace(/[\[\]]/g, '\\$&')
//   var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
//     results = regex.exec(url)
//   if (!results) return null
//   if (!results[2]) return ''
//   return decodeURIComponent(results[2].replace(/\+/g, ' '))
// }

export default {
  components: {},
  data() {
    return {
      dialog: true,
      snackbar: true
    }
  },
  computed: {
    hasErrors() {
      return this.$store.getters['global/hasError']
    },
    errorMessage() {
      return this.$store.getters['global/errorMessage']
    }
  },
  created() {
    // var userId = getParameterByName("userId");
    // var code = getParameterByName("code");
    // var client = getParameterByName("client");

    console.log('confirming user')
    this.$store
      .dispatch(Actions.CONFIRM_EMAIL, {
        userId: this.$route.query.userId, //userId,//this.$router.params.userId,
        code: this.$route.query.code, //code,//this.$router.params.code
        client: this.$route.query.client //,client
      })
      .then(response => {
        console.log('redirect to')
        this.$router.push({ name: 'root' })
        console.log(response)
      })
      .catch(error => {
        console.log(error.response)
      })
  }
}
</script>

<style lang="scss" scoped>
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  // background: lime; /* Just to visualize the extent */
}
</style>
