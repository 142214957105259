<template>
  <div>
    <Spinner :isLoading="isLoading" />

    <v-layout v-if="lessonData" class="lesson-view" :key="componentKey">
      <v-flex>
        <!-- <pre>{{lessonData}}</pre>  -->
        <PageHeading :title="lessonData.lessonName + ' - ' + lessonData.studentName" />

        <!-- <v-layout class="row justify-end pr-0" v-if="!isPaid && IsInstructor">
          <v-btn text small color="primary" class="ma-0" @click.stop="cancelDialog = true"> <v-icon class="mr-4">fas fa-trash small-icon</v-icon>Cancel this lesson </v-btn>
        </v-layout>-->

        <v-toolbar color="grey lighten-2" dense flat dark v-if="!isPaid && IsInstructor">
          <v-btn v-if="canEdit" class="white-button " text outlined small color="primary" light :to="{ name: 'UpdateLesson', params: { lessonId: lessonId } }">
            <v-icon class="pr-4 small-icon">fas fa-pen</v-icon>Edit Lesson
          </v-btn>
          <v-spacer></v-spacer>

          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn light icon v-on="on">
                <v-icon dark>fas fa-ellipsis-v small-icon</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click.stop="cancelDialog = true">
                <v-icon class="pr-4 small-icon">fas fa-times</v-icon>
                <v-list-item-title v-text="'Cancel Lesson'"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>

        <v-alert :value="isPaid && IsInstructor" type="info"
          >This lesson has been paid for, so can no longer be edited or cancelled. Only notes can be added or ammended against this lesson</v-alert
        >

        <v-card class="pa-1">
          <v-container>
            <v-layout row wrap>
              <v-flex sm12 md6 d-flex>
                <v-layout align-center>
                  <h3>Instructor</h3>
                  <AvatarName
                    :isAvatarStudent="false"
                    :userId="lessonData.instructorId"
                    :name="lessonData.instructorName"
                    :imageUrl="lessonData.instructorProfilePicture"
                    :size="'sm'"
                  />
                </v-layout>
              </v-flex>
              <v-flex sm12 md6 align-center d-flex>
                <v-layout align-center>
                  <h3>Student</h3>
                  <AvatarName :userId="lessonData.studentId" :name="lessonData.studentName" :imageUrl="lessonData.profilePicture" :size="'sm'" />
                </v-layout>
              </v-flex>

              <v-flex sm12 md6 align-center class="d-flex">
                <h3>Date</h3>
                <span>{{ lessonData.friendlyDate }}</span>
              </v-flex>
              <v-flex sm12 md6 align-center>
                <h3>Time</h3>
                {{ lessonData.justTime24 }}
              </v-flex>
              <v-flex xs12 sm12 md6 align-center>
                <h3>Duration</h3>
                {{ lessonData.friendlyDuration }}
              </v-flex>
              <v-flex xs12 sm12 md6 align-center>
                <h3>Cost</h3>
                {{ lessonData.lessonCost | asMoney }}
              </v-flex>

              <v-flex xs12 sm12 md6 align-center>
                <h3>Type of Lesson</h3>
                {{ lessonData.lessonTypeText }}
              </v-flex>
              <v-flex lg12 xs12 sm12 md12 align-center>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12 sm12 lg12 px-4 align-center py-4>
                <v-layout row wrap pa-0>
                  <v-flex>
                    <h3>Notes</h3>
                  </v-flex>
                  <v-flex xs12 v-if="IsStudent">
                    <v-card v-if="hasNote">
                      <v-card-text style="white-space: pre-wrap;" class="pointer body-1">{{ lessonData.postLessonNotes }} </v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 v-if="IsInstructor">
                    <div v-if="!editing">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-card @click="enableEditing" class="pointer body-1" v-on="on" v-if="hasNote">
                            <v-card-text style="white-space: pre-wrap;" class="body-2	font-weight-medium	">{{ lessonData.postLessonNotes }}</v-card-text>
                          </v-card>
                        </template>
                        <span>Click to edit</span>
                      </v-tooltip>

                      <v-btn v-if="!hasNote" block color="white" class style="height:100px" @click="enableEditing">
                        Add Note
                        <v-icon right class="small-icon">fas fa-plus small-icon</v-icon>
                      </v-btn>
                    </div>
                    <div v-if="editing" class="text-lg-right">
                      <v-textarea autofocus solo hide-details="true" class="ma-0" auto-grow label="Notes about this lesson" v-model="tempValue"></v-textarea>
                      <v-btn class="mt-2" small text @click="disableEditing">Cancel</v-btn>
                      <v-btn class="mt-2 ml-6" small color="primary" @click="saveEdit">Save</v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex lg12 xs12 sm12 md12 align-center>
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs12 sm12 lg6 align-center>
                <v-chip v-if="isPaid" class="ma-2" color="green" text-color="white">
                  <v-avatar left>
                    <v-icon>far fa-check-circle</v-icon>
                  </v-avatar>
                  This lesson has been paid
                </v-chip>

                <v-chip v-if="!isPaid" class="ma-2 mr-4" color="red" text-color="white">
                  <v-avatar left>
                    <v-icon>far fa-times-circle</v-icon>
                  </v-avatar>
                  This lesson has not been paid
                </v-chip>

                <v-btn class="white-button" outlined v-if="IsInstructor && !isPaid" @click.stop="setAsPaidDialog = true">Set to paid</v-btn>

                <table class="table-bordered" v-if="isPaid">
                  <thead>
                    <tr>
                      <th>Date Paid</th>
                      <th>Reference</th>
                      <th>Payment Method</th>
                      <th>Payment Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ this.datePaid }}</td>
                      <td>{{ lessonData.payment.id }}</td>
                      <td>{{ lessonData.payment.paymentMethodFriendly }}</td>
                      <td>{{ lessonData.payment.amount | asMoney }}</td>
                    </tr>
                  </tbody>
                </table>

                <StripePayment
                  class="ma-4"
                  v-if="IsStudent && !isPaid && lessonData.canBePaidWithStripe"
                  v-bind:lessons="lessons"
                  @finished="finished"
                  :amount="lessonData.lessonCost"
                />
              </v-flex>

              <!-- <pre>
                  {{this.lessonData}}
              </pre>-->
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>

      <Modal v-model="setAsPaidDialog">
        <template #modalTitle>
          <span>Set this lesson as paid</span>
        </template>

        <template #modalBody>
          <FormErrors :errors="errorsMasrkAsPaid" />
          <v-form v-model="valid" ref="form">
            <v-alert :value="markedAsPaid" class="mb-12 white--text" color="success" icon="fas fa-check-circle">The lesson has been marked as paid</v-alert>
            <v-alert :value="true" class="mb-12" color="info" icon="fas fa-info" outlined
              >If {{ lessonData.studentName }} has paid via other means, you can mark the lesson as paid</v-alert
            >
            <v-text-field v-model="amount" :rules="amountRules" label="Amount" prepend-icon="fas fa-pound-sign" class></v-text-field>
            <!-- :rules="amountRules" -->

            <v-select
              :rules="paymentMethodRules"
              v-model="paymentMethodSelected"
              :items="paymentMethods"
              label="Payment Method"
              item-text="text"
              item-value="val"
              prepend-icon="fas fa-wallet"
            ></v-select>
          </v-form>
        </template>

        <template #okButton>
          <!-- <v-btn @click="markAsPaid" text :disabled="submitted" :class="{ 'primary white--text': valid, disabled: !valid }">Set as Paid</v-btn> -->

          <v-btn @click="markAsPaid" :disabled="markAsPaidSubmitted" :class="{ 'primary white--text': valid, disabled: !valid }"
            >Set as Paid <v-progress-circular class="ml-6" size="18" width="2" v-if="markAsPaidSubmitted" indeterminate color="primary"></v-progress-circular>
          </v-btn>
        </template>
      </Modal>

      <Modal v-model="cancelDialog">
        <template #modalTitle>
          <span>Cancel lesson</span>
        </template>
        <template #modalBody>
          <v-alert :value="isCancelled" type="success">This lesson has been cancelled.</v-alert>
          <p v-if="!isCancelled">Are you sure you want to cancel this lesson with {{ lessonData.studentName }}?</p>
          <p>By cancelling it will be removed from your calendar</p>
        </template>
        <template #okButton>
          <v-btn color="primary" :disabled="submitted" text @click="cancelLesson">Cancel Lesson</v-btn>
        </template>
      </Modal>
    </v-layout>
  </div>
</template>

<script>
//import AuthService from "@/services/auth";
//import { REGISTER } from "@/store/actions.type";
//import { Actions } from '@/store/actions.type'
//import LessonsAPI from '@/services/lessons.service'
//import StripeAPI from '@/services/stripe.service'

import lessonInstructorService from '@/services/generated/lessonInstructor.service.gen'
import lessonService from '@/services/generated/lesson.service.gen'

import StripePayment from './StripePayment'
import _ from 'lodash'

import moment from 'moment'

//card = undefined

export default {
  components: { StripePayment },
  data() {
    return {
      markAsPaidSubmitted: false,
      valid: false,
      markedAsPaid: false,
      errorsMasrkAsPaid: [],
      durations: [
        { val: 1, text: '1 hour' },
        { val: 1.25, text: '1 hour, 15 mins' },
        { val: 1.5, text: '1 hour, 30 mins' },
        { val: 2, text: '2 hours' },
        { val: 2.5, text: '2 hours, 30 mins' },
        { val: 3, text: '3 hours' }
      ],
      paymentMethods: [
        { val: 0, text: 'Cash' },
        { val: 1, text: 'Other' }
      ],
      paymentMethodSelected: null,
      amount: 0,
      date: null,
      durationSelected: '',
      lessonData: null,
      lessons: [],
      stripe: {},
      card: {},
      setAsPaidDialog: false,
      cancelDialog: false,
      lessonId: '',
      submitted: false,
      isCancelled: false,
      tempValue: null,
      editing: false,
      amountRules: [v => !!v || 'Amount must be set'],
      paymentMethodRules: [v => v != null || 'Select payment method'],
      componentKey: 0,
      isLoading: true
    }
  },
  computed: {
    formattedDate() {
      return this.date ? moment(this.date).format('lll') : ''
    },
    user() {
      return this.$store.state.auth.user
    },
    IsInstructor() {
      return this.user.role == 'Instructor'
    },
    IsStudent() {
      return this.user.role == 'Student'
    },
    datePaid() {
      //return this.lessonData.isPaid ? moment(this.lessonData.payment.datePaid).format('lll') : 'xx'
      return this.lessonData.payment ? moment(this.lessonData.payment.datePaid).format('lll') : 'xx'
    },
    isPaid() {
      return this.lessonData.isPaid
    },
    hasNote() {
      if (this.lessonData.postLessonNotes) {
        return true
      }
      return false
    },
    canEdit() {
      return moment(this.date).isAfter(moment())
    }
  },
  methods: {
    finished() {
      this.loadLessonData()
      // this.componentKey += 1
    },
    markAsPaid() {
      if (this.$refs.form.validate()) {
        this.markAsPaidSubmitted = true

        // var model = {
        //   paymentMethod: this.paymentMethodSelected,
        //   amount: this.amount
        // }
        // LessonsAPI.markAsPaid(model, this.lessonId)
        lessonInstructorService
          .lessonMarkAsPaid(this.lessonId, this.paymentMethodSelected, this.amount)
          .then(({ data }) => {
            this.markedAsPaid = true

            setTimeout(() => {
              this.setAsPaidDialog = false

              ////////////////////
              this.lessonData = data
              this.amount = this.$options.filters.asMoney(this.lessonData.lessonCost, true)
              this.lessons.push(this.lessonData.id)

              this.date = data.localStartDateTime //localTime;//data.year + "-a" + data.month + "-" + data.day;
              // let d = _.find(this.durations, { val: data.hours })
              // this.durationSelected = d
              ////////////////////
            }, 3000)
            console.log(data)
            //this.lessonCreated = true
            //this.students = data.students;
            //this.pageMetaData = data.pageMetaData
          })
          .catch(({ response }) => {
            console.log(response)
            this.errorsMasrkAsPaid = response.data.errors
            this.submitted = false
            this.markAsPaidSubmitted = false
          })
      }
    },

    cancelLesson() {
      this.submitted = true
      lessonInstructorService
        .cancelLesson(this.lessonId)
        .then(({ data }) => {
          console.log(data)
          this.isCancelled = true

          //alert('status set')
          setTimeout(() => {
            this.$router.push({ name: 'Lessons' })
          }, 3000)
        })
        .catch(({ response }) => {
          console.log(response)
          alert('error')
        })
    },

    enableEditing() {
      this.tempValue = this.lessonData.postLessonNotes
      this.editing = true
    },
    disableEditing() {
      this.tempValue = null
      this.editing = false
    },
    saveEdit() {
      // However we want to save it to the database
      this.lessonData.postLessonNotes = this.tempValue
      this.setNotes()
      this.disableEditing()
    },
    setNotes() {
      // var model = {
      //   postLessonNotes: this.lessonData.postLessonNotes
      // }
      lessonInstructorService
        .updateLessonNotes(this.lessonId, this.lessonData.postLessonNotes)
        .then(({ data }) => {
          console.log(data)
          // setTimeout(() => {
          //   this.$router.push({ name: 'ViewLesson', params: { lessonId: this.lessonId } })
          // }, 3000)
        })
        .catch(error => {
          console.log('TCL: error', error)
          this.submitted = false
        })
    },

    // payForLesson() {
    //   // var stripe = Stripe(`pk_test_xOXjNJC5W7EWbiapHoEvmDH0`)
    //   debugger
    //   this.stripe.createToken(this.card).then(result => {
    //     if (result.error) {
    //       // Inform the user if there was an error.
    //       var errorElement = document.getElementById('card-errors')
    //       errorElement.textContent = result.error.message
    //     } else {
    //       // Send the token to your server.
    //       this.stripeTokenHandler(result.token)
    //     }
    //   })
    // },
    // stripeTokenHandler(token) {
    //   // Insert the token ID into the form so it gets submitted to the server
    //   // var form = document.getElementById('payment-form')
    //   // var hiddenInput = document.createElement('input')
    //   // hiddenInput.setAttribute('type', 'hidden')
    //   // hiddenInput.setAttribute('name', 'stripeToken')
    //   // hiddenInput.setAttribute('value', token.id)
    //   // form.appendChild(hiddenInput)
    //   this.lessons = []
    //   this.lessons.push(this.lessonData.id)
    //   var model = {
    //     lessons: this.lessons,
    //     chargeToken: token.id
    //   }
    //   console.log(model)
    //   StripeAPI.createCharge(model).then(function(result) {
    //     console.log('bank' + result)
    //     //resolve({ accountId: result.data.id })
    //   })
    //   // Submit the form
    //   // form.submit()
    loadLessonData() {
      let lessonId = this.$route.params.lessonId
      this.lessonId = lessonId
      console.log('lessonId' + lessonId)
      lessonService
        .getLesson(lessonId)
        .then(({ data }) => {
          this.isLoading = false
          this.lessonData = data
          this.amount = this.$options.filters.asMoney(this.lessonData.lessonCost, true)
          console.log(data)
          this.lessons.push(this.lessonData.id)

          this.date = data.localStartDateTime //localTime;//data.year + "-a" + data.month + "-" + data.day;
          let d = _.find(this.durations, { val: data.hours })
          this.durationSelected = d
        })
        .catch(({ response }) => {
          console.log(response)
        })
    }
  },
  created() {
    this.loadLessonData()

    // var style = {
    //   base: {
    //     color: '#32325d',
    //     fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    //     fontSmoothing: 'antialiased',
    //     fontSize: '16px',
    //     '::placeholder': {
    //       color: '#aab7c4'
    //     }
    //   },
    //   invalid: {
    //     color: '#fa755a',
    //     iconColor: '#fa755a'
    //   }
    // }
    // this.stripe = Stripe(`pk_test_xOXjNJC5W7EWbiapHoEvmDH0`)
    // var elements = this.stripe.elements()

    // // Create an instance of the card Element.
    // this.card = elements.create('card', { style: style })

    // // Add an instance of the card Element into the `card-element` <div>.
    // this.card.mount('#card-element')

    // // Handle real-time validation errors from the card Element.
    // this.card.addEventListener('change', function(event) {
    //   var displayError = document.getElementById('card-errors')
    //   if (event.error) {
    //     displayError.textContent = event.error.message
    //   } else {
    //     displayError.textContent = ''
    //   }
    // })

    // Handle form submission.
    // var form = document.getElementById('payment-form')
    // form.addEventListener('submit', function(event) {
    //   event.preventDefault()

    //   stripe.createToken(card).then(function(result) {
    //     if (result.error) {
    //       // Inform the user if there was an error.
    //       var errorElement = document.getElementById('card-errors')
    //       errorElement.textContent = result.error.message
    //     } else {
    //       // Send the token to your server.
    //       this.stripeTokenHandler(result.token)
    //     }
    //   })
    // })

    // Submit the form with the token ID.
    //   function stripeTokenHandler(token) {
    //   // Insert the token ID into the form so it gets submitted to the server
    //   var form = document.getElementById('payment-form')
    //   var hiddenInput = document.createElement('input')
    //   hiddenInput.setAttribute('type', 'hidden')
    //   hiddenInput.setAttribute('name', 'stripeToken')
    //   hiddenInput.setAttribute('value', token.id)
    //   form.appendChild(hiddenInput)

    //   // Submit the form
    //   form.submit()
    // }
  }
}
</script>

<style scoped>
.lesson-view .row {
  padding: 15px;
  align-content: center;
  /* background:var(--v-primary-lighten2); */
}

.lesson-view h3 {
  width: 160px;
  display: inline-block;
}

.lesson-view .flex {
  text-align: left;
  margin-bottom: 30px;
}

.table-bordered {
  border-collapse: collapse;
  margin-top: 15px;
}
.table-bordered td,
.table-bordered th {
  border: solid 1px #ddd;
  padding: 5px;
}
/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
/* .StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}*/
</style>
