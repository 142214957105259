<template>
  <v-layout>
    <v-flex>
      <PageHeading title="Pay for Lessons" />

      <v-layout wrap justify-center>
        <v-flex lg6>
          <h3 class="mb-6">Select all the lessons that you want to pay for</h3>
          <v-data-table
            id="paymentsTable"
            :disable-sort="true"
            :options="options"
            v-model="selected"
            :headers="headers"
            :items="lessons"
            item-key="id"
            hide-default-footer
            :show-select="showSelect"
            class="elevation-1"
          >
            <!-- <template v-slot:header.data-table-select>
           <v-checkbox on-icon="far fa-check-square" off-icon="far fa-square" indeterminate-icon="far fa-minus-square"></v-checkbox>

          </template>
        
          <template v-slot:item.data-table-select>
           <v-checkbox on-icon="far fa-check-square" off-icon="far fa-square"></v-checkbox>
          </template>  -->

            <template v-slot:footer>
              <div class="primary white--text py-2 px-4 text-right">
                <h3 v-show="paymentAmount != 0"><span class="mr-2">Total</span> {{ paymentAmount | asMoney }}</h3>
                <!-- <pre>{{ this.selected.length }}</pre> -->
              </div>
              <!-- <tr>
              <td :colspan="headers.length-1" class="text-lg-right primary white--text">
                <h3>Total</h3>
              </td>
              <td class="primary white--text">
                <h3>{{ paymentAmount | asMoney }}</h3>
              </td>
               </tr> -->
            </template>
          </v-data-table>
          <StripePayment
            :key="counterKey"
            v-if="IsStudent && lessons.length"
            :lessons="lessonIds"
            @finished="finished"
            @submitting="submitting"
            :amount="paymentAmount"
          />
        </v-flex>
      </v-layout>
      <!-- <pre>{{ lessons }}</pre> -->

      <!-- <template v-for="(lesson, index) in lessons">
        <LessonsItem v-bind:lesson="lesson" v-bind:key="index" />
      </template>-->
    </v-flex>
  </v-layout>
</template>

<script>
//import LessonsAPI from '@/services/lessons.service'
// import LessonsItem from './LessonsItem'
import studentService from '@/services/generated/student.service.gen'

import StripePayment from '../Common/StripePayment'
import _ from 'lodash'

export default {
  components: {
    // LessonsItem,
    StripePayment
  },
  data() {
    return {
      showSelect: true,
      disabled: false,
      counterKey: 0,
      lessons: [],
      selected: [],
      options: {
        itemsPerPage: 100
      },
      headers: [
        {
          text: 'Lesson Name',
          value: 'lessonName'
        },
        {
          text: 'Date',
          value: 'friendlyDate'
        },
        {
          text: 'Duration',
          value: 'friendlyDuration'
        },
        {
          text: 'Cost',
          value: 'lessonCost'
        }
        // { text: 'Calories', value: 'calories' },
      ]
    }
  },
  computed: {
    lessonIds() {
      return _.map(this.selected, 'id')
    },
    paymentAmount() {
      if (this.selected.length != 0) {
        return _.sumBy(this.selected, 'lessonCost')
      }
      return 0
    },
    user() {
      return this.$store.state.auth.user
    },
    IsInstructor() {
      return this.user.role == 'Instructor'
    },
    IsStudent() {
      return this.user.role == 'Student'
    }
  },
  methods: {
    submitting() {
      this.disabled = true
      this.showSelect = false
    },
    finished() {
      this.counterKey = this.counterKey + 1
      this.fetchLessons()
      this.disabled = false
      this.showSelect = true

      // this.componentKey += 1
    },
    fetchLessons() {
      studentService
        .getUnpaidLessons()
        .then(({ data }) => {
          this.lessons = data.lessons
        })
        .catch(({ response }) => {
          console.log(response)
        })
    }
  },

  created() {
    this.fetchLessons()
  }
}
</script>

<style scoped>
.sortable .v-icon {
  margin-left: 10px;
}

.v-icon.fas {
  margin-left: 10px;
}

.v-datatable td,
.v-datatable th {
  text-align: left;
}
</style>
