<template>
  <!-- <pre>{{this.payment}}</pre> -->

  <v-simple-table class="lesson-payment-table ">
    <thead>
      <th>Lesson</th>
      <th>Date</th>
      <th v-if="!$vuetify.breakpoint.smAndDown">Time</th>
      <th>Duration</th>
      <th>Cost</th>
    </thead>
    <tr v-for="lesson in payment.lessons" v-bind:key="'ml' + lesson.id">
      <td>
        <router-link v-if="!$vuetify.breakpoint.smAndDown" :to="{ name: 'ViewLesson', params: { lessonId: lesson.id } }">{{ lesson.lessonName }}</router-link>
        <router-link v-if="$vuetify.breakpoint.smAndDown" :to="{ name: 'ViewLesson', params: { lessonId: lesson.id } }">{{ lesson.lessonNumber }}</router-link>
      </td>
      <td>
        {{ getDate(lesson.startDateTime) }} <span v-if="$vuetify.breakpoint.smAndDown">{{ getTime(lesson.startDateTime) }}</span>
      </td>
      <td v-if="!$vuetify.breakpoint.smAndDown">{{ getTime(lesson.startDateTime) }}</td>
      <td>{{ lesson.friendlyDuration }}</td>
      <td>{{ lesson.lessonCost | asMoney }}</td>
    </tr>
  </v-simple-table>

  <!-- <v-list-item-content>
    <pre>{{this.payment}}</pre>
    <router-link :to="{ name: 'ViewPayment', params: { paymentId: paymentId }}">View</router-link>
    <router-link :to="{ name: 'ViewStudentPayment', params: { studentId: studentId }}">view Student</router-link>
  </v-list-item-content>-->
</template>

<script>
// 0 Pending,
// 1 Approved,
// 2 Denied,
// 3 PaymentPassed,
// 4 Deleted

import moment from 'moment'
import { mapGetters } from 'vuex'
//import _ from 'lodash'

export default {
  props: {
    payment: {
      type: Object
    }
  },
  data() {
    return {}
  },
  computed: {
    paymentId() {
      return this.payment.id
    },
    studentId() {
      return this.payment.lessons[0].studentId
    },
    date() {
      return moment(this.payment.datePaid).format('LL')
    },
    time() {
      return moment(this.payment.datePaid).format('LT')
    },

    ...mapGetters('payment', ['expandedPayments', 'isExpanded']),
    isExpanded() {
      return this.expandedPayments.indexOf(this.paymentId) !== -1
      //return _.some(this.expandedPayments, this.payment.id)
    }
  },
  methods: {
    getDate(date) {
      return moment(date).format('DD/MM/YY') //.format('LL')
    },
    getTime(date) {
      return moment(date).format('LT')
    }
  }
}
</script>

<style scoped>
.lesson-payment-table {
  border-collapse: collapse;
  background: #fff;
}

.lesson-payment-table td,
.lesson-payment-table th {
  text-align: left;
  border: 1px solid #ccc;
}
</style>
