<template>
  <div>
    <SubHeading :title="titleForDaysLessons" />

    <div v-if="hasItems">
      <ul class="today-list" v-if="mini">
        <li v-for="(lesson, index) in lessons" v-bind:key="'tl' + index">
          <!-- <pre>{{lesson}}</pre> -->
          <h4>{{ lesson.studentName }}</h4>
          {{ lesson.justTime24 }}
          <v-divider light class="my-4"></v-divider>
        </li>
      </ul>

      <SubHeading :title="'Unavailable'" class="mt-4" v-if="appointments.length > 0" />

      <ul class="today-list" v-if="mini">
        <li v-for="(appointment, index) in appointments" v-bind:key="'tl' + index">
          <h4>{{ appointment.title }}</h4>
          {{ appointment.justTime24 }}
          <v-divider light class="my-4"></v-divider>
        </li>
      </ul>
      <!-- <ul class="today-list" v-if="!mini">
        <li v-for="(lesson, index) in lessons" v-bind:key="'tl' + index" class="pa-2 white elevation-1">
          <AvatarName :name="lesson.studentName" :imageUrl="lesson.profilePicture" :userId="lesson.studentId" />

          <v-layout align-center justify-space-between class="ma-2">
            <h4 class="primary--text">
              <v-icon class="primary--text mr-2">fas fa-clock</v-icon>
              {{ lesson.justTime24 }}
            </h4>
            <h4>{{ lesson.hours }} hrs</h4>
          </v-layout>
        </li>
      </ul> -->
    </div>
    <div v-if="!hasItems">
      <h4 class="ma-12 text-lg-center">There are currently no {{ nolessonText }}</h4>
    </div>
  </div>
</template>

<script>
//import LessonsAPI from '@/services/lessons.service'
import lessonInstructorService from '@/services/generated/lessonInstructor.service.gen'
import moment from 'moment'

export default {
  props: {
    // prefetchedLessons: {},
    date: {
      type: String
    },

    mini: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      lessons: [],
      appointments: []
    }
  },

  computed: {
    titleForDaysLessons() {
      if (this.date == moment().format('YYYY-MM-DD')) {
        return 'Lessons for Today'
      }
      return 'Lessons for ' + this.friendlyDate
    },
    friendlyDate() {
      return this.date ? moment(this.date).format('LL') : null
    },
    nolessonText() {
      if (this.date == moment().format('YYYY-MM-DD')) {
        return 'lessons for Today'
      }
      return 'lessons for ' + this.friendlyDate
    },
    hasItems() {
      return (this.lessons === undefined || this.lessons.length == 0) == false //this.lessons && this.lessons.length>0
    }
    // flattenedLessons() {
    //   let mapped = _.map(this.lessons, function(lesson) {
    //     let date = moment(lesson.localStartDateTime).format('YYYY-MM-DD')
    //     let timePart = moment(lesson.localStartDateTime).format('HH:mm')
    //     let dateFormat = moment(lesson.localStartDateTime).format('MMMM Do YYYY, h:mm:ss a')
    //     return {
    //       id: lesson.id, // + timePart,
    //       name: lesson.studentName, // + timePart,
    //       details: lesson.friendlyDate,
    //       date: date,
    //       timePart: timePart,
    //       duration: lesson.hours * 60,
    //       profilePicture: 'https://randomuser.me/api/portraits/men/85.jpg', //lesson.profilePicture,
    //       open: false
    //     }
    //   })
    //   return mapped
    // },
    // todaysLessons() {
    //   //this.lessons
    //   let today = moment(new Date()).format('YYYY-MM-DD')
    //   return _.filter(this.flattenedLessons, function(o) {
    //     return o.date === today
    //   })
    // }
  },

  methods: {
    fetchLessons() {
      lessonInstructorService
        .lessonsForDay(this.date)
        .then(response => {
          console.log(response.data)
          this.lessons = response.data.lessons //.lessons
          this.appointments = response.data.appointments //.lessons
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  watch: {
    date: function(newVal, oldVal) {
      // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.fetchLessons()
    }
  },

  created() {
    this.fetchLessons()
  }
}
</script>

<style lang="scss" scoped>
.today-list {
  h3 {
    margin: 15px 0px;
    .fas {
      margin-right: 15px;
    }
  }
  list-style: none;
}
</style>
