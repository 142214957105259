//import ChatAPI from '@/services/chat.service'
import chatService from '@/services/generated/chat.service.gen'
import Vue from 'vue'
//import _ from 'lodash'

//import { Actions } from './actions.type'
//import { Mutations } from './mutations.type'

const state = {
  contacts: [],
  selectedContact: '',
  messages: [],
  isNew: true,
  pageMetaData: {
    currentPage: 0,
    totalPages: 0,
    pageSize: 0,
    totalCount: 0,
    hasPrevious: true,
    hasNext: true
  }
}

// GETTERS

const getters = {
  contacts(state) {
    return state.contacts
  },
  isNew(state) {
    return state.isNew
  },
  messages(state) {
    //return state.messages

    return state.messages.sort((a, b) => new Date(a.localDateCreated) - new Date(b.localDateCreated))
  },

  nextPage(state) {
    return state.pageMetaData.nextPage ? state.pageMetaData.nextPage : 1
  },
  pageMetaData(state) {
    return state.pageMetaData
  },
  selectedContact(state) {
    return state.selectedContact
  },
  selectedContactProfile(state) {
    const index = state.contacts.findIndex(contact => contact.userId == state.selectedContact)
    if (index >= 0) {
      var contact = state.contacts[index]
      return contact
    }
    return null
  }
}

// ACTIONS

const actions = {
  fetchContacts(context) {
    return new Promise((resolve, reject) => {
      chatService
        .getContacts()
        .then(({ data }) => {
          context.commit('setContacts', data)
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  },

  fetchConversation({ commit, state, getters }) {
    return new Promise((resolve, reject) => {
      if (getters.nextPage == 1 || getters.pageMetaData.hasNext) {
        chatService
          .getConversationWithUser(state.selectedContact, getters.nextPage, 20)
          .then(({ data }) => {
            commit('setMessages', data)
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      }
    })
  }
}

// MUTATIONS

const mutations = {
  setContacts(state, contacts) {
    state.contacts = contacts
  },

  setMessages(state, data) {
    state.pageMetaData = data.pageMetaData
    let older = data.messages
    let newer = state.messages

    let array3 = older.concat(newer)
    state.messages = array3
    state.isNew = false

    //state.messages.push(...data.messages)
  },

  resetMessages(state) {
    state.messages = []
    state.isNew = true
  },
  setSelectedContact(state, userId) {
    state.selectedContact = userId

    // reset
    let pageMetaDataReset = {
      currentPage: 0,
      totalPages: 0,
      pageSize: 0,
      totalCount: 0,
      hasPrevious: true,
      hasNext: true
    }

    state.pageMetaData = pageMetaDataReset
  },

  resetAll(state) {
    state.contacts = []
    state.selectedContact = ''
    state.messages = []
    state.isNew = true
  },

  setSelectedContactUnreadCount(state) {
    const index = state.contacts.findIndex(contact => contact.userId == state.selectedContact)
    if (index >= 0) {
      var contact = state.contacts[index]
      contact.unreadMessageCount = 0
      Vue.set(state.contacts, index, contact)
    }
  },

  // resetSelectedContact(state) {
  //   let pageMetaDataReset = {
  //     currentPage: 0,
  //     totalPages: 0,
  //     pageSize: 0,
  //     totalCount: 0,
  //     hasPrevious: true,
  //     hasNext: true
  //   }

  //   state.pageMetaData = pageMetaDataReset
  // },

  appendMessage(state, message) {
    state.messages.push(message)
  },

  incrementUnreadMessageCount(state, message) {
    // find the block's index in the array
    const index = state.contacts.findIndex(contact => contact.userId == message.fromUserId)
    var contact = state.contacts[index]
    // var contact = _.find(state.contacts, o => {
    //   return o.userId == message.fromUserId
    // })
    contact.unreadMessageCount = contact.unreadMessageCount + 1
    // using Vue.set
    Vue.set(state.contacts, index, contact)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
