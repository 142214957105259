<template>
  <div class="lesson-item" :class="{ isTest: isTest }">
    <v-layout class="" align-center wrap :class="{ mobile: $vuetify.breakpoint.smAndDown, standard: $vuetify.breakpoint.mdAndUp }">
      <!-- <pre>{{this.lesson}}</pre> -->
      <!-- <v-flex xs1 sm1>
         <v-divider vertical></v-divider>
    </v-flex>-->
      <!-- <v-layout row wrap class="justify-space-between row">
        <AvatarName :name="lesson.studentName" :imageUrl="lesson.profilePicture" :userId="lesson.studentId" :size="'md'" />
    </v-layout>-->
      <v-flex md3 xs7 pl-4 class="" @click.self="viewLesson">
        <AvatarName :name="lesson.studentName" :imageUrl="lesson.profilePicture" :userId="lesson.studentId" :size="'md'" />
      </v-flex>
      <v-flex md2 @click="viewLesson" xs2 class="pointer hidden-sm-and-down ">
        <CalendarPage :date="this.lesson.localStartDateTime" :isMini="true" />
      </v-flex>
      <v-flex md1 xs5 class="pointer " @click="viewLesson">
        <div class="text-center hidden-sm-and-down subtitle-2 mx-2 text-center" style="border-bottom:dashed 1px #000000">{{ lesson.justTime24 }}</div>
        <div class="pa-4 text-xs-right hidden-md-and-up">
          <span class="subtitle-2">{{ lesson.justTime24 }}</span>
          {{ lesson.friendlyDate }}
        </div>
      </v-flex>

      <v-flex md2 class="pointer subtitle-2 text-center" @click="viewLesson">
        {{ lesson.friendlyDuration }}
      </v-flex>
      <v-flex md1 class="pointer subtitle-2 text-center" @click="viewLesson">
        {{ lesson.lessonCost | asMoney }}
      </v-flex>
      <v-flex md2 class="pointer subtitle-2 text-center" @click="viewLesson">
        <v-chip color="primary" v-if="isTest" class="ip-chip" small text-color="white" @click="viewLesson">
          Driving Test
        </v-chip>

        <span v-else> {{ lesson.lessonName }}</span>
      </v-flex>
      <v-flex md1>
        <!-- <v-btn text color="orange" :to="{ name: 'ViewLesson', params: { lessonId: lessonId } }">View Lesson</v-btn> -->
        <v-layout class="justify-end ">
          <v-btn v-if="canEdit && !lesson.isPaid" class="mr-4 white-button" outlined small color="primary" :to="{ name: 'UpdateLesson', params: { lessonId: lessonId } }">
            Edit <v-icon dark>fas fa-pen small-icon pl-2</v-icon>
          </v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn v-if="canEdit && lesson.isPaid" class="mr-4" outlined small color="primary" disabled="">
                  Edit <v-icon dark>fas fa-pen small-icon pl-2</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Unable to edit, as this lesson has been paid</span>
          </v-tooltip>
          <v-chip color="red" v-if="!lesson.isPaid && !canEdit" class="ip-chip" small text-color="white" @click="viewLesson">
            <v-icon left>fas fa-times-circle xs-icon</v-icon> Unpaid
          </v-chip>

          <v-chip color="green" v-if="lesson.isPaid && !canEdit" class="ip-chip" small text-color="white" @click="viewLesson">
            <v-icon left>fas fa-check-circle xs-icon</v-icon> Paid
          </v-chip>
        </v-layout>
      </v-flex>

      <!-- <pre>{{lesson}}</pre> -->
    </v-layout>
  </div>
  <!-- <v-layout row wrap>
      <v-divider class="dark my-2"></v-divider>
  </v-layout>-->
</template>

<script>
import CalendarPage from '../../../../components/Shared/CalendarPage'
import moment from 'moment'
export default {
  components: {
    CalendarPage
  },
  props: {
    lesson: {
      type: Object
    }
  },
  data() {
    return {
      type: ''
    }
  },
  computed: {
    isTest() {
      return this.lesson.lessonType == 2
    },
    canEdit() {
      return (this.type == '' || this.type == 'upcoming') && this.isInstructor
    },
    lessonId() {
      return this.lesson.id
    },
    date() {
      return moment(this.lesson.localStartDateTime).calendar()
    },
    startTime() {
      return this.lesson.id
    },
    user() {
      return this.$store.state.auth.user
    },
    isInstructor() {
      return this.user.role == 'Instructor'
    },
    isStudent() {
      return this.user.role == 'Student'
    }
  },
  methods: {
    viewLesson() {
      this.$router.push({ name: 'ViewLesson', params: { lessonId: this.lessonId } })
    }
  },
  created() {
    this.type = this.$route.query.type ? this.$route.query.type : ''
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'digital-clock-font';
  src: url('../../../../assets/fonts/digital/digital-7.ttf');
}

.mobile .boxes {
  font-size: 1.2em;
  font-weight: bold;
}

.standard .boxes {
  font-size: 1.1em;
  font-weight: bold;
}

.lesson-item {
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;

  &.isTest {
  }
}

.lesson-item:hover {
  background: #f1f1f1;
}
</style>
