<template>
  <div>
    <v-card>
      <div class="pa-4">
        <p>Give this code to students to enable them to pair with you.</p>

        <div class="mb-6" style="width:300px">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-sheet class="white--text d-flex pa-4 text-center justify-center elevation-5" color="accent" v-on="on" @click="copyTextToClipboard">
                <h2 class="headline mb-0 text-center">{{ code }}</h2>
              </v-sheet>
            </template>
            <span>Click to copy</span>
          </v-tooltip>
        </div>

        <p>
          You can change your code at any time. If you do change your code, only students with the new code can pair with you. All your previous students will continue to
          remain as your students.
        </p>
      </div>

      <v-card-actions class="mx-2">
        <v-btn text primary @click="copyTextToClipboard"> <v-icon left>far fa-copy medium-icon</v-icon> Copy Code </v-btn>
        <v-spacer />
        <v-btn text @click="generateNewCode">Change Code</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
//import InstructorAPI from '@/services/instructor.service'
import instructorService from '@/services/generated/instructor.service.gen'

import { Mutations } from '@/store/mutations.type'

export default {
  data() {
    return {
      snackbar: false,
      code: ''
    }
  },
  methods: {
    generateNewCode() {
      //InstructorAPI.generateCode()
      instructorService
        .generateCode()
        .then(({ data }) => {
          console.log(data)
          this.code = data.newCode
          this.$store.commit(Mutations.SET_CODE, this.code)
        })
        .catch(({ response }) => {
          console.log(response)
        })
    },

    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        console.log('Fallback: Copying text command was ' + msg)
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
      this.snackbar = true
    },
    copyTextToClipboard() {
      this.$store.commit('global/showCopiedCode', this.code)
    }
  },
  created() {
    //InstructorAPI.getCode()
    instructorService
      .getCode()
      .then(({ data }) => {
        console.log(data)
        this.code = data.code
      })
      .catch(({ response }) => {
        console.log(response)
      })
  }
}
</script>

<style scoped></style>
