//lessonInstructor.service.gen.js
// import lessonInstructorService from '@/services/generated/lessonInstructor.service.gen'
import axios from 'axios'

export default {
  createLesson(studentId, startDateTime, duration, preLessonNotes, lessonCost, lessonType) {
    var model = {
      studentId: studentId,
      startDateTime: startDateTime,
      duration: duration,
      preLessonNotes: preLessonNotes,
      lessonCost: lessonCost,
      lessonType: lessonType
    }
    return axios.post('/api/instructor/lessons', model)
  },

  /*
					lessonInstructorService.createLesson(studentId, startDateTime, duration, preLessonNotes, lessonCost, lessonType)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  updateLesson(id, studentId, startDateTime, duration, lessonCost, postLessonNotes, lessonType) {
    var model = {
      studentId: studentId,
      startDateTime: startDateTime,
      duration: duration,
      lessonCost: lessonCost,
      postLessonNotes: postLessonNotes,
      lessonType: lessonType
    }
    return axios.put(`/api/instructor/lessons/${id}`, model)
  },

  /*
					lessonInstructorService.updateLesson(id, studentId, startDateTime, duration, lessonCost, postLessonNotes, lessonType)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  updateLessonNotes(id, postLessonNotes) {
    var model = {
      postLessonNotes: postLessonNotes
    }
    return axios.put(`/api/instructor/lessons/update-lesson-notes/${id}`, model)
  },

  /*
					lessonInstructorService.updateLessonNotes(id, postLessonNotes)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  lessonMarkAsPaid(id, paymentMethod, amount) {
    var model = {
      paymentMethod: paymentMethod,
      amount: amount
    }
    return axios.put(`/api/instructor/lessons/mark-as-paid/${id}`, model)
  },

  /*
					lessonInstructorService.lessonMarkAsPaid(id, paymentMethod, amount)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  cancelLesson(id) {
    return axios.put(`/api/instructor/lessons/cancel/${id}`)
  },

  /*
					lessonInstructorService.cancelLesson(id)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getStudentsLessonsForInstructor(studentId, type, pageNumber, pageSize) {
    return axios.get(`/api/instructor/lessons/student/${studentId}`, {
      params: {
        type: type,
        ...(pageNumber ? { pageNumber: pageNumber } : {}),
        ...(pageSize ? { pageSize: pageSize } : {})
      }
    })
  },

  /*
					lessonInstructorService.getStudentsLessonsForInstructor(studentId, type, pageNumber, pageSize)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getInstructorsLessons(type, pageNumber, pageSize) {
    return axios.get('/api/instructor/lessons/getinstructorslessons', {
      params: {
        type: type,
        ...(pageNumber ? { pageNumber: pageNumber } : {}),
        ...(pageSize ? { pageSize: pageSize } : {})
      }
    })
  },

  /*
					lessonInstructorService.getInstructorsLessons(type, pageNumber, pageSize)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getInstructorsCalendarForInstructor(month, year) {
    return axios.get('/api/instructor/lessons/calendar', {
      params: {
        month: month,
        year: year
      }
    })
  },

  /*
					lessonInstructorService.getInstructorsCalendarForInstructor(month, year)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  lessonsForDay(date) {
    return axios.get('/api/instructor/lessons/for-day', {
      params: {
        date: date
      }
    })
  }
  /*
					lessonInstructorService.lessonsForDay(date)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
