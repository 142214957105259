<template>
  <v-layout justify-center>
    <v-flex xs12 sm9 lg12>
      <v-card class="">
        <FormTitle class="hidden-sm-and-down">Change Password</FormTitle>

        <SuccessTick v-if="successSubmit">Password has been changed successfully</SuccessTick>

        <v-card-text v-if="!successSubmit" class="pt-6">
          <FormErrors :errors="errors" />

          <v-form v-model="valid" ref="form">
            <v-text-field
              :disabled="submitted"
              label="Old password"
              v-model="form.oldPassword"
              min="8"
              :append-icon="e1 ? 'fas fa-eye' : 'fas fa-eye-slash'"
              prepend-icon="fas fa-unlock small-icon"
              @click:append="() => (e1 = !e1)"
              :type="e1 ? 'text' : 'password'"
              :rules="oldPasswordRules"
              counter
              required
              tabindex="1"
            ></v-text-field>
            <v-text-field
              :disabled="submitted"
              label="Enter your new password"
              v-model="form.newPassword"
              min="8"
              :append-icon="e1 ? 'fas fa-eye' : 'fas fa-eye-slash'"
              prepend-icon="fas fa-edit small-icon"
              @click:append="() => (e1 = !e1)"
              :type="e1 ? 'text' : 'password'"
              :rules="newPasswordRules"
              counter
              required
              tabindex="2"
            ></v-text-field>
            <v-text-field
              :disabled="submitted"
              label="Confirm your new password"
              v-model="form.confirmPassword"
              min="8"
              :append-icon="e1 ? 'fas fa-eye' : 'fas fa-eye-slash'"
              prepend-icon="fas fa-edit small-icon"
              @click:append="() => (e1 = !e1)"
              :type="e1 ? 'text' : 'password'"
              :rules="confirmPasswordRules"
              counter
              required
              tabindex="3"
            ></v-text-field>

            <v-layout align-center>
              <v-btn @click="submit" :disabled="submitted" :class="{ 'primary white--text': valid, disabled: !valid }">Save</v-btn>
              <v-progress-circular size="18" width="2" v-if="submitted" indeterminate color="primary"></v-progress-circular>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
//import * as TYPES from "../../store/actions.type";
//import { Mutations } from "../../store/mutations.type";
import { Actions } from '../../store/actions.type'

export default {
  components: {},
  data() {
    return {
      submitted: false,
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      valid: false,
      e1: false,
      errors: [],
      oldPasswordRules: [v => !!v || 'Old password is required'],
      newPasswordRules: [v => !!v || 'New password is required'],
      confirmPasswordRules: [v => !!v || 'New password confirmation is required', v => v == this.form.newPassword || 'Password must match'],
      successSubmit: false
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.submitted = true
        this.$store
          .dispatch(Actions.CHANGE_PASSWORD, this.form)
          .then(response => {
            //this.$store.commit("global/setLoggedIn", true);
            //this.$router.push({ name: "root" });
            console.log(response)
            this.successSubmit = true
            setTimeout(() => {
              this.successSubmit = false
            }, 3000)
            this.submitted = false
            this.$refs.form.reset()
          })
          .catch(error => {
            this.submitted = false
            this.errors = error.data.errors
          })
      }
    },
    clear() {
      this.$refs.form.reset()
    }
  }
}
</script>

<style lang="scss" scoped></style>
