<template>
  <AuthWrapper>
    <template #form>
      <v-btn v-if="isDevelopment" color="accent" rounded @click="quickFill">Quick Fill</v-btn>
      <v-btn v-if="isDevelopment" color="accent" rounded @click="quickFill2">Quick Fill Student</v-btn>
      <v-flex xs12 sm9 elevation-6>
        <v-card class="mx-auto" max-width="900">
          <v-toolbar class="primary" flat dark>
            <v-toolbar-title>
              <h4>{{ currentTitle }}</h4>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <h3>Step</h3>
            <v-avatar color="primary lighten-2" class="subtitle-1 white--text ml-2" size="24" v-text="step"></v-avatar>
          </v-toolbar>
          <!-- <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ currentTitle }}</span>
            <v-avatar
              color="primary lighten-2"
              class="subtitle-1 white--text"
              size="24"
              v-text="step"
            ></v-avatar>
          </v-card-title>-->

          <v-window v-model="step">
            <v-window-item :value="1">
              <v-card-text class="userTypeStep">
                <h3>What type of user are you?</h3>

                <v-layout justify-center row pa-2>
                  <v-btn color="primary" rounded @click="setUserType('Instructor')">Instructor</v-btn>
                  <v-btn color="accent" rounded @click="setUserType('Student')">Learner</v-btn>
                </v-layout>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text class="pt-6">
                <FormErrors :errors="errors" />
                <v-form v-model="valid" ref="form">
                  <v-text-field id="email" label="Email address" v-model="form.email" :rules="emailRules" required :disabled="submitted" tabindex="1"></v-text-field>
                  <v-text-field
                    label="Password"
                    v-model="form.password"
                    min="8"
                    :append-icon="e1 ? 'fas fa-eye' : 'fas fa-eye-slash'"
                    @click:append="() => (e1 = !e1)"
                    :type="e1 ? 'text' : 'password'"
                    :rules="passwordRules"
                    counter
                    required
                    :disabled="submitted"
                    tabindex="2"
                  ></v-text-field>
                  <v-text-field
                    label="Confirm password"
                    v-model="form.confirmPassword"
                    min="8"
                    :append-icon="e1 ? 'fas fa-eye' : 'fas fa-eye-slash'"
                    @click:append="() => (e1 = !e1)"
                    :type="e1 ? 'text' : 'password'"
                    :rules="confirmPasswordRules"
                    :disabled="submitted"
                    counter
                    required
                    tabindex="3"
                  ></v-text-field>
                </v-form>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="3">
              <div class="pa-12 text-center">
                <span class="title font-weight-light py-6">Please check your email and click on the link to confirm your email</span>
              </div>
            </v-window-item>
          </v-window>

          <v-divider></v-divider>

          <v-card-actions v-if="step === 2">
            <v-btn :disabled="step === 1 || submitted" text @click="goBack">Back</v-btn>
            <v-spacer></v-spacer>
            <!-- <v-btn
        :disabled="step === 3"
        color="primary"
        depressed
        @click="step++"
      >
        Login
            </v-btn>-->

            <v-btn @click="submit" :disabled="submitted" :class="{ 'primary white--text': valid, disabled: !valid }"
              >Sign Up
              <v-progress-circular size="18" width="2" v-if="submitted" indeterminate color="primary" class="ml-2"></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 sm9 pa-4>
        <p class="text-center white--text title">
          Already have an account?
          <router-link to="/login" class="white--text">Log In</router-link>
        </p>
      </v-flex>
    </template>
    <template #rightside>
      <img v-show="step == 1 && hasNoChosenRole" src="@/assets/images/undraw_electric_car_b7hl.svg" class="animated flipInY" style="width: 50%; height: auto;" />
      <img v-show="step == 2 && isSigningUpAsStudent" src="@/assets/images/undraw_fast_car_p4cu.svg" class="animated flipInX" style="width: 50%; height: auto;" />
      <div class="text-center" v-show="step == 2 && isSigningUpAsInstructor">
        <h2 class="display-3">Free 30 day trial</h2>
        <h2 class="display-1 my-10">then {{ price }} per month</h2>
        <h2 class="title my-4">No payment details needed</h2>
      </div>
      <img v-show="step == 2 && isSigningUpAsInstructor" src="@/assets/images/undraw_professor_8lrt.svg" class="animated flipInX" style="width: 50%; height: auto;" />
      <img v-show="step == 3" src="@/assets/images/undraw_mail_cg1t.svg" class="animated flipInX" style="width: 30%; height: auto;" />

      <div class="pa-12 text-center">
        <span v-show="step == 2 && isSigningUpAsStudent" class="display-2 font-weight-light py-6">Sign Up as a Learner Driver</span>
        <span v-show="step == 2 && isSigningUpAsInstructor" class="display-2 font-weight-light py-6">Sign Up as a Driving Instructor</span>
      </div>
    </template>
  </AuthWrapper>
</template>

<script>
//import AuthService from "@/services/auth";
//import { REGISTER } from "@/store/actions.type";
import { Actions } from '../../store/actions.type'
import AuthWrapper from './AuthWrapper'

export default {
  components: {
    AuthWrapper
  },
  data() {
    return {
      valid: false,
      e1: false,
      form: {
        // firstName:"",
        // lastName:"",
        password: '',
        email: '',
        confirmPassword: '',
        role: '', //"Instructor",//"Student",
        client: 'Web'
      },
      // firstNameRules:[v => !!v || "First name is required"],
      // lastNameRules:[v => !!v || "Last name is required"],
      passwordRules: [v => !!v || 'Password is required'],
      confirmPasswordRules: [v => !!v || 'Password confirmation is required', v => v == this.form.password || 'Password must match'],
      //emailRules: [v => !!v || 'E-mail is required', v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'],
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid'],

      errors: [],
      step: 1,
      submitted: false
    }
  },
  computed: {
    price() {
      return process.env.VUE_APP_MONTHLY_COST
    },
    isDevelopment() {
      return process.env.NODE_ENV == 'development'
    },
    isSigningUpAsInstructor() {
      return this.form.role === 'Instructor'
    },
    isSigningUpAsStudent() {
      return this.form.role === 'Student'
    },
    hasNoChosenRole() {
      return this.form.role == ''
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return 'Type of User'
        case 2:
          return 'Sign Up' + (this.form.role === 'Instructor' ? ' as an Instructor' : ' as a Learner')
        default:
          return 'Account created'
      }
    },
    HasErrors() {
      return false //this.errors.length != 0
    },
    isValid() {
      return this.$refs.form.validate()
    }
  },
  methods: {
    goBack() {
      this.step--
      this.form.role = ''
    },
    setUserType(userType) {
      this.form.role = userType
      this.step = 2
    },

    makeid(length) {
      var result = ''
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    quickFill() {
      this.form.email = 'tom' + this.makeid(5) + '@mailinator.com'
      this.form.password = 'Password123'
      this.form.confirmPassword = 'Password123'
      this.form.role = 'Instructor'
      this.step = 2
      this.submit
    },

    quickFill2() {
      this.form.email = 'bobby' + this.makeid(5) + '@mailinator.com'
      this.form.password = 'Password123'
      this.form.confirmPassword = 'Password123'
      this.form.role = 'Student'
      this.step = 2
      this.submit
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.submitted = true

        this.$store
          .dispatch(Actions.REGISTER, this.form)
          .then(response => {
            this.step++

            //this.$store.commit("global/setLoggedIn", true);
            //this.$router.push({ name: "root" });
            console.log(response)
          })
          .catch(error => {
            this.submitted = false

            this.errors = error.data.errors
            console.log(error.data.errors)
          })
      }
    },
    clear() {
      this.$refs.form.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
.userTypeStep {
  text-align: center;
  .v-btn {
    margin: 15px;
  }
}
</style>
