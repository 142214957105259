<template>
  <div :class="{ 'max-width': block }">
    <Modal v-model="noStudentsDialog">
      <template #modalTitle>
        <span>No students</span>
      </template>
      <template #modalBody>
        <p>You currently don't have any students paired with you.</p>
        <p>Share your pairing code with your students and ask them to use this when signing up to InstructorPay</p>
        <h3 class="display-1 text-lg-center">{{ instructorCode }}</h3>
      </template>
    </Modal>

    <Modal v-model="noPermissionDialog">
      <template #modalTitle>
        <span>Access Denied</span>
      </template>
      <template #modalBody>
        <p>You currently do not have permission to Add Lessons</p>
        <p>Check your subscrption status, and ensure you have an Active subscription to enable full access on InstructorPay</p>
        <router-link :to="{ name: 'Subscription' }">My Subscription</router-link>
      </template>
    </Modal>
    <v-btn @click.stop="addLesson" :outlined="outlined" :small="small" :text="text" :block="block" class="mr-0 pointer  " :color="color">
      <v-icon class="mr-4 plus-icon">fas fa-plus small-icon</v-icon>Add Lesson
    </v-btn>
    <!-- <v-btn @click.stop="addLesson" :outlined="outlined" :small="small" :text="text" :block="block" class="mr-0 pointer hidden-md-and-up">
      <v-icon class="plus-icon">fas fa-plus small-icon</v-icon>
    </v-btn> -->
  </div>
</template>

<script>
//import InstructorAPI from '@/services/instructor.service'
import instructorService from '@/services/generated/instructor.service.gen'
import { mapGetters } from 'vuex'

//import moment from 'moment'
export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    outlined: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    studentId: {
      type: String,
      default: null
    }
  },

  components: {},
  data() {
    return {
      noStudentsDialog: false,
      noPermissionDialog: false
    }
  },
  computed: {
    instructorCode() {
      return this.$store.getters.user.profile.instructorCode
    },

    user() {
      return this.$store.state.auth.user
    },
    status() {
      return this.user.profile.subscriptionStatusViewModel
    },
    ...mapGetters(['hasAccess'])
  },
  methods: {
    addLesson() {
      if (!this.hasAccess) {
        this.noPermissionDialog = true
      } else {
        instructorService
          .hasStudents()
          .then(res => {
            if (res.data) {
              if (this.studentId) {
                this.$router.push({ name: 'AddLesson', query: { student: this.studentId } })
              } else {
                this.$router.push({ name: 'AddLesson' })
              }
            } else {
              this.noStudentsDialog = true
            }
          })
          .catch(({ response }) => {
            console.log(response)
          })
      }
    }
  }
}
</script>

<style scoped>
.max-width {
  width: 100%;
}
</style>
