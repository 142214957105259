//webhook.service.gen.js
// import webhookService from '@/services/generated/webhook.service.gen'
import axios from 'axios'

export default {

  updateAccount() {
    return axios.post('/api/webhook/updateaccount')
  },

  /*
					webhookService.updateAccount()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  invoicePayment() {
    return axios.post('/api/webhook/invoicepayment')
  },

  /*
					webhookService.invoicePayment()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  subscriptionUpdated() {
    return axios.post('/api/webhook/subscriptionupdated')
  },

  /*
					webhookService.subscriptionUpdated()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  setNgrok() {
    return axios.get('/api/webhook/setngrok')
  },

  /*
					webhookService.setNgrok()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getNgrok() {
    return axios.get('/api/webhook/getngrok')
  },

  /*
					webhookService.getNgrok()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  test() {
    return axios.get('/api/webhook/test')
  },

  /*
					webhookService.test()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  testConfig() {
    return axios.get('/api/webhook/testconfig')
  },

  /*
					webhookService.testConfig()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  testError() {
    return axios.get('/api/webhook/testerror')
  },

  /*
					webhookService.testError()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  testError2() {
    return axios.get('/api/webhook/testerror2')
  }
  /*
					webhookService.testError2()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
