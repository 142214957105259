<template>
  <div class="">
    <v-row no-gutters="">
      <v-col lg="12" sm="12">
        <div class=" grey lighten-4 pt-3">
          <v-row>
            <v-col md="6" xs="12" sm="6" cols="12">
              <div class="d-flex justify-center align-center flex-column">
                <h4 class="title mb-5">Overall Progress</h4>
                <v-progress-circular class="" :rotate="-90" :size="128" :width="15" :value="overallProgress" :color="progressColor">
                  {{ overallProgress }}
                </v-progress-circular>
              </div>
            </v-col>
            <v-col md="6" xs="12" sm="6" cols="12" class="">
              <v-row v-for="(item, index) in progressText" :key="index" :dense="true">
                <v-row no-gutters="">
                  <v-col cols="6" class="text-right pa-2">
                    <span class="body-2">{{ item }}</span></v-col
                  >
                  <v-col cols="6" class="pa-2">
                    <v-rating
                      :value="index + 1"
                      :length="index + 1"
                      :empty-icon="emptyIcon"
                      :full-icon="fullIcon"
                      :size="size"
                      :dense="dense"
                      :color="colors[index]"
                      :background-color="bgColor"
                      :readonly="true"
                      :hover="false"
                    >
                    </v-rating
                  ></v-col>
                </v-row>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col lg="12" sm="12">
        <v-row :dense="true" no-gutters="">
          <StudentsProgressListItem :studentProgressListItem="item" :studentId="userId" v-for="(item, index) in studentProgressList" :key="'pro' + index" />
        </v-row>
      </v-col>
    </v-row>

    <!-- <v-rating
      v-model="rating"
      :length="5"
      :empty-icon="'far fa-star'"
      :full-icon="'fas fa-star'"
      :hover="true"
      :readonly="readonly"
      :size="100"
      :color="this.color"
      :background-color="bgColor"
    ></v-rating> -->
    <!-- <pre>{{this.studentProgressList}}</pre> -->
  </div>
</template>

<script>
import StudentsProgressListItem from './StudentsProgressListItem'

export default {
  components: {
    StudentsProgressListItem
  },
  props: ['studentProgressList', 'studentId'],
  data: () => ({
    emptyIcon: 'far fa-star',
    fullIcon: 'fas fa-star',
    size: 16,
    dense: true,
    userId: '',

    halfIcon: 'fas fa-star-half-alt',
    halfIncrements: false,
    hover: true,
    length: 5,
    colors: ['red', 'orange', '#ead84d', '#abc123', 'green'],
    bgColor: 'grey lighten-1',
    progressText: ['Introduced', 'Under Full Instruction', 'Prompted', 'Seldom Prompted', 'Independant']
  }),
  computed: {
    overallProgress() {
      let progress = this.$store.getters['global/overallProgress']
      if (progress) {
        return progress
      }
      return 0
    },
    // progressSize() {
    //   if(this.$vuetify.breakpoint.xs){
    //     return 64
    //   }
    //   return 128
    // },
    progressColor() {
      if (this.overallProgress > 80) {
        return this.colors[4]
      }
      if (this.overallProgress > 60) {
        return this.colors[3]
      }
      if (this.overallProgress > 40) {
        return this.colors[2]
      }
      if (this.overallProgress > 20) {
        return this.colors[1]
      }
      return this.colors[0]
    }
  },
  methods: {
    getColor(rating) {
      return this.colors[rating - 1]
    }
  },
  created() {
    this.userId = this.studentId
  }
}
</script>

<style lang="scss" scoped>
.flex-even {
  flex: 1;
}
</style>
