//profileInstructor.service.gen.js
// import profileInstructorService from '@/services/generated/profileInstructor.service.gen'
import axios from 'axios'

export default {
  getProfileInstructorOwn() {
    return axios.get('/api/instructor/profile/getprofileinstructorown')
  },

  /*
					profileInstructorService.getProfileInstructorOwn()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  updateProfile(standardLessonFee, website, drivingSchoolName, transmission, firstName, lastName, telephoneNumber, address) {
    var tempAddress = null
    if (address) {
      tempAddress = {
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        addressLine3: address.addressLine3,
        postCode: address.postCode
      }
    }
    var model = {
      standardLessonFee: standardLessonFee,
      website: website,
      drivingSchoolName: drivingSchoolName,
      transmission: transmission,
      firstName: firstName,
      lastName: lastName,
      telephoneNumber: telephoneNumber,
      address: tempAddress
    }
    return axios.put('/api/instructor/profile', model)
  }
  /*
					profileInstructorService.updateProfile(standardLessonFee, website, drivingSchoolName, transmission, firstName, lastName, telephoneNumber, address)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
