<template>
  <div>
    <AdminHeader />
  </div>
</template>

<script>
import AdminHeader from './AdminHeader.vue'

export default {
  components: {
    AdminHeader
  },
  data() {
    return {}
  },
  computed: {},

  created() {}
}
</script>

<style scoped></style>
