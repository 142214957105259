<template>
  <li :class="classObject" class="elevation-1">
    <div class="body font-weight-medium mb-2">
      {{ message.message }}
    </div>
    <div class="text-right caption grey--text	">
      {{ localDateCreated }}
    </div>
  </li>
  <!-- <pre>{{user}}</pre> -->
  <!-- <pre>{{message }}</pre> -->
</template>

<script>
//import StudentsItem from './StudentsItem'
//import StudentsPaging from "./StudentsPaging";
// import { Actions } from "@/store/actions.type";
// import { mapState } from "vuex";
// import { mapMutations } from "vuex";
import moment from 'moment'

export default {
  props: ['message'],
  components: {
    //ChatConversationItem
    //  StudentsPaging
  },
  data() {
    return {}
  },
  computed: {
    localDateCreated() {
      var messageDate = moment(this.message.localDateCreated)
      var current = moment()
      var isSameDate = messageDate.isSame(current, 'date')
      if (isSameDate) {
        return messageDate.format('HH:mm')
      } else {
        return messageDate.format('DD/MM/YY HH:mm')
      }
    },
    user() {
      return this.$store.state.auth.user
    },
    fullName() {
      return this.user.profile.fullName
    },

    isMessageFromeMe() {
      return this.user.profile.userId == this.message.fromUserId
    },
    classObject: function() {
      return {
        'me primary lighten-5': this.isMessageFromeMe,
        'them white lighten-2': !this.isMessageFromeMe,
        regular: !this.$vuetify.breakpoint.xs,
        large: this.$vuetify.breakpoint.xs
      }
    }
  },
  methods: {
    // fetchConversation() {
    //   this.$store
    //     .dispatch('chat/fetchConversation')
    //     .then(response => {
    //       //this.$store.commit("global/setLoggedIn", true);
    //       //this.$router.push({ name: "root" });
    //       console.log(response)
    //     })
    //     .catch(error => {
    //       console.log('TCL: fetchContacts -> error', error)
    //     })
    // }
  },

  created() {
    // this.fetchConversation()
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li {
  display: inline-block;
  clear: both;
  padding: 6px 12px;
  border-radius: 10px;
  margin-bottom: 8px;
  font-family: Helvetica, Arial, sans-serif;
}

.them {
  float: left;
}

.me {
  float: right;
}

.them + .me {
  border-bottom-right-radius: 0px;
}

.me + .me {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.me:last-of-type {
  border-bottom-right-radius: 5px;
}

.regular {
  max-width: 60%;
}

.large {
  max-width: 85%;
}
</style>
