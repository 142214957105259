<template>
  <v-flex xs12 class="text-center text-sm-center text-md-center text-lg-center">
    <!-- <img :src="imageUrl" height="150" v-if="imageUrl" />
    <v-text-field label="Select Image" @click="pickFile" v-model="imageName"></v-text-field>-->
    <input :value="fileUpload" type="file" ref="image" accept="image/*" @change="onFilePicked" />

    <Modal v-model="bigFileModal">
      <template #modalTitle>
        <span>File is too big</span>
      </template>
      <template #modalBody>
        <p>Please choose an image that is less than 5mb</p>
      </template>
    </Modal>
  </v-flex>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      errors: [],

      // IMAGE UPLOAD
      title: 'Image Upload',
      dialog: false,
      imageName: '',
      imageUrl: '',
      imageFile: '',
      bigFileModal: false,
      fileUpload: ''
      //************/
    }
  },
  methods: {
    pickFile() {
      this.$refs.image.click()
    },
    uploadImage() {
      var model = {
        image: this.imageFile
      }
      this.$emit('avatarSaved', model)

      // ProfileAPI.updateAvatar(model)
      //   .then(({ data }) => {
      //     debugger;
      //     this.$emit("avatarSaved", data);

      //     console.log(data);
      //     //this.lessonCreated = true;
      //     //this.students = data.students;
      //     //this.pageMetaData = data.pageMetaData
      //   })
      //   .catch(({ response }) => {
      //     console.log(response);
      //   });
    },
    onFilePicked(e) {
      const files = e.target.files
      if (files[0] !== undefined) {
        this.imageName = files[0].name
        if (this.imageName.lastIndexOf('.') <= 0) {
          return
        }

        if (files[0].size > 5 * 1024 * 1024) {
          e.preventDefault()
          this.bigFileModal = true
          //document.getElementById('fileUpload').val('')
          this.fileUpload = ''
          return
        } else {
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.imageUrl = fr.result
            this.imageFile = files[0] // this is an image file that can be sent to server...
            this.uploadImage()
          })
        }
      } else {
        this.imageName = ''
        this.imageFile = ''
        this.imageUrl = ''
      }
    }
  }
}
</script>
