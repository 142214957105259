//paymentInstructor.service.gen.js
// import paymentInstructorService from '@/services/generated/paymentInstructor.service.gen'
import axios from 'axios'

export default {
  getInstructorPayments(pageNumber, pageSize, order) {
    return axios.get('/api/instructor/payments', {
      params: {
        ...(pageNumber ? { pageNumber: pageNumber } : {}),
        ...(pageSize ? { pageSize: pageSize } : {}),
        order: order
      }
    })
  }
  /*
					paymentInstructorService.getInstructorPayments(pageNumber, pageSize, order)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
