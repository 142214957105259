<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm12 lg6>
      <v-card>
        <FormTitle>Pair with Instructor</FormTitle>

        <FormErrors :errors="errors" />
        <v-card-text class="pt-6">
          <v-layout>
            <v-flex xs12>
              <v-form v-model="valid" ref="form">
                <v-text-field :disabled="submitted" id="code" label="Enter your code" v-model="code" :rules="codeRules" required></v-text-field>
                <v-btn class="mt-2" :disabled="submitted" :class="{ 'primary white--text': valid, disabled: !valid }" @click="pairWithInstructor">
                  Pair Up With Instructor
                  <v-progress-circular size="18" width="2" v-if="submitted" indeterminate color="primary" class="ml-2"></v-progress-circular>
                </v-btn>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
//import StudentAPI from '@/services/student.service'
import studentService from '@/services/generated/student.service.gen'

import { Mutations } from '../../../store/mutations.type'

export default {
  data() {
    return {
      valid: false,
      submitted: false,
      code: '',
      codeRules: [v => !!v || 'code is required'],
      errors: []
    }
  },
  methods: {
    pairWithInstructor() {
      if (this.$refs.form.validate()) {
        this.submitted = true

        studentService
          .pairWithInstructor(this.code)
          .then(({ data }) => {
            console.log(data)

            this.$store.commit(Mutations.SET_PROFILE, data)
            //this.submitted = false

            console.log('paired')
            setTimeout(() => {
              this.$router.push({ name: 'root' })
            }, 1500)
          })
          .catch(error => {
            this.errors = error.response.data.errors
            this.submitted = false
          })
        // this.$store
        //   .dispatch('student/'+Actions.PAIR_WITH_INSTRUCTOR, this.code)
        //   .then(response => {
        //     //this.$store.commit("global/setLoggedIn", true);
        //     alert("paired");
        //     //this.$router.push({ name: "root" });
        //     console.log(response);
        //   })
        //   .catch(error => {
        //     alert("error");
        //     this.errors = error.data.errors;
        //     console.log(error.data.errors);
        //   });
      }
    }
  }
}
</script>

<style scoped></style>
