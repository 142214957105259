<template>
  <div>
    <v-expansion-panels focusable class="elevation-0 pa-0">
      <v-expansion-panel v-for="(payment, index) in payments" :key="'e' + index" class="pa-0">
        <v-expansion-panel-header>
          <PaymentsItemMini v-bind:payment="payment" :isMini="true" v-bind:key="'item' + index" />
        </v-expansion-panel-header>
        <v-expansion-panel-content class="grey pa-0 ">
          <div class="mt-6" :class="{ 'pa-1': $vuetify.breakpoint.smAndDown }">
            <PaymentsItemLessons v-bind:payment="payment" v-bind:key="'lesson' + index" />
            <v-layout wrap class="white--text body-2">
              <v-flex lg12 class="mt-4">
                <span class="mr-12">Payment method: {{ payment.paymentMethod }}</span>

                <span>Reference:{{ payment.id }}</span>
              </v-flex>
            </v-layout>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- <table class="v-datatable v-table theme--light" v-if="hasItems">
          <thead>
            <tr>
            
              <th class="text-xs-left">Amount <br />Paid</th>
              <th class="text-xs-left">Amount <br />Transferred</th>
              <th class="text-xs-left">Date <br /> Paid</th>

              <th class="text-xs-left">Payment <br /> Method</th>
              <th class="text-xs-left"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(payment, index) in payments">
              <PaymentsItem v-bind:payment="payment" :isMini="true" v-bind:key="'item'+index"/>
              <PaymentsItemLessons v-bind:payment="payment" v-bind:key="'lesson'+index"/>
            </template>
          </tbody>
    </table>-->
    <div v-if="!hasItems">
      <h4 class="pa-12 text-center">There are no payments</h4>
    </div>
  </div>
</template>

<script>
import PaymentsItemMini from '../Payments/PaymentsItemMini'
import PaymentsItemLessons from '../Payments/PaymentsItemLessons'

export default {
  props: ['payments'],
  components: {
    PaymentsItemMini,
    PaymentsItemLessons
    //PaymentsPaging
  },
  data() {
    return {}
  },
  computed: {
    hasItems() {
      return this.payments && this.payments.length
    }
  },
  methods: {},

  created() {}
}
</script>

<style scoped></style>
