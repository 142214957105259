<template>
  <v-layout>
    <v-flex>
      <PageHeading title="Lessons">
        <template #side>
          <LessonsAddButton v-if="$vuetify.breakpoint.smAndDown" />
        </template>
      </PageHeading>

      <!-- <pre>{{this.groupedLessons}}</pre> -->

      <v-layout align-center justify-space-between row class="pa-0 ma-0">
        <v-layout wrap :justify-space-between="$vuetify.breakpoint.smAndDown">
          <v-btn color="accent" class="elevation-0" :text="!upcomingActive" small @click="changeType('upcoming')">Upcoming</v-btn>
          <v-btn color="accent" class="elevation-0" :class="{ 'mx-4': !$vuetify.breakpoint.smAndDown }" :text="!previousActive" small @click="changeType('previous')"
            >Previous</v-btn
          >
          <v-btn color="accent" class="elevation-0" :text="!unpaidActive" small @click="changeType('unpaid')">Unpaid</v-btn>
        </v-layout>
        <LessonsAddButton v-if="!$vuetify.breakpoint.smAndDown" />
      </v-layout>
      <v-divider class="my-6"></v-divider>
      <Spinner :isLoading="isLoading" />
      <div v-show="!isLoading">
        <div v-infinite-scroll="fetchLessons" infinite-scroll-disabled="busy" infinite-scroll-distance="pageSize">
          <div v-if="hasItems">
            <div v-for="(group, indexg) in groupedLessons" v-bind:key="indexg" :class="{ 'date-lessons': $vuetify.breakpoint.smAndDown }">
              <template v-for="(lesson, index) in group">
                <LessonsItem v-if="!$vuetify.breakpoint.smAndDown" v-bind:lesson="lesson" v-bind:key="index" />
                <LessonsItemMobile v-if="$vuetify.breakpoint.smAndDown" v-bind:lesson="lesson" v-bind:key="'mob' + index" />

                <v-divider v-bind:key="'div' + index" class="dark my-2 hidden-md-and-up"></v-divider>
              </template>
              <v-divider light class="hidden-sm-and-down" style="margin:15px 0px"></v-divider>
            </div>
          </div>
        </div>
        <div v-if="!hasItems">
          <h4 class="ma-12 text-lg-center">There are currently no {{ currentType }} lessons</h4>
        </div>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
//import LessonsAPI from '@/services/lessons.service'
import lessonInstructorService from '@/services/generated/lessonInstructor.service.gen'

import LessonsItem from './LessonsItem'
import LessonsItemMobile from './LessonsItemMobile'
import LessonsAddButton from './LessonsAddButton'

import _ from 'lodash'
//import moment from 'moment'
export default {
  components: {
    LessonsItem,
    LessonsItemMobile,
    LessonsAddButton
  },
  data() {
    return {
      type: '',
      pageSize: 20,
      lessons: [],
      pageMetaData: {},
      busy: false,
      isLoading: true
    }
  },
  computed: {
    lessonIds() {
      return _.map(this.lessons, 'id')
    },
    groupedLessons() {
      // var groups = _.groupBy(this.lessons, function(date) {
      //   return moment(date)
      //     .startOf('justDate')
      //     .format()
      // })

      var groups = _.groupBy(this.lessons, 'justDate')

      console.log(groups)
      return groups
    },

    nextPage() {
      return this.pageMetaData.nextPage ? this.pageMetaData.nextPage : 1
    },

    upcomingActive() {
      return this.type === 'upcoming' || this.type === ''
    },
    unpaidActive() {
      return this.type === 'unpaid'
    },
    previousActive() {
      return this.type === 'previous'
    },
    hasItems() {
      return this.lessons && this.lessons.length
    },
    currentType() {
      return this.type === '' ? 'upcoming' : this.type
    },
    instructorCode() {
      return this.$store.getters.user.profile.instructorCode
    }
  },
  methods: {
    changeType(type) {
      this.type = type
      this.lessons = []
      this.pageMetaData = {}
      this.isLoading = true
      this.fetchLessons()
    },

    fetchLessons() {
      this.busy = true
      let prevRoute = this.$route.path
      if (this.nextPage == 1 || this.pageMetaData.hasNext) {
        lessonInstructorService
          .getInstructorsLessons(this.type, this.nextPage, this.pageSize)
          .then(({ data }) => {
            this.pageMetaData = data.pageMetaData

            this.lessons.push(...data.lessons)
            this.busy = false
            this.isLoading = false
            let currentRoute = this.$route.path
            if (currentRoute === prevRoute) {
              this.$router.push({
                name: 'Lessons',
                query: {
                  ...(this.type ? { type: this.type } : {})
                  //...(this.pageNumber ? { pageNumber: this.pageNumber } : {})
                }
              })
            }
          })
          .catch(({ response }) => {
            console.log('TCL: fetchLessons -> response', response)
            //this.busy = false
            this.isLoading = false
          })
      }
    }
  },

  created() {
    this.type = this.$route.query.type ? this.$route.query.type : 'upcoming'
  }
}
</script>

<style scoped>
.activeFilter {
  font-weight: bold;
  text-decoration: underline;
}
.plus-icon {
  font-size: 12px;
}

.date-lessons:nth-child(odd) {
  background-color: #dfdfdf;
}

.date-lessons:nth-child(even) {
  background-color: #fff;
}
</style>
