<template>
  <div v-if="subscriptionStatus">
    <Spinner :isLoading="isLoading" />
    <v-card v-if="!isLoading">
      <div class="pa-4">
        <div v-if="this.hasPaymentIssue">
          <div v-if="this.subscriptionStatusStripe.requiresPaymentMethod">
            <v-alert type="warning" dark icon="fas fa-exclamation-triangle" border="left">
              There was an issue with your payment. Please try entering your card details again.
            </v-alert>
          </div>

          <div v-if="this.subscriptionStatusStripe.requiresAction">
            <v-alert type="warning" dark icon="fas fa-exclamation-triangle" border="left">
              To complete payment for your subsription, extra steps are required to verify your identity.
            </v-alert>
          </div>
        </div>
        <div v-if="!this.hasPaymentIssue">
          <!-- // TRIALLING -->
          <div v-if="isTrialing">
            <v-alert type="success" dark icon="fas fa-check" border="left">
              Trial
            </v-alert>
            <p>You are currently trialing with InstructorPay</p>
            <p>
              Your trial period will end on
              <v-chip class="grey lighten-2 font-weight-black" label>
                {{ currentPeriodEnd }}
              </v-chip>
            </p>
            <p>
              To continue accessing all features of InstructorPay after this date, subscribe for just
              <v-chip class="grey lighten-2 font-weight-black" :class="{ strike: isCouponValid }" label> {{ price }} </v-chip>
              <v-chip v-if="isCouponValid" class="ml-4 grey lighten-2 font-weight-black" label> {{ priceDiscount }} </v-chip>
              a month.
            </p>
            <p>You will be able to cancel this at any time.</p>
          </div>

          <!-- // TRIAL ENDED -->
          <div v-if="isTrialEnded">
            <v-alert type="warning" dark border="left">
              Trial Ended
            </v-alert>
            <p>Your trial with InstructorPay has expired</p>
            <p>
              To continue accessing all features of InstructorPay, subscribe for just
              <v-chip class="grey lighten-2 font-weight-black" label>
                {{ price }}
              </v-chip>
              a month.
            </p>
            <p>You will be able to cancel this at any time.</p>
          </div>

          <!-- // isIncomplete -->
          <div v-if="isIncomplete">
            <h3 class="title">Subscription Incomplete</h3>
            <v-alert type="warning" dark icon="fas fa-exclamation-triangle" border="left">
              Subscription Incomplete
            </v-alert>
            <p>Your subscription with InstructorPay is incomplete</p>
            <p>
              To continue accessing all features of InstructorPay, enter your card details below.
            </p>
            <p>You will be able to cancel this at any time.</p>
          </div>

          <!-- // ACTIVE -->
          <div v-if="isActive">
            <v-alert type="success" dark icon="fas fa-check" border="left">
              Active Subscription
            </v-alert>
            <p>You are currently subscribed with InstructorPay</p>
            <p>If you wish to cancel your subscription you can do so by <a class="link-button" @click="cancelSubscriptionDialog = true">clicking here</a></p>
          </div>

          <!-- // CANCELLED -->
          <div v-if="isCancelled">
            <v-alert type="error" dark icon="fas fa-ban" border="left">
              Cancelled Subscription
            </v-alert>
            <p>Your subscription with InstructorPay is currently cancelled</p>
            <p>
              To continue accessing all features of InstructorPay, re-subscribe for just
              <v-chip class="grey lighten-2 font-weight-black" label>
                {{ price }}
              </v-chip>
              a month.
            </p>
          </div>

          <!-- // ENDED -->
          <div v-if="isEnded">
            <v-alert type="error" dark icon="fas fa-ban" border="left">
              Subscription Ended
            </v-alert>
            <p>Your subscription with InstructorPay has ended</p>
            <p>
              To continue accessing all features of InstructorPay, re-subscribe for just
              <v-chip class="grey lighten-2 font-weight-black" label>
                {{ price }}
              </v-chip>
              a month.
            </p>
          </div>
        </div>
        <!-- <pre>ipp{{ this.subscriptionStatus }}</pre>
        <pre>ss{{ this.subscriptionStatusStripe }}</pre> -->

        <SubscriptionStripePayment v-if="showCard" :isCouponValid="isCouponValid" />
      </div>
    </v-card>

    <Modal v-model="cancelSubscriptionDialog" :isPersistent="true">
      <template #modalTitle>
        <span>Cancel your monthly subscription</span>
      </template>
      <template #modalBody>
        <v-alert :value="subscriptionWasCancelled" class="mb-12" color="success" icon="fas fa-check-circle"
          >Your subscription has been cancelled, and will not renew after <span class="font-weight-bold"> {{ currentPeriodEnd }} </span></v-alert
        >
        <p>Are you sure you want to cancel your subscription with InstructorPay?</p>

        <p>
          You will lose access to some features, and will have to re-subscribe to gain access
        </p>
      </template>

      <template #okButton>
        <v-btn @click="cancelSubscription" :disabled="submitted" :class="{ 'primary white--text': true }"
          >Cancel Subscription
          <v-spacer v-if="submitted"></v-spacer>
          <v-progress-circular v-if="submitted" size="16" width="2" indeterminate color="primary"></v-progress-circular>
        </v-btn>
      </template>
    </Modal>
  </div>
</template>

<script>
import SubscriptionStripePayment from './SubscriptionStripePayment'
//import _ from 'lodash'
//import StripeAPI from '../../../services/stripe.service'
import stripeBillingService from '@/services/generated/stripeBilling.service.gen'
import { SubscriptionStatus } from '../../../helpers/enums'
import moment from 'moment'
//import { Mutations } from '@/store/mutations.type'
import { Actions } from '@/store/actions.type'

import { mapGetters } from 'vuex'

//card = undefined

export default {
  components: { SubscriptionStripePayment },
  data() {
    return {
      //subscriptionStatus: null,
      cancelSubscriptionDialog: false,
      subscriptionWasCancelled: false,
      submitted: false,
      combinedStatus: {},
      isLoading: true,
      isDiscountMode: true,
      isCouponValid: false
    }
  },
  computed: {
    isTrialing() {
      return this.subscriptionStatus.subscriptionStatus == SubscriptionStatus.Trialing
    },

    isTrialEnded() {
      return this.subscriptionStatus.subscriptionStatus == SubscriptionStatus.TrialEnded
    },
    isIncomplete() {
      return this.subscriptionStatus.subscriptionStatus == SubscriptionStatus.Incomplete
    },
    isActive() {
      return this.subscriptionStatus.subscriptionStatus == SubscriptionStatus.Active
    },
    isEnded() {
      return this.subscriptionStatus.subscriptionStatus == SubscriptionStatus.Ended
    },
    isCancelled() {
      return this.subscriptionStatus.subscriptionStatus == SubscriptionStatus.Cancelled
    },
    showCard() {
      return this.isTrialing || this.isTrialEnded || this.isIncomplete || this.isEnded || this.isCancelled || this.hasPaymentIssue
    },
    price() {
      return process.env.VUE_APP_MONTHLY_COST
    },
    priceDiscount() {
      return process.env.VUE_APP_MONTHLY_COST_DISCOUNT
    },
    currentPeriodEnd() {
      return moment(this.subscriptionStatus.currentPeriodEnd).format('DD/MM/YYYY')
    },
    hasPaymentIssue() {
      return this.subscriptionStatusStripe.requiresAction || this.subscriptionStatusStripe.requiresPaymentMethod
    },
    ...mapGetters(['subscriptionStatus', 'subscriptionStatusStripe'])
  },
  methods: {
    cancelSubscription() {
      this.submitted = true
      stripeBillingService
        .cancelSubscription()
        .then(response => {
          console.log(response)
          //this.status.subscriptionStatus = SubscriptionStatus.Cancelled
          //this.$store.commit(Mutations.SET_SUBSCRIPTION_STATUS, response.data)
          //this.getSubscriptionStatus()
          this.$store.dispatch(Actions.GET_SUBSCRIPTION)

          this.cancelSubscriptionDialog = false
          this.submitted = false

          // this.subscriptionWasCancelled = true
          // setTimeout(() => {
          //   this.cancelSubscriptionDialog = false
          //   this.subscriptionWasCancelled = false
          // }, 3000)
        })
        .catch(({ response }) => {
          console.log(response)
        })
    },

    // getSubscriptionStatus() {
    //   StripeAPI.getSubscriptionStatus()
    //     .then(response => {
    //       console.log(response.data)
    //       this.combinedStatus = response.data
    //       debugger
    //       this.$store.commit(Mutations.SET_SUBSCRIPTION_STATUS, response.data.ipSubscriptionStatus)
    //       this.$store.commit(Mutations.SET_SUBSCRIPTION_STATUS_STRIPE, response.data.stripeSubscriptionStatus)
    //     })
    //     .catch(({ response }) => {
    //       console.log(response)
    //     })
    //     .finally(() => {
    //       //this.isLoading = false
    //     })
    // }

    // reactivateSubscription() {
    //   StripeAPI.reactivateSubscription()
    //     .then(({ data }) => {
    //       console.log(data)
    //       this.status.subscriptionStatus = SubscriptionStatus.Active
    //     })
    //     .catch(({ response }) => {
    //       console.log(response)
    //     })
    // }
    getCouponStatus() {
      stripeBillingService
        .getCouponStatus()
        .then(response => {
          this.isCouponValid = response.data.isValid
        })
        .catch()
        .finally(() => {
          //this.isLoading = false
        })
    }
  },
  created() {
    this.$store.dispatch(Actions.GET_SUBSCRIPTION).then(() => {
      this.isLoading = false
    })

    this.getCouponStatus()
  }
}
</script>

<style scoped>
h3 {
  margin-bottom: 15px;
}

.strike {
  text-decoration: line-through;
}
</style>
