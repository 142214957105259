<template>
  <div v-if="studentId" class="justify-space-between d-flex">
    <!-- <v-btn small outlined color="primary" style="background:#fff" @click="setLicenceNumberDialog = true">Edit</v-btn> -->
    <span class="licence" @click="setLicenceNumberDialog = true" v-if="licenceNumber">{{ licenceNumber }}</span>
    <span class="licence" @click="setLicenceNumberDialog = true" v-else>[ Not Set ]</span>
    <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" @click="setLicenceNumberDialog = true">fas fa-pen small-icon mx-4 primary--text</v-icon>
      </template>
      <span>Edit licence number</span>
    </v-tooltip>
    <Modal v-model="setLicenceNumberDialog" :isPersistent="true">
      <template #modalTitle>
        <span>Set licence number</span>
      </template>
      <template #modalBody>
        <v-form v-model="valid" ref="formLicenceNumber">
          <v-alert :value="licenceNumberWasSet" class="mb-12 white--text" color="success" icon="fas fa-check-circle white--text">Licence number has been set</v-alert>
          <p class="body-1">Licence number for {{ fullName }}</p>
          <v-text-field prepend-icon="fas fa-id-badge" :rules="licenceNumberRules" class="ma-0" label="Licence number" v-model="licenceNumberValue"></v-text-field>
        </v-form>
      </template>

      <template #okButton>
        <v-btn @click="setStudentLicence" :disabled="submitted" :class="{ 'primary white--text': valid, disabled: !valid }"
          >Set licence number <v-progress-circular size="18" width="2" v-if="submitted" class="pl-4" indeterminate color="primary"></v-progress-circular
        ></v-btn>
      </template>
    </Modal>
  </div>
</template>

<script>
//import InstructorAPI from '@/services/instructor.service'
import instructorService from '@/services/generated/instructor.service.gen'
export default {
  props: ['licenceNumber', 'studentId', 'fullName'],
  components: {},
  data() {
    return {
      valid: false,
      //studentId: '',
      //fullName: '',

      licenceNumberValue: '',

      licenceNumberRules: [v => !!v || 'Licence Number is required'],

      setLicenceNumberDialog: false,
      licenceNumberWasSet: false,
      submitted: false
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    isInstructor() {
      return this.user.role == 'Instructor'
    },
    isStudent() {
      return this.user.role == 'Student'
    }
  },
  methods: {
    setStudentLicence() {
      if (this.$refs.formLicenceNumber.validate()) {
        // let model = {
        //   studentId: this.studentId,
        //   licenceNumber: this.licenceNumberValue
        // }
        this.submitted = true
        //InstructorAPI.setStudentLicenceNumber(model)
        instructorService
          .setLicenceNumber(this.studentId, this.licenceNumberValue)
          .then(({ data }) => {
            console.log(data)
            this.licenceNumberWasSet = true
            //that.$parent.setLicenceNumber("ddd")//this.licenceNumberValue)
            this.$emit('event', this.licenceNumberValue)
            setTimeout(() => {
              this.setLicenceNumberDialog = false
              this.licenceNumberWasSet = false
              this.submitted = false
            }, 2000)
          })
          .catch(({ response }) => {
            console.log(response)
            this.submitted = false
          })
      }
    }
  },

  created() {
    this.licenceNumberValue = this.licenceNumber
  }
}
</script>

<style lang="scss" scoped>
.licence {
  cursor: pointer;
  text-decoration: underline;
}
</style>
