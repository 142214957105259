<template>
  <v-row>
    <v-col xs="6">
      <v-btn color="" class="mr-10" :to="{ name: 'AdminUsers', query: { type: 'student' } }">students </v-btn>
      <v-btn color="primary" :to="{ name: 'AdminUsers', query: { type: 'instructor' } }">Instructors </v-btn>
    </v-col>
    <v-col xs="6">
      <div class="d-flex justify-end align-start">
        <v-btn color="" class="mr-10" @click="getNgrok">GET EP</v-btn>
        <v-btn color="success" @click="setNgrok">Set NGROK dev</v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
//import StripeAPI from '../../services/stripe.service'
import webhookService from '@/services/generated/webhook.service.gen'

export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    isDevelopment() {
      return process.env.NODE_ENV == 'development'
    }
  },
  methods: {
    setNgrok() {
      if (this.isDevelopment) {
        webhookService.setNgrok().then(
          setTimeout(() => {
            this.getNgrok()
          }, 3000)
        )
      }
    },
    getNgrok() {
      if (this.isDevelopment) {
        webhookService.getNgrok().then(result => {
          alert(result.data)
        })
      }
    }
  },
  created() {}
}
</script>

<style scoped></style>
