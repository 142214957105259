<template>
  <MiscWrapper>
    <template #content>
      <v-layout row wrap justify-center>
        <v-flex xs12 lg8> </v-flex>
      </v-layout>
    </template>
  </MiscWrapper>
</template>

<script>
import MiscWrapper from './MiscWrapper'
import { Actions } from '../../store/actions.type'

export default {
  components: {
    MiscWrapper
  },
  data() {
    return {
      form: {
        email: 'luke.hopkins@example.com',
        password: 'Password123',
        client: 'Web'
      }
    }
  },
  methods: {},
  mounted() {
    this.$store.dispatch(Actions.LOGIN, this.form).then(response => {
      //this.$store.commit("global/setLoggedIn", true);
      this.$router.push({ name: 'root' })
      console.log(response)
    })
  }
}
</script>
