<template>
  <!-- <div>
    <v-card v-if="!isMini" class="calendar-page">
      <v-card-title class="primary white--text pa-2 justify-center" :class="classObject">
        <h5 class="headline mb-0">{{ month }} {{ day }}</h5>
      </v-card-title>
      <v-card-text class="pb-6">
        <h1>{{ day }}</h1>
      </v-card-text>
    </v-card>

  

  
  </div> -->
  <div class="d-flex elevation-0">
    <div class=" justify-center subtitle-2 text-center flex-even " :class="classObject">{{ dayName }}, {{ day }} {{ month }}</div>
    <!-- <div class=" justify-center subtitle-2 text-center flex-even  " :class="classObject">
      {{ day }}
    </div>
    <div class=" justify-center align-center subtitle-2 flex-even  " style="text-align:center" :class="classObject">
      {{ month }}
    </div> -->
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    date: null,
    title: {
      type: String
    },
    type: {
      type: String
    },
    isMini: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    classObject() {
      return '' //this.type == 'previous' ? 'lighten-4' : ''
    },
    day() {
      return moment(this.date).format('D')
    },
    dayName() {
      return moment(this.date).format('ddd')
    },
    month() {
      return moment(this.date).format('MMM')
    }
  }
}
</script>

<style scoped>
.calendar-page {
  /* width: 120px; */
  text-align: center;
}

.calendar-page-mini {
  /* width: 80px; */
  text-align: center;
}

.flex-even {
  flex: 1;
}
</style>
