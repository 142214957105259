/* eslint-disable no-debugger */
import axios from 'axios'
import store from '@/store/index'
import Storage from '@/services/storage'
import { Actions } from '@/store/actions.type'
//import { Mutations } from '@/store/mutations.type'
import router from '../router'
//import Vue from 'vue'

let isRefreshing = false
let subscribers = []

export default function execute() {
  // Request
  axios.defaults.baseURL = process.env.VUE_APP_API_URL
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  console.log(process.env.VUE_APP_API_URL)
  console.log(process.env.NODE_ENV)
  console.log('****')


  
  axios.interceptors.request.use(
    config => {
      const token = Storage.getAccessToken()
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }

      const url = config.url.split('/')

      if (url[url.length - 1] === 'login') {
        delete config.headers.Authorization
      }

      return config
    },
    error => Promise.reject(error)
  )

  // Response
  axios.interceptors.response.use(
    response => {
      console.log('bbbbbb')
      return response
    },
    error => {
      console.log(error)
      const {
        config,
        response: { status }
      } = error
      const originalRequest = config
      if (status == 400 && error.response.data.errors[0] == 'Invalid refresh token') {
        store.dispatch(Actions.LOGOUT).then(() => {
          router.push({ name: 'root' })
        })
      } else if (status === 401) {
        if (!isRefreshing) {
          isRefreshing = true

          let tokenModel = {
            accessToken: Storage.getAccessToken(),
            client: 'Web',
            refreshToken: Storage.getRefreshToken()
          }

          store
            .dispatch(Actions.REFRESH, { tokenModel })
            .then(() => {
              //const { data } = respaonse
              isRefreshing = false
              onRrefreshed(Storage.getAccessToken())
              subscribers = []
            })
            .catch(error => {
              isRefreshing = false
              console.log(error)
              store.dispatch(Actions.LOGOUT).then(() => {
                router.push({ name: 'root' })
              })
              //context.commit(Mutations.SET_ERROR, response.data.errors)
            })
        }
        const requestSubscribers = new Promise(resolve => {
          subscribeTokenRefresh(token => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            resolve(axios(originalRequest))
          })
        })
        return requestSubscribers
      } else {
        if (status === 500) {
          // store.commit('global/setError', error.response.data)
          //alert('Environment:' + process.env.NODE_ENV)
          console.log('Environment')
          console.log(process.env.NODE_ENV)

          //store.commit('global/setErrorDev', error.response.data)
          //router.push({ name: 'Errors' })
          console.log('error dsfsdf')

          console.log(error)

          if (process.env.NODE_ENV == 'development') {
            store.commit('global/setErrorDev', error.response.data)
            router.push({ name: 'Errors' })
          } else {
            store.commit('global/setError', 'An unexpected error occurred')
            setTimeout(() => {
              store.commit('global/setError', '')
            }, 6000)
            console.log('TCL: execute -> error', error)
          }
          return Promise.reject(error)
        }

        if (status == 400 && error.response.data.errors[0] == 'Invalid refresh token') {
          store.dispatch(Actions.LOGOUT).then(() => {
            router.push({ name: 'root' })
          })
        }

        if (status == 403) {
          store.commit('global/setError', "You don't have permission to do this, check your subription status in your profile.")
          setTimeout(() => {
            store.commit('global/setError', '')
          }, 6000)
          //this.errors = ["You don't have permission to do this, check your subription status in your profile."]
        }
        //alert("error")

        return Promise.reject(error)
      }
      //return Promise.reject(error)
    }
  )
}

function subscribeTokenRefresh(cb) {
  subscribers.push(cb)
}

function onRrefreshed(token) {
  subscribers.map(cb => cb(token))
}
