<template>
  <div class="chip" :class="[size, { backed: isBacked }, { pointer: isClickable && !isBacked }]" @click="goToProfile">
    <img :src="this.imageUrl | asProfilePicture" alt="Person" />
    {{ name }}
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String
    },
    name: {
      type: String
    },
    userId: {
      type: String
    },
    size: {
      default: 'sm',
      type: String
    },
    isClickable: {
      default: true,
      type: Boolean
    },

    isBacked: {
      default: true,
      type: Boolean
    },

    isAvatarStudent: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      // avatarSize: 48
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    isInstructor() {
      return this.user.role == 'Instructor'
    },
    isStudent() {
      return this.user.role == 'Student'
    }
  },
  methods: {
    goToProfile() {
      if (this.isClickable) {
        if (this.isInstructor) {
          if (this.isAvatarStudent) {
            this.$router.push({ name: 'StudentView', params: { studentId: this.userId } })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin setSize($size: 32px) {
  padding: 0 $size/2;
  height: $size;
  font-size: $size * 0.42;
  line-height: $size;
  border-radius: $size/2;
  img {
    margin: 0 ($size/2) 0 (-$size/2);
    height: $size;
    width: $size;
  }
}

$baseSize: 32px;
$multiplier: 1.2;

$xs: $baseSize / $multiplier;
$sm: $xs * $multiplier;
$md: $sm * $multiplier;
$lg: $md * $multiplier;
$xl: $lg * 1.5;

$chipbg: #ededed;

.xs {
  @include setSize($size: $xs);
}
.sm {
  @include setSize($size: $sm);
}
.md {
  @include setSize($size: $md);
}

.lg {
  @include setSize($size: $lg);
}

.xl {
  @include setSize($size: $xl);
}

.chip {
  display: inline-block;
  font-weight: bold;
  &.backed {
    background-color: $chipbg;
    cursor: pointer;
    &:hover {
      background-color: darken($chipbg, 10%);
      -webkit-transition: background-color 150ms linear;
      -ms-transition: background-color 150ms linear;
      transition: background-color 150ms linear;
    }
  }

  &.pointer {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.chip img {
  float: left;
  border-radius: 50%;
}
</style>
