<template>
  <div>
    <v-layout v-if="!isMini" text-lg-center text-center pa-10 column class="animated flipInX">
      <v-icon>fas fa-check-circle ma-12 success--text large-icon</v-icon>
      <h2>
        <slot>Success</slot>
      </h2>
    </v-layout>
    <div v-if="isMini" class="d-flex justify-start align-center animated flipInX">
      <v-icon>fas fa-check-circle ma-12 success--text medium-tick </v-icon>
      <h4 class="title">
        <slot>Success</slot>
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isMini: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.medium-tick {
  font-size: 3em;
}
</style>
