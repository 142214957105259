//profileShared.service.gen.js
// import profileSharedService from '@/services/generated/profileShared.service.gen'
import axios from 'axios'

export default {
  deleteAvatar() {
    return axios.post('/api/profile-shared/delete-avatar')
  },

  /*
					profileSharedService.deleteAvatar()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  updateAvatar(profileImage) {
    var formData = new FormData()
    formData.append('profileImage', profileImage)

    return axios.post('/api/profile-shared/update-avatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  /*
					profileSharedService.updateAvatar(profileImage)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
