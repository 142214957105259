import { render, staticRenderFns } from "./StudentSideBar.vue?vue&type=template&id=50aa1935&scoped=true&"
import script from "./StudentSideBar.vue?vue&type=script&lang=js&"
export * from "./StudentSideBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50aa1935",
  null
  
)

export default component.exports