//stripeBilling.service.gen.js
// import stripeBillingService from '@/services/generated/stripeBilling.service.gen'
import axios from 'axios'

export default {
  confirmPaymentSubscription(payment_method_id, payment_intent_id, onlyUpdatePaymentMethod) {
    var model = {
      payment_method_id: payment_method_id,
      payment_intent_id: payment_intent_id,
      onlyUpdatePaymentMethod: onlyUpdatePaymentMethod
    }
    return axios.post('/api/stripebilling/confirm-payment-subscription', model)
  },

  /*
					stripeBillingService.confirmPaymentSubscription(payment_method_id, payment_intent_id, onlyUpdatePaymentMethod)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getSubscriptionStatus() {
    return axios.get('/api/stripebilling/subscription-status')
  },

  /*
					stripeBillingService.getSubscriptionStatus()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  cancelSubscription() {
    return axios.get('/api/stripebilling/cancel-subscription')
  },

  /*
					stripeBillingService.cancelSubscription()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  renewSubscription() {
    return axios.post('/api/stripebilling/renew-subscription')
  },

  /*
					stripeBillingService.renewSubscription()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getCouponStatus() {
    return axios.get('/api/stripebilling/coupon-status')
  }
  /*
					stripeBillingService.getCouponStatus()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
