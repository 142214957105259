//import { Actions } from './actions.type'
//import { SET_AUTH, PURGE_AUTH, SET_ERROR, UPDATE_TOKENS } from "./mutations.type";

import { Mutations } from './mutations.type'
import _ from 'lodash'

//import PaymentAPI from '../services/payment.service'

// initial state
const state = {
  expandedPayments: []
  //isLoggedIn: false
}

// getters
const getters = {
  expandedPayments(state) {
    return state.expandedPayments
  },

  isExpanded(state, id) {
    return _.some(state.expandedPayments, id.toString())
  }
}

// actions
const actions = {}

// mutations
const mutations = {
  [Mutations.PUSH_PAYMENTID](state, id) {
    state.expandedPayments.push(id)
  },

  [Mutations.POP_PAYMENTID](state, id) {
    _.pull(state.expandedPayments, id)

    let arr = state.expandedPayments

    state.expandedPayments = arr.filter(item => item !== id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
