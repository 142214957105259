<template>
  <div class="lesson-item pa-2">
    <v-row align-center no-gutters align="center">
      <v-col sm="3" hidden-sm-and-down class="hidden-sm-and-down" @click="viewLesson">
        <CalendarPage :isMini="true" hidden-sm-and-down :type="type" :date="this.lesson.localStartDateTime" class="pointer hidden-sm-and-down" @click="viewLesson" />
      </v-col>
      <!-- <pre>{{lesson}}</pre> -->
      <v-col md="9" sm="12">
        <v-row no-gutters align="center">
          <v-col xs="3" class="pointer hidden-md-and-up subtitle-2" @click="viewLesson">
            {{ lesson.justDateSlash }}<br />
            {{ lesson.justTime24 }}
          </v-col>
          <v-col xs="3" class="pointer subtitle-2 text-center pa-0  hidden-sm-and-down" @click="viewLesson">
            {{ lesson.justTime24 }}
          </v-col>

          <v-col xs="3" class="pointer subtitle-2 align-center align-content-center  text-center pa-0" @click="viewLesson">
            <span> {{ lesson.friendlyDuration }}</span>
          </v-col>
          <v-col xs="3" class="pointer subtitle-2 text-center pa-0" @click="viewLesson">
            {{ lesson.lessonCost | asMoney }}
          </v-col>
          <v-col xs="3" class="pointer subtitle-2 text-center pa-0" @click="viewLesson">
            {{ lesson.lessonName }} <br />
            <v-chip color="red" v-if="!lesson.isPaid && !canEdit" class="ip-chip  hidden-sm-and-up" x-small text-color="white" @click="viewLesson">
              Unpaid
            </v-chip>
            <v-chip color="green" v-if="lesson.isPaid && !canEdit" class="ip-chip  hidden-sm-and-up" x-small text-color="white" @click="viewLesson">
              Paid
            </v-chip>
          </v-col>
          <v-col xs="12" sm="3" md="3" class=" text-right pa-0  hidden-md-and-down">
            <v-btn v-if="canEdit && !lesson.isPaid" small outlined color="primary" :to="{ name: 'UpdateLesson', params: { lessonId: lessonId } }"
              >Edit<v-icon class="plus-icon">fas fa-pen small-icon ml-5</v-icon></v-btn
            >

            <v-tooltip top class="pa-0">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn v-if="canEdit && lesson.isPaid" outlined small color="primary" disabled="">
                    Edit<v-icon class="plus-icon">fas fa-pen small-icon ml-5</v-icon></v-btn
                  >
                </div>
              </template>
              <span>Unable to edit, as this lesson has been paid</span>
            </v-tooltip>

            <v-chip color="red" v-if="!lesson.isPaid && !canEdit" class="ip-chip " small text-color="white" @click="viewLesson">
              <v-icon left>fas fa-times-circle xs-icon</v-icon> Unpaid
            </v-chip>

            <v-chip color="green" v-if="lesson.isPaid && !canEdit" class="ip-chip " small text-color="white" @click="viewLesson">
              <v-icon left class="">fas fa-check-circle xs-icon</v-icon> Paid
            </v-chip>

            <!-- <v-btn class="text-center white--text elevation-0" style="font-size:12px" block small color="red" @click="viewLesson" v-if="!lesson.isPaid && !canEdit">
              Unpaid
            </v-btn>
            <v-btn class="text-center white--text elevation-0" style="font-size:12px" block small color="green" @click="viewLesson" v-if="lesson.isPaid && !canEdit">
              Paid
            </v-btn> -->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>

  <!-- <v-row>
      <v-col sm10>
        <v-row class="justify-space-between row">
          <v-btn text color="orange" :to="{ name: 'ViewLesson', params: { lessonId: lessonId } }">View</v-btn>
          <v-btn text color="orange" :to="{ name: 'UpdateLesson', params: { lessonId: lessonId } }">Edit</v-btn>
        </v-row>
      </v-col>
    </v-row>-->
</template>

<script>
import CalendarPage from '../../../../components/Shared/CalendarPage'
import moment from 'moment'
export default {
  components: {
    CalendarPage
  },
  props: {
    lesson: {
      type: Object
    },
    type: {
      type: String
    }
  },
  data() {
    return {}
  },
  computed: {
    // classObject(){
    //   return this.type =='previous'?:""
    // },
    canEdit() {
      return this.type == 'upcoming' && this.isInstructor
    },
    lessonId() {
      return this.lesson.id
    },
    date() {
      return moment(this.lesson.localStartDateTime).calendar()
    },
    startTime() {
      return this.lesson.id
    },
    user() {
      return this.$store.state.auth.user
    },
    // profilePicture() {
    //   return this.user.profile.profilePicture
    // },
    fullName() {
      return this.user.profile.fullName
    },
    isInstructor() {
      return this.user.role == 'Instructor'
    },
    isStudent() {
      return this.user.role == 'Student'
    }
  },
  methods: {
    viewLesson() {
      this.$router.push({ name: 'ViewLesson', params: { lessonId: this.lessonId } })
    }
  }
}
</script>

<style scoped>
.lesson-item {
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.lesson-item:hover {
  background: #f5f5f5;
}
</style>
