<template>
  <div>
    <v-btn outlined class="mr-4 white-button" small @click.stop="appointmentDialog = true">Add Unavailability</v-btn>

    <Modal v-model="appointmentDialog">
      <template #modalTitle>
        <span>Add Unavailability</span>
      </template>

      <template #modalBody>
        <!-- <SuccessTick v-if="successSubmit">Saved</SuccessTick> -->
        <v-layout text-lg-center text-center pa-10 column class="" v-if="successSubmit">
          <v-icon>fas fa-check-circle ma-12 success--text large-icon</v-icon>
          <h2>
            Saved
          </h2>
        </v-layout>
        <FormErrors :errors="errors" />
        <v-form v-if="!successSubmit" v-model="valid" ref="form">
          <v-text-field label="Notes" v-model="title" prepend-icon="far fa-sticky-note" :rules="titleRules" class="pa-6" :disabled="submitted"></v-text-field>

          <v-menu :close-on-content-click="false" v-model="menu1" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                :value="computedDateFormatted"
                label="Date "
                prepend-icon="far fa-calendar-alt"
                :rules="dateRules"
                readonly
                class="pa-6"
                :disabled="submitted"
              ></v-text-field>
            </template>
            <v-date-picker :min="today" v-model="date" @input="dateSelected"></v-date-picker>
          </v-menu>

          <v-dialog ref="dialog1" v-model="modal1" :return-value.sync="time1" persistent width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                :disabled="submitted"
                v-on="on"
                v-model="time1"
                :rules="timeRules1"
                label="Start time"
                prepend-icon="fas fa-hourglass-start"
                readonly
                class="pa-6"
              ></v-text-field>
            </template>

            <v-time-picker v-if="modal1" v-model="time1" format="24hr" :allowed-minutes="allowedStep" @input="timeSelected1">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal1 = false">Cancel</v-btn>
              <v-btn text :class="{ 'primary white--text': valid, disabled: !valid }" @click="$refs.dialog1.save(time1)">OK</v-btn>
            </v-time-picker>
          </v-dialog>

          <v-dialog ref="dialog2" v-model="modal2" :return-value.sync="time2" persistent width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                :disabled="submitted"
                v-on="on"
                v-model="time2"
                :rules="timeRules2"
                label="End time"
                prepend-icon="fas fa-hourglass-end"
                readonly
                class="pa-6"
              ></v-text-field>
            </template>

            <v-time-picker v-if="modal2" v-model="time2" format="24hr" :allowed-minutes="allowedMins" :allowed-hours="allowedHours" @input="timeSelected2">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal2 = false">Cancel</v-btn>
              <v-btn text :class="{ 'primary white--text': valid, disabled: !valid }" @click="$refs.dialog2.save(time2)">OK</v-btn>
            </v-time-picker>
          </v-dialog>
        </v-form>
      </template>

      <template #okButton>
        <!-- <v-btn @click="markAsPaid" text :disabled="submitted" :class="{ 'primary white--text': valid, disabled: !valid }">Set as Paid</v-btn> -->

        <v-btn v-if="!successSubmit" @click="addAppointment" :disabled="submitted" :class="{ 'primary white--text': valid, disabled: !valid }"
          >Add <v-progress-circular class="ml-6" size="18" width="2" v-if="submitted" indeterminate color="primary"></v-progress-circular>
        </v-btn>
      </template>
    </Modal>
  </div>
</template>

<script>
import appointmentService from '@/services/generated/appointment.service.gen'
import moment from 'moment'

export default {
  components: {},
  data() {
    return {
      valid: false,

      submitted: false,
      successSubmit: false,

      appointmentDialog: false,

      dateRules: [v => !!v || 'Date is required'],
      timeRules1: [v => !!v || 'Start time is  required'],
      timeRules2: [v => !!v || 'End time is  required', v => this.datesOk(v) || 'End time must be after start time'],
      titleRules: [v => !!v || 'Notes is required'],

      errors: [],
      title: '',

      date: null,

      menu1: false,

      time1: null,
      time2: null,

      modal1: false,
      modal2: false
    }
  },

  computed: {
    computedDateFormatted() {
      return this.date ? moment(this.date).format('DD/MM/YYYY') : null
    },
    today() {
      return moment().format('YYYY-MM-DD')
    },
    start() {
      var date = this.date
      var time = this.time1

      var dateTime = moment(date + ' ' + time).format('YYYY-MM-DD HH:mm')
      return dateTime
    },
    end() {
      var date = this.date
      var time = this.time2

      var dateTime = moment(date + ' ' + time).format('YYYY-MM-DD HH:mm')
      return dateTime
    },
    startHour() {
      var time = this.time1
      var dateTime = moment(time, 'HH:mm').format('HH')
      return dateTime
    },

    endHour() {
      var time = this.time2
      var dateTime = moment(time, 'HH:mm').format('HH')
      return dateTime
    },

    startMin() {
      var time = this.time1
      var dateTime = moment(time, 'HH:mm').format('mm')
      return dateTime
    }
  },

  methods: {
    datesOk(d) {
      console.log('TCL: datesOk -> d', d)
      var beginningTime = moment(this.time1, 'h:mma')
      var endTime = moment(this.time2, 'h:mma')
      var isOk = beginningTime.isBefore(endTime) // true
      return isOk
    },
    dateSelected() {
      this.menu1 = false
      console.log(this.date)
    },

    allowedStep(m) {
      return m % 5 === 0
    },
    allowedHours(m) {
      return m >= this.startHour
    },

    allowedMins(m) {
      if (this.endHour == this.startHour) {
        return m > this.startMin && m % 5 === 0
      }
      return m % 5 === 0
    },

    addAppointment() {
      if (this.$refs.form.validate()) {
        this.submitted = true
        this.errors = []

        appointmentService
          .createAppointment(this.start, this.end, this.title)
          .then(() => {
            this.$parent.fetchAppointments()

            this.successSubmit = true
            this.$refs.form.reset()
            setTimeout(() => {
              this.appointmentDialog = false
              this.successSubmit = false
              this.submitted = false
              this.date = null
            }, 2000)
          })
          .catch(error => {
            console.log(error)
            this.submitted = false
            this.errors = error.response.data.errors
          })
      }
    },
    timeSelected1() {
      console.log(this.time1)

      let t1 = moment(this.time1, 'h:mma')
      this.time2 = moment(t1)
        .add(60, 'minutes')
        .format('HH:mm')
    },
    timeSelected2() {
      console.log(this.time2)
    }
  },

  mounted() {}
}
</script>

<style lang="scss" scoped></style>
