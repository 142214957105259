<template>
  <div>
    <Spinner :isLoading="isLoading" />

    <div v-if="studentData">
      <!-- <pre>{{this.studentData}}</pre> -->

      <v-row>
        <v-col md="4" xs="12" sm="12">
          <v-toolbar color="grey lighten-2" dense flat dark v-if="isInstructor && isActiveStudent">
            <v-spacer></v-spacer>

            <v-menu bottom float-left>
              <template v-slot:activator="{ on }">
                <v-btn light icon v-on="on">
                  <v-icon dark>fas fa-ellipsis-v small-icon</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="removeStudentDialog = true">
                  <v-icon class="pr-4 small-icon">fas fa-times</v-icon>
                  <v-list-item-title v-text="'Remove Student'"></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <div class="grey lighten-3 pa-4">
            <div class="d-flex flex-column justify-start align-center">
              <!-- <img :src="studentData.profilePicture | asProfilePicture" alt="Person" /> -->
              <v-avatar :size="128" color="grey lighten-4 elevation-3">
                <img :src="studentData.profilePicture | asProfilePicture" class="circle" />
              </v-avatar>
              <h2 class="my-4">{{ studentData.fullName }}a</h2>

              <LessonsAddButton v-if="isInstructor && isActiveStudent" :text="false" :block="true" :studentId="studentData.studentId" />

              <!-- <v-btn v-if="isInstructor && isActiveStudent" text block class="primary" :to="{ name: 'AddLesson', query: { student: studentId } }">
                <v-icon class="pr-4 small-icon">fas fa-plus</v-icon>Add Lesson
              </v-btn> -->
            </div>

            <v-sheet class="pa-6 my-4">
              <v-layout row wrap>
                <v-flex xs5 text-lg-right>
                  <h4>Telephone:</h4>
                </v-flex>
                <v-flex xs7 text-lg-left pl-4>{{ studentData.telephone }}</v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs5 text-lg-right>
                  <h4>Address:</h4>
                </v-flex>
                <v-flex xs7 text-lg-left pl-4>
                  <ul class="address-list" v-if="address">
                    <li v-if="address.addressLine1">{{ address.addressLine1 }}</li>
                    <li v-if="address.addressLine2">{{ address.addressLine2 }}</li>
                    <li v-if="address.addressLine3">{{ address.addressLine3 }}</li>
                    <li v-if="address.addressLine4">{{ address.addressLine4 }}</li>
                    <li v-if="address.postCode">{{ address.postCode }}</li>
                  </ul>
                </v-flex>
              </v-layout>

              <v-divider class="my-4" v-if="isActiveStudent"></v-divider>

              <v-layout row wrap v-if="isActiveStudent">
                <v-flex xs5 text-lg-right>
                  <h4>Lesson Cost:</h4>
                </v-flex>

                <v-flex xs7 text-lg-left pl-4 align-self-center="" class="justify-space-between d-flex">
                  <span class="pointer underline" @click="setLessonCostDialog = true">{{ hourlyRate | asMoney }}</span>

                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" @click="setLessonCostDialog = true">fas fa-pen small-icon mx-4 primary--text</v-icon>
                    </template>
                    <span>Edit hourly rate</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>

              <v-divider class="my-4"></v-divider>

              <v-layout row wrap v-if="isStudent">
                <v-flex xs5 text-lg-right>
                  <h4>Licence Number:</h4>
                </v-flex>
                <v-flex xs7 text-lg-left pl-4 align-self-center="">
                  <span v-if="studentData.licenceNumber">{{ studentData.licenceNumber }}</span>
                  <span v-else>[ Set by instructor ]</span>
                </v-flex>
              </v-layout>
              <v-layout row wrap v-if="isActiveStudent">
                <v-flex xs5 text-lg-right>
                  <h4>Licence Number:</h4>
                </v-flex>
                <v-flex xs7 text-lg-left pl-4 align-self-center="">
                  <StudentViewLicenceModal
                    @event="setLicenceNumber"
                    :studentId="studentData.studentId"
                    :licenceNumber="studentData.licenceNumber"
                    :fullName="studentData.fullName"
                  />
                </v-flex>
              </v-layout>
            </v-sheet>
            <!-- <div class="d-flex justify-end align-start pa-3 grey lighten-2" v-if="isActiveStudent">
              <v-btn text block small outlined color="primary" style="background:#fff" @click="setLessonCostDialog = true"
                >Edit hourly rate <v-icon class="plus-icon">fas fa-pen small-icon ml-5</v-icon></v-btn
              >
            </div>
            <div>
              <StudentViewLicenceModal :studentId="studentData.studentId" :licenceNumber="studentData.licenceNumber" :fullName="studentData.fullName" />
            </div> -->
            <v-btn v-if="hasInstructor" text block outlined color="primary" style="background:#fff" @click="myInstructorDialog = true"
              ><v-icon>far fa-address-book mr-4</v-icon> My Instructor
            </v-btn>

            <v-alert type="success" v-if="hasPassed" class="mt-4">
              Congratulations on passing your driving test!
            </v-alert>
          </div>
        </v-col>
        <v-col md="8" xs="12" sm="12">
          <v-expansion-panels :value="[0, 2]" multiple="">
            <v-expansion-panel class="accordion">
              <v-expansion-panel-header>
                <div class="d-flex justify-start"><v-icon left>fas fa-car panel-icon</v-icon> <span class="title ml-2">Lessons</span></div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="grey lighten-3 pt-6 pa-xs-0">
                <v-tabs background-color="" class="elevation-0" slider-color="primary">
                  <v-tab>
                    Upcoming Lessons
                  </v-tab>
                  <v-tab>
                    Previous Lessons
                  </v-tab>

                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <StudentsMiniLessonsList :type="'upcoming'" :lessons="upcomingLessons" />
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <StudentsMiniLessonsList :type="'previous'" :lessons="previousLessons" />
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="accordion" v-if="studentData">
              <v-expansion-panel-header>
                <div class="d-flex justify-start"><v-icon left>fas fa-pound-sign panel-icon</v-icon> <span class="title ml-2">Payments</span></div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="grey lighten-3 pt-6 pa-0">
                <div class="text-right" v-if="studentData.payments && studentData.payments.length != 0">
                  <v-btn v-if="isStudent && studentData.canBePaidWithStripe" class="ma-4 small primary white--text" outlined="" :to="{ name: 'MakePayments' }">
                    <v-icon>fas fa-credit-card small-icon mr-4</v-icon>Make Payment
                  </v-btn>
                </div>

                <StudentsMiniPaymentList :payments="studentData.payments" />
                <div class="text-center mb-6" v-if="studentData.payments && studentData.payments.length == 0">
                  <v-btn color="primary" v-if="isStudent && studentData.canBePaidWithStripe" class="ma-4 small primary white--text" :to="{ name: 'MakePayments' }">
                    <v-icon>fas fa-credit-card small-icon mr-4</v-icon>Make Payment
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="d-flex justify-start"><v-icon left>fas fa-star panel-icon</v-icon> <span class="title ml-2">Progress</span></div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <div flat v-if="isStudent || (isInstructor && isActiveStudent)">
                  <StudentsProgress :studentProgressList="studentData.studentProgressList" :studentId="studentData.studentId" />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- <v-tabs grow="" background-color="grey lighten-2" class="elevation-3" slider-color="primary">
            <v-tab>
              Upcoming Lessons
            </v-tab>
            <v-tab>
              Previous Lessons
            </v-tab>
            <v-tab>
              Payments
            </v-tab>
            <v-tab v-if="isStudent || (isInstructor && isActiveStudent)">
              Progress
            </v-tab>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <StudentsMiniLessonsList :type="'upcoming'" :lessons="upcomingLessons" />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <StudentsMiniLessonsList :type="'previous'" :lessons="previousLessons" />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <div class="text-right" v-if="studentData.payments.length != 0">
                  <v-btn color="primary" v-if="isStudent && studentData.canBePaidWithStripe" class="ma-4 small" outlined="" :to="{ name: 'MakePayments' }">
                    <v-icon>fas fa-credit-card small-icon mr-4</v-icon>Make Payment
                  </v-btn>
                </div>

                <StudentsMiniPaymentList :payments="studentData.payments" />
                <div class="text-center mb-6" v-if="studentData.payments.length == 0">
                  <v-btn color="primary" v-if="isStudent && studentData.canBePaidWithStripe" class="ma-4 small" :to="{ name: 'MakePayments' }">
                    <v-icon>fas fa-credit-card small-icon mr-4</v-icon>Make Payment
                  </v-btn>
                </div>
              </v-card>
            </v-tab-item>

            <v-tab-item v-if="isStudent || (isInstructor && isActiveStudent)">
              <v-card flat>
                <v-card-text>
                  <StudentsProgress :studentProgressList="studentData.studentProgressList" :studentId="studentData.studentId" />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs> -->
        </v-col>
      </v-row>

      <Modal v-model="myInstructorDialog" :padding="'pa-0'">
        <template #modalTitle>
          <span>My Instructor</span>
        </template>
        <template #modalBody>
          <InstructorProfile @dismiss="dismissInstructorDialog" />
        </template>
      </Modal>

      <Modal v-model="removeStudentDialog">
        <template #modalTitle>
          <span>Remove {{ studentData.fullName }}</span>
        </template>
        <template #modalBody>
          <v-alert :value="studentWasRemoved" class="mb-12" color="success" icon="fas fa-trash-alt">{{ studentData.fullName }} has been removed</v-alert>
          <v-alert class="mb-6" :value="true" type="warning"
            >Are you sure you want to remove this student? This will cancel all upcoming lesson you have with them</v-alert
          >

          <p>
            If the student has passed their test, ask them to set their status to
            <strong>"Passed"</strong> on their profile.
          </p>

          <p>Only remove this student if you no longer want to take them on.</p>
        </template>
        <template #okButton>
          <v-btn color="primary" text @click="setStatus(3)">Remove Student</v-btn>
        </template>
      </Modal>

      <Modal v-model="setLessonCostDialog" :isPersistent="true">
        <template #modalTitle>
          <span>Set hourly rate</span>
        </template>
        <template #modalBody>
          <v-form v-model="valid" ref="form">
            <v-alert :value="lessonCostWasSet" class="mb-12 white--text" color="success" icon="fas fa-check-circle white--text">The lesson cost has been set</v-alert>
            <p class="body-1">The cost of a 1 hour lesson with {{ studentData.fullName }}</p>
            <v-text-field
              prepend-icon="fas fa-pound-sign "
              :rules="hourlyRateRules"
              class="ma-0"
              type="number"
              label="Hourly lesson cost"
              v-model="studentData.hourlyRate"
            ></v-text-field>
          </v-form>
        </template>

        <template #okButton>
          <v-btn @click="setStudentLessonCost" :disabled="submitted" :class="{ 'primary white--text': valid, disabled: !valid }"
            >Set hourly rate <v-progress-circular size="18" width="2" v-if="submitted" class="pl-4" indeterminate color="primary"></v-progress-circular
          ></v-btn>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
//import InstructorAPI from '@/services/instructor.service'
//import StudentAPI from '../../../../services/student.service'
import instructorService from '@/services/generated/instructor.service.gen'
import studentService from '@/services/generated/student.service.gen'

import { Mutations } from '@/store/mutations.type'
import LessonsAddButton from '../../Instructor/Lessons/LessonsAddButton'
import StudentViewLicenceModal from './StudentViewLicenceModal'
import { mapGetters } from 'vuex'

import moment from 'moment'
import _ from 'lodash'

import StudentsMiniLessonsList from './StudentsMiniLessonsList'
import StudentsMiniPaymentList from './StudentsMiniPaymentList'
import StudentsProgress from './StudentsProgress'
import InstructorProfile from '../../Student/InstructorProfile'
const Status = {
  Pending: 0,
  Active: 1,
  Passed: 2,
  Removed: 3,
  StudentDeleted: 4
}

export default {
  components: {
    StudentsMiniLessonsList,
    StudentsMiniPaymentList,
    StudentsProgress,
    InstructorProfile,
    LessonsAddButton,
    StudentViewLicenceModal
  },
  data() {
    return {
      valid: false,
      studentData: null,
      studentId: '',
      myInstructorDialog: false,
      removeStudentDialog: false,
      setLessonCostDialog: false,
      lessonCostWasSet: false,
      studentWasRemoved: false,
      hourlyRate: '',
      hourlyRateRules: [v => !!v || 'Amount must be set', v => v > 10 || 'Amount must be more than 10', v => v < 35 || 'Amount must be less than 35'],
      submitted: false,
      isLoading: true

      //licenceNumber: ''
    }
  },
  computed: {
    ...mapGetters(['profile', 'hasInstructor']),
    hasPassed() {
      return this.profile.hasPassed
    },

    isActiveStudent() {
      return this.studentData.status == Status.Active
    },

    address() {
      if (this.studentData.addresses) {
        return this.studentData.addresses[0]
      }
      return null
    },
    user() {
      return this.$store.state.auth.user
    },
    isInstructor() {
      return this.user.role == 'Instructor'
    },
    isStudent() {
      return this.user.role == 'Student'
    },
    previousLessons() {
      let today = moment(new Date())
      console.log(today)
      let lessons = _.filter(this.studentData.lessons, function(lesson) {
        return moment(lesson.endDateTime).isBefore(moment()) //lesson.endDateTime < today
      })
      return lessons.sort(function(a, b) {
        return new Date(b.startDateTime) - new Date(a.startDateTime)
      })
      //return lessons
    },
    upcomingLessons() {
      //let today = moment(new Date())
      let lessons = _.filter(this.studentData.lessons, function(lesson) {
        return moment(lesson.endDateTime).isAfter(moment()) //lesson.localStartDateTime > today
      })
      return lessons.sort(function(a, b) {
        return new Date(a.startDateTime) - new Date(b.startDateTime)
      })
    }
  },
  methods: {
    setStudentLessonCost() {
      if (this.$refs.form.validate()) {
        // let model = {
        //   studentId: this.studentId,
        //   hourlyRate: this.studentData.hourlyRate
        // }
        this.submitted = true
        // InstructorAPI.setStudentLessonCost(model)
        instructorService
          .setStudentLessonCost(this.studentId, this.studentData.hourlyRate)
          .then(({ data }) => {
            console.log(data)
            this.hourlyRate = this.studentData.hourlyRate
            this.lessonCostWasSet = true
            setTimeout(() => {
              this.setLessonCostDialog = false
              this.lessonCostWasSet = false
              this.submitted = false
            }, 3000)
          })
          .catch(({ response }) => {
            console.log(response)
            this.submitted = false
          })
      }
    },

    setStatus(status) {
      // let model = {
      //   studentId: this.studentId,
      //   status: status
      // }
      // console.log('status' + status)
      // InstructorAPI.setStatus(model)
      instructorService
        .setStatus(this.studentId, status)
        .then(({ data }) => {
          console.log(data)
          this.studentWasRemoved = true
          setTimeout(() => {
            this.$router.push({ name: 'Students' })
          }, 3000)
        })
        .catch(({ response }) => {
          console.log(response)
          alert('error')
        })
    },

    fetchStudent() {
      if (this.isStudent) {
        this.fetchSelfStudent()
      } else {
        this.fetchSpecificStudent()
      }
    },

    fetchSelfStudent() {
      studentService
        .getStudentDetails()
        .then(({ data }) => {
          this.isLoading = false

          this.studentData = data
          this.hourlyRate = this.studentData.hourlyRate
          this.$store.commit('global/' + Mutations.SET_OVERALL_PROGRESS, data.studentProgressOverallScore)
          this.licenceNumber = data.licenceNumber
          console.log(data)
        })
        .catch(({ response }) => {
          console.log(response)
        })
    },

    fetchSpecificStudent() {
      let studentId = this.$route.params.studentId
      this.studentId = studentId
      console.log('studentId' + studentId)
      instructorService
        .getStudent(studentId)
        .then(({ data }) => {
          this.isLoading = false

          this.studentData = data
          this.licenceNumber = data.licenceNumber

          this.$store.commit('global/' + Mutations.SET_OVERALL_PROGRESS, data.studentProgressOverallScore)
          this.hourlyRate = this.studentData.hourlyRate
          console.log(data)
        })
        .catch(({ response }) => {
          console.log(response)
        })
    },

    setLicenceNumber(number) {
      this.studentData.licenceNumber = number
    },
    dismissInstructorDialog() {
      //alert('dsfsd')
      this.myInstructorDialog = false
    }
  },

  created() {
    this.fetchStudent()
  }
}
</script>

<style lang="scss" scoped>
.address-list {
  padding: 0;
  list-style-type: none;
}

// .v-slide-group__prev.v-slide-group__prev--disabled {
//   display: none;
// }

.v-expansion-panel-content {
  .v-expansion-panel-content__wrap {
    padding: 0px !important;
  }
}

.panel-icon {
  min-width: 50px;
  width: 50px;
}
</style>
