//lesson.service.gen.js
// import lessonService from '@/services/generated/lesson.service.gen'
import axios from 'axios'

export default {
  getLesson(id) {
    return axios.get(`/api/lessons/${id}`)
  }
  /*
					lessonService.getLesson(id)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
