<template>
  <v-layout>
    <v-flex>
      <PageHeading title="Payments" />
      <!-- <pre>{{balance}}</pre> -->

      <!-- <v-btn color="primary" text @click="changeOrder('asc')">Ascending</v-btn>
      <v-btn color="primary" text @click="changeOrder('desc')">Descending</v-btn>-->
      <SubHeading v-if="isInstructorPayEnabled" :title="'Online Balance'" />

      <v-card v-if="isInstructorPayEnabled" class="pa-4 elevation-1 ">
        <!-- <SubHeading :title="'Online Balance'" /> -->
        <!-- <div class="d-flex justify-start align-center">
          <h4>Balance for payments made through InstructorPay</h4>

          <div class=" d-flex justify-start align-center ml-12">
            <v-chip label class="subtitle-2 mr-10" color="grey lighten-3"><span class="mr-4">Pending Balance:</span>{{ balance.pendingAsCurrency }}</v-chip>
            <v-chip label class="subtitle-2" color="grey lighten-3"><span class="mr-4">Available Balance:</span>{{ balance.availableAsCurrency }}</v-chip>
          </div>
        </div> -->
        <v-row>
          <v-col xs="12" lg="6"><h4>Balance for payments made through InstructorPay</h4></v-col>
          <v-col xs="12" lg="6">
            <div class=" d-flex justify-start align-center ml-12">
              <v-chip label class="subtitle-2 mr-10" color="grey lighten-3"><span class="mr-4">Pending Balance:</span>{{ balance.pendingAsCurrency }}</v-chip>
              <v-chip label class="subtitle-2" color="grey lighten-3"><span class="mr-4">Available Balance:</span>{{ balance.availableAsCurrency }}</v-chip>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <v-layout align-center justify-end>
        <span class="subtitle-1 ma-4">Sort by date:</span>

        <v-btn text icon small color="primary" @click="changeOrder('asc')">
          <v-icon>fas fa-arrow-up small-icon</v-icon>
        </v-btn>

        <v-btn text icon small color="primary" @click="changeOrder('desc')">
          <v-icon>fas fa-arrow-down small-icon</v-icon>
        </v-btn>
      </v-layout>
      <Spinner :isLoading="isLoading" />

      <div v-show="!isLoading" v-infinite-scroll="fetchPayments" infinite-scroll-disabled="busy" infinite-scroll-distance="pageSize">
        <v-expansion-panels>
          <v-expansion-panel v-for="(payment, index) in payments" :key="'e' + index">
            <v-expansion-panel-header>
              <PaymentsItem v-bind:payment="payment" v-bind:key="'item' + index" @click="alert('sss')" />
            </v-expansion-panel-header>
            <v-expansion-panel-content class="grey">
              <div class="mt-4">
                <v-layout row wrap>
                  <v-flex lg6 xs12>
                    <PaymentsItemLessons v-bind:payment="payment" v-bind:key="'lesson' + index" />
                  </v-flex>
                  <v-flex lg6 xs12 white--text body-2 ma-2>
                    <p>Reference: {{ payment.id }}</p>
                    <p>Date Paid: {{ friendlyDate(payment.localDatePaid) }}</p>
                  </v-flex>
                </v-layout>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div v-if="!hasItems">
          <h4 class="pa-12 text-lg-center">There are currently no payments</h4>
        </div>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
//import PaymentAPI from '../../../../services/payment.service'
import PaymentsItem from './AdminPaymentsItem'
//import PaymentsItem from '@/views/Dashboard/Common/Payments/PaymentsItem'

import PaymentsItemLessons from '@/views/Dashboard/Common/Payments/PaymentsItemLessons'
import moment from 'moment'
//import StripeAPI from '../../../../services/stripe.service'
import { PayoutStatus } from '@/helpers/enums'

//import StripeAPI from '@/services/stripe.service'
import stripeService from '@/services/generated/stripe.service.gen'
//import paymentInstructorService from '@/services/generated/paymentInstructor.service.gen'
import adminService from '@/services/generated/admin.service.gen'

export default {
  props: ['instructorId'],
  components: {
    PaymentsItem,
    PaymentsItemLessons
  },
  data() {
    return {
      isLoading: true,
      order: '',
      pageSize: 10,
      payments: [],
      pageMetaData: {},
      busy: false,
      headers: [
        { text: 'Student', value: 'calories' },
        { text: 'Date', value: 'fat' },
        { text: 'Amount', value: 'carbs' },
        { text: 'Transferred', value: 'protein' },
        { text: 'Iron (%)', value: 'iron' }
      ],
      panelIndex: -9,
      balance: {}
    }
  },
  // watch: {
  //   panelIndex: function() {
  //     console.log('watch panelIndex id: ' + this.panelIndex)
  //     let selectedPayment = this.payments[this.panelIndex]
  //     console.log('TCL: panelIndex', selectedPayment)

  //     StripeAPI.getPayoutDate(selectedPayment.id, selectedPayment.studentId).then(resp => {
  //       console.log('TCL: getPayoutDate -> resp', resp)
  //       console.log('resp.data', resp.data)

  //       selectedPayment.payoutDate = resp.data

  //       this.payments.splice(this.panelIndex, 1, selectedPayment)
  //     })
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    payoutStatus() {
      return this.user.profile.payoutStatus
    },
    isInstructorPayEnabled() {
      return this.payoutStatus == PayoutStatus.InstructorPayEnabled
    },
    nextPage() {
      return this.pageMetaData.nextPage ? this.pageMetaData.nextPage : 1
    },
    hasItems() {
      return this.payments && this.payments.length
    }
  },
  methods: {
    friendlyDate(dp) {
      return moment(dp).format('lll')
    },
    changeOrder(order) {
      this.order = order
      this.payments = []
      this.pageMetaData = {}
      this.fetchPayments()
    },

    fetchPayments() {
      this.busy = true
      let prevRoute = this.$route.path

      if (this.nextPage == 1 || this.pageMetaData.hasNext) {
        adminService
          .getInstructorPayments(this.instructorId, this.order, this.nextPage, this.pageSize)
          .then(({ data }) => {
            this.isLoading = false
            this.pageMetaData = data.pageMetaData
            this.payments.push(...data.payments)
            this.busy = false
            let currentRoute = this.$route.path
            if (currentRoute === prevRoute) {
              // this.$router.push({
              //   name: 'Payments',
              //   query: {
              //     ...(this.order ? { order: this.order } : {})
              //   }
              // })
            }
          })
          .catch(({ response }) => {
            console.log(response)
          })
      }
    },

    loadAccountBalance() {
      stripeService
        .getAccountBalance()
        .then(({ data }) => {
          this.balance = data
        })
        .catch(() => {
          //console.log(response)
        })
        .finally(() => {
          // this.isLoading = false
        })

      // StripeAPI.getStripeAccount()
      // .then(({ data }) => {
      //   this.account = data
      // })
      // .catch(({ response }) => {
      //   console.log(response)
      // })
      // .finally(() => {
      //   // this.isLoading = false
      // })
    }
  },

  created() {
    this.order = this.$route.query.order ? this.$route.query.order : ''
    //this.loadAccountBalance()
  }
}
</script>

<style scoped></style>
