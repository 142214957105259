<template>
  <v-container class="home-hero" container--fluid :class="home1" id="home">
    <v-layout wrap>
      <v-flex xs12>
        <Header />
      </v-flex>

      <v-flex xs12 justify-center>
        <v-layout row wrap class="ip-fill" :align-center="!$vuetify.breakpoint.xs">
          <v-flex xs12 sm6 md6 order-xs2 order-lg1 pa-4>
            <v-layout class="ip-text" column fill-height justify-center :align-end="!$vuetify.breakpoint.xs" :align-center="$vuetify.breakpoint.xs">
              <!-- <h1 class="display-4 white--text font-weight-bold hidden-md-and-down mb-6">InstructorPay</h1> -->
              <img src="@/assets/images/Logo.png" class="logo mb-10" />
              <span class="white--text title text-center  ">
                333 Manage driving lessons for instructors and learner drivers
              </span>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm6 md6 order-xs1 order-lg2 pa-4>
            <v-layout align-center justify-center fill-height>
              <img src="@/assets/images/undraw_online_friends_x73e.svg" class="bounce animated svg" />
            </v-layout>

            <!-- <v-icon class="animated bounce text--white">fas fa-car</v-icon> -->
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Header from '../../components/Home/Header'

export default {
  components: {
    Header
  },
  data: () => ({}),
  computed: {
    home1() {
      return {
        'home1-image-xs': this.$vuetify.breakpoint.smAndDown,
        'home1-image': this.$vuetify.breakpoint.mdAndUp
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//  .logo {
//     width: auto;
//     height: auto;
//   }

.home1-image-xs {
  .logo {
    width: 90%;
    height: auto;
  }
  text-align: center;

  img.svg {
    width: 100%;
    height: auto;
  }
  .ip-text {
    text-align: left;

    h2 {
      font-size: 2rem;
    }

    h4 {
      text-align: center;
      font-size: 1.4rem;
    }
  }
}
.home1-image {
  text-align: right;
  img.svg {
    width: 80%;
    height: auto;
  }
  .ip-text {
    h2 {
      font-size: 3.75rem;
    }

    h4 {
      font-size: 2.1rem;
      margin-right: 20px;
    }
  }
}

.ip-fill {
  min-height: 90vh;
}

.home-hero {
  .animated {
    -webkit-animation-duration: 8s;
    animation-duration: 8s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  }
  @-webkit-keyframes bounce {
    0%,
    100% {
      -webkit-transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(-40px);
    }
  }
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-40px);
    }
  }
  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
  }
  .fas {
    font-size: 30vw;
  }

  // @media screen and (max-width: 600px) {
  //   .fas {
  //     font-size: 30px;
  //   }
  // }

  min-height: 100vh;
  width: 100vw;
  // background-image: linear-gradient(150deg, #2a2a72 15%, 70%, #ff6663 94%);
  // background-size: 400% 400%;
  // -webkit-animation: Gradient-data-v-cd17e608 8s ease-out infinite alternate, AlterGradient 10s ease-out infinite alternate;
  // animation: Gradient-data-v-cd17e608 8s ease-out infinite alternate, AlterGradient 10s ease-out infinite alternate;

  //background: linear-gradient(138deg, #111, #070e74);
  //background: linear-gradient(138deg, #070e74  15%,70%, #ff6633 94%);
  background: linear-gradient(138deg, #000051 15%, 70%, #ff6633 94%);

  background-size: 400% 400%;
  //background-size: 100% 100%;
  //Gradient-data-v-cd17e608 8s ease-out infinite alternate,AlterGradient 10s ease-out infinite alternate

  //background-image: linear-gradient(150deg,#2a2a72 15%,70%,#ff6663 94%);
  // -webkit-animation: Gradient-data-v-cd17e608 8s ease-out infinite alternate,AlterGradient 10s ease-out infinite alternate;
  // animation: Gradient-data-v-cd17e608 8s ease-out infinite alternate,AlterGradient 10s ease-out infinite alternate;

  -webkit-animation: AnimationName 7s ease infinite;
  -moz-animation: AnimationName 7s ease infinite;
  animation: AnimationName 7s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 51% 100%;
    }
    100% {
      background-position: 50% 0%;
    }
  }
  @-moz-keyframes AnimationName {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 51% 100%;
    }
    100% {
      background-position: 50% 0%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 51% 100%;
    }
    100% {
      background-position: 50% 0%;
    }
  }
}
</style>
