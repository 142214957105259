<template>
  <v-layout row wrap>
    <v-flex>
      <AdminHeader />

      <PageHeading title="Users" />
      <Spinner :isLoading="isLoading" />
      <div v-show="!isLoading">
        <div v-infinite-scroll="fetchUsers" infinite-scroll-disabled="busy" infinite-scroll-distance="pageSize">
          <div v-if="hasItems">
            <template v-for="(user, index) in users">
              <UsersItem v-bind:user="user" v-bind:key="index" />
              <v-divider :key="'d' + index" class="dark my-2 hidden-md-and-up"></v-divider>
            </template>
            <v-divider light class="hidden-sm-and-down" style="margin:50px 0px"></v-divider>
          </div>
        </div>
        <div v-if="!hasItems">
          <h4 class="ma-12 text-lg-center">There are currently no {{ currentType }} users</h4>
        </div>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
//import adminAPI from '@/services/admin.service'
import adminService from '@/services/generated/admin.service.gen'
import UsersItem from './UsersItem'

import _ from 'lodash'
import AdminHeader from './AdminHeader.vue'

export default {
  components: {
    AdminHeader,
    UsersItem
  },
  data() {
    return {
      type: '',
      pageSize: 20,
      users: [],
      pageMetaData: {},
      busy: false,
      isLoading: true
    }
  },
  computed: {
    userIds() {
      return _.map(this.users, 'id')
    },

    nextPage() {
      return this.pageMetaData.nextPage ? this.pageMetaData.nextPage : 1
    },

    hasItems() {
      return this.users && this.users.length
    },
    currentType() {
      return this.type === '' ? 'instructor' : this.type
    }
  },
  methods: {
    changeType(type) {
      this.type = type
      this.users = []
      this.pageMetaData = {}
      this.isLoading = true
      this.fetchUsers()
    },

    fetchUsers() {
      this.busy = true
      let prevRoute = this.$route.path

      if (this.nextPage == 1 || this.pageMetaData.hasNext) {
        adminService
          .getUsers(this.type, this.nextPage, this.pageSize)
          .then(({ data }) => {
            this.pageMetaData = data.pageMetaData

            this.users.push(...data.users)
            this.busy = false
            this.isLoading = false
            let currentRoute = this.$route.path
            if (currentRoute === prevRoute) {
              this.$router.push({
                name: 'AdminUsers',
                query: {
                  ...(this.type ? { type: this.type } : {})
                  //...(this.pageNumber ? { pageNumber: this.pageNumber } : {})
                }
              })
            }
          })
          .catch(() => {
            //this.busy = false
            this.isLoading = false
          })
      }
    }
  },

  created() {
    this.type = this.$route.query.type ? this.$route.query.type : ''
  }
}
</script>

<style scoped>
.activeFilter {
  font-weight: bold;
  text-decoration: underline;
}
.plus-icon {
  font-size: 12px;
}

.date-users:nth-child(odd) {
  background-color: #efefef;
}

.date-users:nth-child(even) {
  background-color: #fff;
}
</style>
