<template>
  <div class="grey lighten-5 pa-2">
    <h4 class="underline mb-2 font-weight-black">{{ hours }} lesson</h4>
    <!-- <p>
      {{ hours }} lesson at <strong>£{{ amount }}</strong>
    </p> -->
    <p>
      Student paid <strong>£{{ amount }}</strong>
    </p>
    <p>
      Fee: (£{{ amount }} X {{ this.sumPC }}%) + {{ this.sumFixed }}p = <strong>£{{ this.appFee(amount) }}</strong>
    </p>
    <p>
      You recieve: <strong>£{{ this.insctructoRecieve(amount) }}</strong>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    amount: {
      type: Number,
      default: 25
    },
    hours: {
      type: String,
      default: '1 hour'
    },
    egNumber: {
      default: ''
    },
    sumFixed: {
      type: Number,
      default: 25
    },
    sumPC: {
      type: Number,
      default: 25
    }
  },
  data() {
    return {}
  },
  computed: {},

  methods: {
    insctructoRecieve(lessonCost) {
      var val = lessonCost - this.appFee(lessonCost)
      var pounds = val

      return pounds.toFixed(2)
    },
    appFee(lessonCost) {
      var fee = Number(lessonCost * 100 * (this.sumPC / 100)) + Number(this.sumFixed)
      let feePounds = fee / 100
      return Math.round(feePounds * 100) / 100 // Math.round(fee / 100)
    }
  }
}
</script>

<style scoped>
.underline {
  text-decoration: underline;
}
</style>
