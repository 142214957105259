<template>
  <v-container class="" grid-list-md v-if="instructor">
    <v-row>
      <v-col>
        <v-toolbar color="grey lighten-2" dense flat dark>
          <v-spacer></v-spacer>

          <v-menu bottom float-left v-if="hasInstructor && !hasPassed">
            <template v-slot:activator="{ on }">
              <v-btn light icon v-on="on">
                <v-icon dark>fas fa-ellipsis-v small-icon</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="unpairDialog = true">
                <v-icon class="pr-4 small-icon">fas fa-times</v-icon>
                <v-list-item-title v-text="'Leave Instructor'"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <div class="grey lighten-3 pa-2">
          <div class="d-flex flex-column justify-start align-center">
            <!-- <img :src="studentData.profilePicture | asProfilePicture" alt="Person" /> -->
            <v-avatar :size="128" color="grey lighten-4 elevation-3">
              <img :src="instructor.profilePicture | asProfilePicture" class="circle" />
            </v-avatar>
            <h2 class="my-4">{{ instructor.fullName }}</h2>
          </div>

          <v-sheet class="pa-2 my-4">
            <v-layout row wrap>
              <v-flex lg4 xs12 text-lg-right>
                <h4>Telephone:</h4>
              </v-flex>
              <v-flex lg8 xs12 text-lg-left>{{ instructor.telephone }}</v-flex>
            </v-layout>
            <v-layout row wrap v-if="instructor.website">
              <v-flex lg4 xs12 text-lg-right>
                <h4>Website:</h4>
              </v-flex>
              <v-flex lg8 xs12 text-lg-left>
                <a class="word-wrap dont-break-out" :href="instructor.website">{{ instructor.website }}</a>
              </v-flex>
            </v-layout>

            <v-layout row wrap v-if="instructor.drivingSchoolName">
              <v-flex lg4 xs12 text-lg-right>
                <h4>Driving School:</h4>
              </v-flex>
              <v-flex lg8 xs12 text-lg-left>{{ instructor.drivingSchoolName }}</v-flex>
            </v-layout>
          </v-sheet>
          <div class="d-flex justify-end align-start pa-3 grey lighten-2" v-if="hasInstructor && !hasPassed">
            <v-btn text block outlined color="primary" style="background:#fff" @click="passedDialog = true"> <v-icon>fas fa-award mr-4</v-icon>I have Passed </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- <pre>{{instructor}}</pre> -->

    <!-- <h1 v-if="hasPassed">Has Passed</h1> -->
    <!-- <InstructorProfileCalendar v-if="!hasPassed" /> -->

    <Modal v-model="passedDialog">
      <template #modalTitle>
        <span>Passed Driving Test</span>
      </template>
      <template #modalBody>
        <span>Are you sure you want mark yourself as passed with {{ instructor.fullName }}?</span>
      </template>
      <template #okButton>
        <v-btn :disabled="submitted" :class="{ 'primary white--text': !submitted, disabled: submitted }" text @click="setStatus(2)">Yes</v-btn>
      </template>
    </Modal>

    <Modal v-model="unpairDialog">
      <template #modalTitle>
        <span>Unpair with {{ instructor.fullName }}</span>
      </template>
      <template #modalBody>
        <p>Are you sure you want to Unpair With this instructor?</p>
      </template>
      <template #okButton>
        <v-btn :disabled="submitted" :class="{ 'primary white--text': !submitted, disabled: submitted }" text @click="setStatus(4)">Ok</v-btn>
      </template>
    </Modal>

    <!-- <v-snackbar :multi-line="true" :right="true" :timeout="3000" :top="true" :vertical="true">
      Congratulations on passing your driving test!
      <v-btn color="pink" text @click="snackbar = false">Close</v-btn>
    </v-snackbar> -->
  </v-container>
</template>

<script>
//         Pending 0,
//         Active 1,
//         Passed 2,
//         Removed 3,
//         StudentDeleted 4
//import StudentAPI from '@/services/student.service'
import studentService from '@/services/generated/student.service.gen'
import { Mutations } from '../../../store/mutations.type'
//import { Actions } from '../../../store/actions.type'
import { mapGetters } from 'vuex'

// import InstructorProfileCalendar from './InstructorProfileCalendar'

export default {
  components: {
    //InstructorProfileCalendar
  },
  data() {
    return {
      instructor: null,
      passedDialog: false,
      unpairDialog: false,
      submitted: false,
      submittedPassed: false

      //snackbar: true
    }
  },
  computed: {
    ...mapGetters(['profile', 'hasInstructor']),
    hasPassed() {
      return this.profile.hasPassed
    }
  },
  methods: {
    user() {
      return this.$store.state.auth.user
    },
    isInstructor() {
      return this.user.role == 'Instructor'
    },
    isStudent() {
      return this.user.role == 'Student'
    },
    setStatus(status) {
      this.submitted = true
      this.submittedPassed = true
      studentService
        .setStatusByStudent(this.instructor.instructorId, status)
        .then(({ data }) => {
          if (status == 2) {
            //passed
            console.log('passed')
            this.$store.commit(Mutations.SET_PROFILE, data)

            //this.snackbar = true

            //this.hasPassed = true

            setTimeout(() => {
              this.passedDialog = false
              this.$emit('dismiss')

              //this.$router.push({ name: 'root' })
            }, 1000)
          } else if (status == 4) {
            console.log('unpaired')
            this.$store.commit(Mutations.SET_PROFILE, data)
            setTimeout(() => {
              this.unpairDialog = false
              this.$emit('dismiss')

              //this.$router.push({ name: 'root' })
            }, 1000)
          }
        })
        .catch(({ response }) => {
          console.log(response)
          alert('error')
        })
    }
  },
  created() {
    studentService
      .getInstructor()
      .then(({ data }) => {
        console.log(data)

        this.instructor = data
      })
      .catch(({ response }) => {
        console.log(response)
        alert('error')
      })
  }
}
</script>

<style lang="scss" scoped>
.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
</style>
