//chat.service.gen.js
// import chatService from '@/services/generated/chat.service.gen'
import axios from 'axios'

export default {
  getContacts() {
    return axios.get('/api/chat/contacts')
  },

  /*
					chatService.getContacts()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getConversationWithUser(recipientId, pageNumber, pageSize) {
    return axios.get('/api/chat/conversation', {
      params: {
        recipientId: recipientId,
        ...(pageNumber ? { pageNumber: pageNumber } : {}),
        ...(pageSize ? { pageSize: pageSize } : {})
      }
    })
  }
  /*
					chatService.getConversationWithUser(recipientId, pageNumber, pageSize)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
