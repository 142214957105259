<template>
  <div @scroll="scrolledToTop" id="conversation" class="middle flex-grow-1 min-100 max-parent scrollable " :style="cssProps">
    <ul>
      <ChatConversationItem :message="message" v-for="(message, index) in messages" :key="index + 'msg'" />
    </ul>
  </div>
</template>

<script>
//import StudentsItem from './StudentsItem'
//import StudentsPaging from "./StudentsPaging";
// import { Actions } from "@/store/actions.type";
// import { mapState } from "vuex";
// import { mapMutations } from "vuex";
import ChatConversationItem from './ChatConversationItem'

export default {
  components: {
    ChatConversationItem
    //  StudentsPaging
  },
  data() {
    return {
      image: '../assets/images/email-pattern.png'
    }
  },
  computed: {
    messages() {
      return this.$store.getters['chat/messages']
    },
    selectedContact() {
      return this.$store.getters['chat/selectedContact']
    },
    cssProps() {
      return {
        'background-image': 'url(' + require('@/assets/images/email-pattern.png') + ')',
        'background-repeat': 'repeat',
        opacity: 0.8
      }
    },
    isNew() {
      return this.$store.getters['chat/isNew']
    }
  },
  methods: {
    // fetchConversation() {
    //   this.$store
    //     .dispatch('chat/fetchConversation')
    //     .then(response => {
    //       //this.$store.commit("global/setLoggedIn", true);
    //       //this.$router.push({ name: "root" });
    //       console.log(response)
    //     })
    //     .catch(error => {
    //       console.log('TCL: fetchContacts -> error', error)
    //     })
    // },

    scrollDownABit() {
      setTimeout(function() {
        var objDiv = document.getElementById('conversation')
        objDiv.scrollTop = 50
      }, 30)
    },

    scrolledToTop(event) {
      var el = event.srcElement
      console.log(el.scrollTop + ' ' + el.scrollHeight + ' ' + el.clientHeight)
      //if(!this.reachedBottom){
      if (el.scrollTop >= el.scrollHeight - el.clientHeight - 100) {
        //this.reachedBottom = true
      }

      if (el.scrollTop == 0) {
        //alert("top")
        if (!this.isNew) {
          this.$store.dispatch('chat/fetchConversation').then(() => {
            this.scrollDownABit()
          })
        }
      }
      // }
    }
  },

  created() {
    // this.fetchConversation()
  }
}
</script>

<style lang="scss" scoped>
.middle {
  padding: 10px 3vw;
  ul {
    padding-left: 0px;
  }
}
</style>
