<template>
  <v-layout column :justify-space-between="!$vuetify.breakpoint.smAndDown" fill-height>
    <div v-if="isProfileComplete">
      <v-list>
        <v-list-item @click="goToProfile">
          <v-list-item-avatar>
            <img :src="this.user.profile.profilePicture | asProfilePicture" />
          </v-list-item-avatar>

          <v-list-item-title>{{ fullName }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <StudentSideBar v-if="IsStudent" />
      <InstructorSideBar v-if="IsInstructor" />
    </div>

    <v-btn class="ma-4 accent lighten-2" @click="logout">Sign Out</v-btn>
  </v-layout>
</template>

<script>
import StudentSideBar from '@/components/Dashboard/StudentSideBar.vue'
import InstructorSideBar from '@/components/Dashboard/InstructorSideBar.vue'

import { Actions } from '../../store/actions.type'

export default {
  data() {
    return {
      //drawer: null,
      hideOverlay: !true
    }
  },
  components: {
    StudentSideBar,
    InstructorSideBar
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },

    fullName() {
      return this.user.profile.fullName
    },
    IsInstructor() {
      return this.user.role == 'Instructor'
    },
    IsStudent() {
      return this.user.role == 'Student'
    },
    isProfileComplete() {
      console.log('profile: ')
      console.log(this.user)
      console.log(this.user.profile)
      return this.user.profile.isProfileComplete
    }
  },
  methods: {
    logout() {
      this.$store.dispatch(Actions.LOGOUT).then(() => {
        this.$router.push({ name: 'root' })
      })
    },
    goToProfile() {
      this.$router.push({ path: '/settings' })
    }
  }
}
</script>

<style scoped></style>
