<template>
  <v-alert :value="hasErrors" type="error" class="ma-2">
    <ul>
      <li v-for="item in errors" :key="item">{{ item }}</li>
    </ul>
  </v-alert>
</template>

<script>
export default {
  props: ['errors'],
  computed: {
    hasErrors() {
      return this.errors && this.errors.length > 0
    }
  }
}
</script>

<style scoped></style>
