<template>
  <AuthWrapper>
    <template #form>
      <v-flex xs12 elevation-6>
        <v-card>
          <FormTitle>Login</FormTitle>

          <v-card-text class="pt-6">
            <FormErrors :errors="errors" />

            <v-form v-model="valid" ref="form">
              <v-text-field id="emailTxt" label="Email Address" v-model="form.email" :rules="emailRules" required :disabled="submitted"></v-text-field>
              <v-text-field
                id="passwordTxt"
                label="Password"
                v-model="form.password"
                min="8"
                :append-icon="e1 ? 'fas fa-eye' : 'fas fa-eye-slash'"
                @click:append="() => (e1 = !e1)"
                :type="e1 ? 'text' : 'password'"
                :rules="passwordRules"
                counter
                required
                :disabled="submitted"
              ></v-text-field>
              <v-layout justify-space-between>
                <v-btn id="loginButton" @click="submit" :disabled="submitted" :class="{ 'primary white--text': valid, disabled: !valid }"
                  >Login
                  <v-progress-circular size="18" width="2" v-if="submitted" indeterminate color="primary" class="ml-2"></v-progress-circular>
                </v-btn>
                <router-link to="/forgot-password">Forgot Password</router-link>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 pa-4>
        <p class="text-center white--text title">
          Need to create an account?
          <router-link class="white--text " to="/sign-up">Sign Up</router-link>
        </p>
      </v-flex>
    </template>

    <template #rightside>
      <img src="@/assets/images/undraw_mobile_login_ikmv.svg" class="animated flipInY" style="width: 50%; height: auto;" />
    </template>
  </AuthWrapper>
</template>

<script>
//import AuthService from "../../services/auth";
//import { LOGIN } from "@/store/actions.type";
//import { Types } from "../../store/actions.type";
import { Actions } from '../../store/actions.type'
import AuthWrapper from './AuthWrapper'

export default {
  components: {
    AuthWrapper
  },
  data() {
    return {
      valid: false,
      e1: false,
      form: {
        email: 'jeffery.banks@example.com',
        password: 'Password123',
        client: 'Web'
      },
      passwordRules: [v => !!v || 'Password is required'],
      // eslint-disable-next-line no-useless-escape
      //emailRules: [v => !!v || 'E-mail is required', v => /^\w+([\.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'],
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid'],
      errors: [],
      submitted: false
    }
  },
  computed: {
    HasErrors() {
      return this.errors.length > 0
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.submitted = true
        this.errors = []
        this.$store
          .dispatch(Actions.LOGIN, this.form)
          .then(response => {
            //this.$store.commit("global/setLoggedIn", true);
            if (this.$route.query.from) {
              this.$router.replace(this.$route.query.from)
            } else {
              this.$router.push({ name: 'root' })
            }
            console.log(response)
          })
          .catch(error => {
            this.submitted = false
            this.errors = error.data.errors
            console.log(error.data.errors)
          })
      }
    },
    clear() {
      this.$refs.form.reset()
    }
  }
}
</script>

<style lang="scss" scoped></style>
