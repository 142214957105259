<template>
  <AuthWrapper>
    <template #form>
      <v-flex xs12 sm9 elevation-6>
        <v-card v-if="!sent">
          <FormTitle>Forgot Password</FormTitle>

          <v-card-text class="pt-6">
            <FormErrors :errors="errors" />
            <div>
              <v-form v-model="valid" ref="form">
                <v-text-field label="Enter your e-mail address" v-model="form.email" :rules="emailRules" required :disabled="submitted"></v-text-field>

                <v-layout align-center>
                  <v-btn @click="submit" :disabled="submitted" :class="{ 'primary white--text': valid, disabled: !valid }">Submit</v-btn>
                  <v-progress-circular size="18" width="2" v-if="submitted" indeterminate color="primary"></v-progress-circular>
                  <v-spacer></v-spacer>
                  <router-link to="/login">Remembered Password?</router-link>
                </v-layout>
              </v-form>
            </div>
          </v-card-text>
        </v-card>

        <v-card v-if="sent">
          <v-card-text class="pt-6 text-md-center">
            <span class="title font-weight-light py-6">Please check your email and click on the link to reset your password</span>
          </v-card-text>
        </v-card>
      </v-flex>
    </template>
    <template #rightside>
      <img src="@/assets/images/undraw_forgot_password_gi2d.svg" class="animated flipInX" style="width: 50%; height: auto;" />
    </template>
  </AuthWrapper>
</template>

<script>
//import { FORGOT_PASSWORD } from "@/store/actions.type";
import { Actions } from '../../store/actions.type'
import AuthWrapper from './AuthWrapper'

export default {
  components: {
    AuthWrapper
  },
  data() {
    return {
      sent: false,
      valid: false,
      e1: false,
      form: { email: '' },
      errors: [],
      // eslint-disable-next-line no-useless-escape
      //emailRules: [v => !!v || 'E-mail is required', v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'],
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid'],
      submitted: false
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.submitted = true
        this.$store
          .dispatch(Actions.FORGOT_PASSWORD, this.form)
          .then(() => {
            this.sent = true
          })
          .catch(error => {
            this.submitted = false

            this.errors = error.data.errors
            console.log(error.data.errors)
          })
      }
    }
  }
}
</script>
