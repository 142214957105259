<template>
  <v-layout :key="componentKey">
    <v-flex>
      <PageHeading title="Set up Payments"> </PageHeading>

      <Spinner :isLoading="isLoading" />

      <v-row v-if="!isLoading">
        <v-col offset-lg="" sm="12" xs="12" md="12" lg="8">
          <!-- <pre>{{ this.payoutStatus }}</pre> -->
          <!-- <pre>{{ this.account }}</pre> -->

          <!-- <v-btn text color="primary" @click="stepOverride--">-</v-btn>{{ stepOverride }} <v-btn color="primary" text @click="stepOverride++">+</v-btn> -->
          <SubHeading :title="'Getting Paid '" />

          <div class="grey lighten-3 pa-4 mb-6">
            <p v-if="!this.isPayoutEnabled && !this.isBankCreated">
              Provide your bank details in the form below to enable your students to pay for lessons through this platform.
            </p>

            <p>
              Any lessons that have been paid through InstructorPay with a credit or debit card will incur a small fee detailed in the "Application Fees" panel
            </p>

            <h4 class="sub-title underline">Payouts to your bank account</h4>
            <p>
              Any payments made to you will be added to your balance with fees deducted and be transferred to your bank within 24 hours.
            </p>
          </div>
          <SubHeading :title="'Payment Setup Steps'" />

          <div class="grey lighten-3 pa-4">
            <v-alert type="error" v-if="isBanned">
              Your account is currently suspended.
            </v-alert>
            <v-alert type="warning" v-if="isDisabledByIntructor">
              You have disabled payments. To re-enable please get in touch.
            </v-alert>

            <div v-if="!isDisabledByIntructor && !isBanned">
              <!-- ######################################## isBankCreated ###############################################-->

              <v-card color="grey lighten-4" class=" pa-5" v-if="isBankCreated">
                <div class="pa-8 grey lighten-5 my-2">
                  <div class="d-flex justify-start align-start">
                    <v-icon>fas fa-user-clock mr-4 warning--text </v-icon>
                    <div>
                      <h4>Your account is pending approval</h4>
                      <p class="mt-4">
                        You will be notified here once your account has been approved to recieve payments
                      </p>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-end align-end">
                  <v-btn small text v-if="isPayoutEnabled" @click="disablePaymentsDialog = true" color="primary">Disable Payments</v-btn>
                </div>
              </v-card>
              <!-- ######################################## isPayoutEnabled ###############################################-->

              <v-card color="grey lighten-4" class="pa-5" v-if="isPayoutEnabled">
                <div class="pa-8 grey lighten-5 my-2">
                  <div class="d-flex justify-start align-start">
                    <v-icon>fas fa-check-circle mr-4 success--text </v-icon>
                    <div>
                      <h4>Your account has been approved to recieve payments</h4>
                      <p class="mt-4">
                        Your learner drivers can pay for lessons using their credit or debit cards on InstructorPay
                      </p>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-end align-end">
                  <v-btn small text v-if="isPayoutEnabled" @click="disablePaymentsDialog = true" color="primary">Disable Payments</v-btn>
                </div>
              </v-card>

              <!-- ######################################## STEP 2 ###############################################-->

              <v-card color="grey lighten-4" class="mt-4 pa-6">
                <SetupPaymentsOnboardingBankSimple :bank="bankSimple" @reloadData="reloadData" class="mt-4" />
              </v-card>
              <!-- ######################################## END ###############################################-->
            </div>
          </div>
        </v-col>
        <SetupPaymentsOnboardingFees />
      </v-row>
    </v-flex>

    <Modal v-model="disablePaymentsDialog">
      <template #modalTitle>
        <span>Disable payments?</span>
      </template>
      <template #modalBody>
        <v-alert :value="isDisabledPayments" type="success">Payments through InstructorPay has been disabled.</v-alert>
        <v-alert :value="isErrorSettingPaymentStatus" type="error">There was a problem setting your payment status.</v-alert>

        <p v-if="!isDisabledPayments">Are you sure you want to disable payments?</p>
        <p>By disabling payments, students will no longer be able to pay for lessons through InstructorPay</p>
      </template>
      <template #okButton>
        <v-btn color="primary" text @click="setPaymentStatus(false)">Disable Payments</v-btn>
      </template>
    </Modal>
  </v-layout>
</template>

<script>
import instructorService from '@/services/generated/instructor.service.gen'
import bankAccountService from '@/services/generated/bankAccount.service.gen'

import SetupPaymentsOnboardingBankSimple from './SetupPaymentsOnboardingBankSimple'
import SetupPaymentsOnboardingFees from './SetupPaymentsOnboardingFees'

import { Mutations } from '@/store/mutations.type'
import { BankPayoutStatus } from '../../../../helpers/enums'

export default {
  components: {
    SetupPaymentsOnboardingBankSimple,
    SetupPaymentsOnboardingFees
  },
  data() {
    return {
      componentKey: 0,
      stepOverride: -1,
      showOverlay: false,
      isLoading: true,
      clickedUpdate: false,
      // account: {},
      // bank: {
      //   bank_account: {
      //     country: 'GB',
      //     currency: 'gbp',
      //     // account_holder_name: '',
      //     account_holder_type: 'individual',
      //     routing_number: '',
      //     account_number: ''
      //   }
      // },

      disablePaymentsDialog: false,
      isDisabledPayments: false,
      isErrorSettingPaymentStatus: false,
      bankSimple: {
        sortCode: '',
        accountNumber: ''
      }
    }
  },
  computed: {
    // canEditBank() {
    //   return this.account.isPayoutsEnabled
    // },
    user() {
      return this.$store.state.auth.user
    },
    payoutStatus() {
      return this.user.profile.payoutStatus
    },
    isUnsubmitted() {
      return this.payoutStatus == BankPayoutStatus.Unsubmitted
    },

    isBankCreated() {
      return this.payoutStatus == BankPayoutStatus.BankCreated
    },

    isPayoutEnabled() {
      return this.payoutStatus == BankPayoutStatus.PayoutEnabled
    },

    isBanned() {
      return this.payoutStatus == BankPayoutStatus.Banned
    },

    isDisabledByIntructor() {
      return this.payoutStatus == BankPayoutStatus.DisabledByIntructor
    },

    showAddBankForm() {
      if (this.isDisabledByIntructor || this.isBanned || this.isBankCreated) {
        return false
      }

      if (this.isUnsubmitted) {
        return true
      }

      if (this.isPayoutEnabled && this.clickedUpdate) {
        return true
      }
      return false
    },

    approvalText() {
      if (this.isPayoutEnabled) {
        return 'Approved'
      }
      return 'Approval'
    }
  },

  methods: {
    reloadData: function() {
      this.componentKey += 1
      this.loadAccount()
      //this.stepOverride = 5
    },

    startUpdatingBank() {
      this.clickedUpdate = true
    },
    cancelUpdatingBank() {
      this.clickedUpdate = false
    },

    setPaymentStatus(status) {
      // var model = { isEnabled: status }
      instructorService
        .setPaymentStatus(status)
        .then(() => {
          this.disablePaymentsDialog = false
          this.loadAccount()

          // this.isDisabledPayments = true
        })
        .catch(() => {
          this.isErrorSettingPaymentStatus = true
        })
    },

    loadAccount() {
      instructorService
        .getPayoutStatus()
        .then(result => {
          this.isLoading = false
          this.$store.commit(Mutations.SET_PAYOUT_STATUS, result.data.payoutStatus)
          if (!this.isUnsubmitted) {
            bankAccountService
              .getBankAccount()
              .then(({ data }) => {
                this.bankSimple = data
                console.log('daaata:', data)
              })
              .catch(error => {
                console.log(error)
              })
          }
        })
        .catch(() => {
          ////this.$store.commit(Mutations.SET_PAYOUT_STATUS, PayoutStatus.RegisteredInterest);
        })
    }
  },
  created() {
    this.loadAccount()
  }
}
</script>

<style lang="scss" scoped></style>
