<template>
  <v-container class="home-hero d-flex align-start" container--fluid :class="home2" fill-heightX>
    <div class="wrapper text-center">
      <h2 class="display-2">On Your Mobile</h2>
      <div class="d-flex justify-space-around phone-pic align-center ">
        <img data-aos="flip-right" class="hidden-sm-and-down" src="@/assets/images/screens/mobile/lessons.png" />
        <img data-aos="flip-right" src="@/assets/images/screens/mobile/calendar.png" />
        <img data-aos="flip-right" class="hidden-sm-and-down last-img" src="@/assets/images/screens/mobile/messages.png" />
        <!-- <img src="@/assets/images/screens/mobile/progress.png" /> -->
      </div>
    </div>

    <!-- <v-row align="center" justify-center justify-space-between>
      <v-col><img src="@/assets/images/screens/mobile/lessons.png"/></v-col>
      <v-col><img src="@/assets/images/screens/mobile/calendar.png"/></v-col>
      <v-col><img src="@/assets/images/screens/mobile/messages.png"/></v-col>
    </v-row> -->
    <!-- <v-layout text-center wrap>



      <v-flex xs12 sm6 md6 pa-12>
        <img src="@/assets/images/undraw_by_my_car_ttge.svg" />
      </v-flex>
      <v-flex xs12 sm6 md6>
        <v-layout class="ip-text" column fill-height justify-center>
          <h2 class="font-weight-bold mb-2">Driven to Succeed</h2>
          <h4>Students can monitor their own progress, view their payments and keep on top of lessons</h4>
        </v-layout>
      </v-flex>
    </v-layout> -->
  </v-container>
</template>
<script>
//import SVGImage from './SVG/Home1'
//import HomeRight from './HomeRight'

export default {
  data: () => ({}),
  computed: {
    home2() {
      return {
        'home2-image-xs': this.$vuetify.breakpoint.smAndDown,
        'home2-image': this.$vuetify.breakpoint.mdAndUp
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  h2 {
    margin: 50px;
  }
}
.phone-pic {
  width: 100%;
  img {
    width: 350px;
    height: auto;
    // z-index: 0;
  }
}

.home-hero {
  min-height: 100vh;
  width: 100vw;
  background: #fff;
}

.fas {
  font-size: 30vw;
}

.last-img{
  z-index: 3;
}
</style>
