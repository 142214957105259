<template>
  <div>
    <Spinner :isLoading="isLoading" />

    <v-card v-show="!isLoading">
      <FormTitle class="hidden-sm-and-down" v-show="!isLoading">Profile Details</FormTitle>

      <SuccessTick v-if="profileUpdated">Profile updated</SuccessTick>

      <v-card-text v-if="!profileUpdated" class="pt-6">
        <v-layout row wrap>
          <v-flex sm3 xs12>
            <v-hover>
              <v-layout align-center justify-center column slot-scope="{ hover }">
                <v-layout row>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-avatar v-on="on" color="grey lighten-4" size="100" @click="callPickFile" class="avatar-image">
                        <img :src="profilePicture" alt="avatar" class="circle elevation-2" />
                      </v-avatar>
                      <!-- <div class="company-header-avatar elevation-5" :style="styleObject"></div> -->

                      <span v-show="hover && !showDefault">
                        <v-badge overlap color="red" right>
                          <template v-slot:badge>
                            <v-icon dark @click="deleteAvatar">fas fa-times pointer</v-icon>
                          </template>
                        </v-badge>
                      </span>
                    </template>
                    <span>Upload Image</span>
                  </v-tooltip>
                </v-layout>
                <!-- <v-layout row justify-end class="delete-chip">
                  <v-chip v-show="hover" close>Delete Pictures</v-chip>
                </v-layout>-->
              </v-layout>
            </v-hover>

            <UploadImage class="d-none" @avatarSaved="onFileChosen" ref="uploader" />
          </v-flex>
          <v-flex sm9 xs12>
            <div>
              <v-form v-model="valid" ref="form">
                <v-subheader>Contact Details</v-subheader>
                <div class="mx-12">
                  <v-text-field
                    :disabled="submitted"
                    label="First Name"
                    v-model="form.firstName"
                    prepend-icon="fas fa-address-card small-icon"
                    :rules="firstNameRules"
                    required
                  ></v-text-field>

                  <v-text-field
                    :disabled="submitted"
                    label="Last Name"
                    v-model="form.lastName"
                    prepend-icon="fas fa-address-card small-icon"
                    :rules="lastNameRules"
                    required
                  ></v-text-field>
                  <v-text-field :disabled="true" label="Email" v-model="form.email" prepend-icon="fas fa-envelope small-icon"></v-text-field>
                  <v-text-field
                    :disabled="submitted"
                    label="Telephone Number"
                    v-model="form.telephoneNumber"
                    :rules="telephoneRules"
                    prepend-icon="fas fa-phone-square  small-icon"
                    required
                  ></v-text-field>
                </div>
                <v-subheader>Address</v-subheader>

                <!-- ADDRESS -->
                <div class="mx-12">
                  <v-text-field :disabled="submitted" label="Address Line 1" v-model="address.addressLine1" prepend-icon="fas fa-address-card small-icon"></v-text-field>
                  <v-text-field :disabled="submitted" label="Address Line 2" v-model="address.addressLine2" prepend-icon="fas fa-address-card small-icon"></v-text-field>
                  <v-text-field :disabled="submitted" label="Address Line 3" v-model="address.addressLine3" prepend-icon="fas fa-address-card small-icon"></v-text-field>
                  <v-text-field :disabled="submitted" label="Post Code" v-model="address.postCode" prepend-icon="fas fa-address-card small-icon"></v-text-field>
                </div>
                <!-- END ADDRESS -->

                <v-subheader>Driving Details</v-subheader>

                <div class="mx-12">
                  <!-- <DurationsDropdownList
                  v-if="isInstructor"
                  v-bind:durationpassed="this.profile.standardLessonDuration"
                  @durationChanged="onDurationChanged"
                  />-->
                  <v-text-field
                    :disabled="submitted"
                    v-if="isInstructor"
                    type="number"
                    label="Standard hourly rate for lesson"
                    v-model="standardLessonFee"
                    :rules="hourlyRateRules"
                    prepend-icon="fas fa-pound-sign small-icon"
                    required
                  ></v-text-field>

                  <v-text-field
                    :disabled="submitted"
                    v-if="isInstructor"
                    label="Driving School Name"
                    v-model="drivingSchoolName"
                    prepend-icon="fas fa-car-side  small-icon"
                    required
                  ></v-text-field>

                  <v-text-field
                    :disabled="submitted"
                    v-if="isInstructor"
                    label="Website"
                    v-model="website"
                    :rules="websiteRules"
                    prepend-icon="fas fa-link  small-icon"
                    required
                  ></v-text-field>

                  <v-radio-group
                    :disabled="submitted"
                    v-if="isInstructor"
                    v-model="transmission"
                    label="Transmission"
                    prepend-icon="fas fa-car  small-icon"
                    :rules="transmissionRules"
                  >
                    <v-radio label="Manual" value="0"></v-radio>
                    <v-radio label="Automatic" value="1"></v-radio>
                    <v-radio label="Both" value="2"></v-radio>
                  </v-radio-group>

                  <v-switch :disabled="submitted" v-if="isStudent" v-model="hasPassedTheory" label="Passed theory test"></v-switch>
                </div>
                <v-layout px-12 align-center>
                  <v-btn :disabled="submitted" @click="submit" :class="{ 'primary  white--text': valid, disabled: !valid }">Save</v-btn>

                  <v-progress-circular class="ml-4" size="18" width="2" v-if="submitted" indeterminate color="primary"></v-progress-circular>
                </v-layout>
              </v-form>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <FormErrors :errors="errors" />
    </v-card>

    <!-- <v-snackbar v-model="profileUpdated" :bottom="true" :right="true" :timeout="6000" :color="'success'">Profile Saved</v-snackbar> -->
  </div>
</template>

<script>
//import AuthService from "../../services/auth";
//import { LOGIN } from "@/store/actions.type";
//import { Types } from "../../store/actions.type";

import { Mutations } from '@/store/mutations.type'

// import DurationsDropdownList from '@/components/Shared/DurationDropDownList'
//import ProfileAPI from '@/services/profile.service'
import profileInstructorService from '@/services/generated/profileInstructor.service.gen'
import profileStudentService from '@/services/generated/profileStudent.service.gen'
import profileSharedService from '@/services/generated/profileShared.service.gen'

import UploadImage from '@/components/Shared/UploadImage'
import addressService from '@/services/generated/address.service.gen'

import { mapGetters } from 'vuex'

export default {
  components: {
    // DurationsDropdownList,
    UploadImage
  },
  data() {
    return {
      isLoading: true,
      submitted: false,
      valid: false,
      e1: false,
      form: {
        firstName: '',
        lastName: '',
        telephoneNumber: ''
      },
      address: {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        postCode: ''
      },

      firstNameRules: [v => !!v || 'First name is required'],
      lastNameRules: [v => !!v || 'Last name is required'],
      telephoneRules: [v => !!v || 'Telephone number is required'],
      hourlyRateRules: [v => !!v || 'Your hourly rate'],
      websiteRules: [v => this.isUrlValid(v) || 'Website is not a valid URL'],
      transmissionRules: [v => !!v || 'Select the transmission of the vehicle(s) you teach with'],

      errors: [],
      avatarUrl: '',
      profileUpdated: false,

      ///////////////////////////////////////
      standardLessonFee: '',
      //standardLessonDuration: '',
      website: '',
      drivingSchoolName: '',
      transmission: '',

      ///////////////////////////////////////
      hasPassedTheory: false

      ///////////////////////////////////////
    }
  },
  computed: {
    styleObject() {
      return {
        'background-image': 'url(' + this.profilePicture + ')'
      }
    },
    showDefault() {
      return this.avatarUrl == '' || this.avatarUrl == null
    },
    profilePicture() {
      // if (this.avatarUrl && this.avatarUrl.indexOf('random') !== -1) {
      //   return this.avatarUrl
      // }
      // return `${location.protocol}//${location.host}/Avatars/${this.avatarUrl}`

      return this.$options.filters.asProfilePicture(this.avatarUrl)
    },
    HasErrors() {
      return this.errors.length != 0
    },
    ...mapGetters(['profile', 'isInstructor', 'isStudent'])
  },
  methods: {
    isUrlValid(userInput) {
      if (userInput) {
        // eslint-disable-next-line no-useless-escape
        var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
        if (!re.test(userInput)) {
          return false
        }
      }
      return true
    },
    callPickFile() {
      this.$refs.uploader.pickFile()
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.submitted = true
        this.errors = []

        // var model = {
        //   firstName: this.form.firstName,
        //   lastName: this.form.lastName,
        //   telephoneNumber: this.form.telephone,
        //   standardLessonFee: this.form.hourlyRate,
        //   standardLessonDuration: this.form.duration,
        //   //profilePicture: this.form.profilePicture
        // };
        this.form.address = this.address

        if (this.isInstructor) {
          this.form.standardLessonFee = this.standardLessonFee
          //this.form.standardLessonDuration = this.standardLessonDuration
          this.form.website = this.website
          this.form.transmission = this.transmission.toString()
          this.form.drivingSchoolName = this.drivingSchoolName

          //ProfileAPI.updateInstructorProfile(this.form) //model)
          profileInstructorService
            .updateProfile(
              this.standardLessonFee,
              this.website,
              this.drivingSchoolName,
              this.transmission.toString(),
              this.form.firstName,
              this.form.lastName,
              this.form.telephoneNumber,
              this.form.address
            )

            .then(({ data }) => {
              this.submitted = false
              console.log(data)
              this.profileUpdated = true
              this.$store.commit(Mutations.SET_PROFILE, data)
              //this.$store.commit(Mutations.SET_PROFILE_PICTURE, this.avatarUrl)

              setTimeout(() => {
                this.profileUpdated = false
              }, 3000)

              //this.lessonCreated = true;
              //this.students = data.students;
              //this.pageMetaData = data.pageMetaData
            })
            .catch(({ response }) => {
              console.log(response)
              this.errors = response.data.errors
              this.submitted = false
            })
        } else {
          this.form.hasPassedTheory = this.hasPassedTheory

          //ProfileAPI.updateStudentProfile(this.form) //model)
          profileStudentService
            .updateProfile(this.hasPassedTheory, this.form.firstName, this.form.lastName, this.form.telephoneNumber, this.form.address)
            .then(({ data }) => {
              this.submitted = false
              console.log(data)
              this.profileUpdated = true
              this.$store.commit(Mutations.SET_PROFILE, data)
              //this.$store.commit(Mutations.SET_PROFILE_PICTURE, this.avatarUrl)
              setTimeout(() => {
                this.profileUpdated = false
              }, 3000)
            })
            .catch(({ response }) => {
              console.log(response)
              this.errors = response.data.errors
              this.submitted = false
            })
        }
      }
    },
    onDurationChanged() {
      //this.standardLessonDuration = selected
      //  alert('partesxe '+selected) // someValue
    },
    onFileChosen() {
      //model) {
      // var model = {
      //   profileImage: this.imageFile
      // }
      //ProfileAPI.updateAvatar(model)
      profileSharedService
        .updateAvatar(this.imageFile)
        .then(({ data }) => {
          //this.$emit('avatarSaved', data)
          this.avatarUrl = data
          this.$store.commit(Mutations.SET_PROFILE_PICTURE, data)

          console.log(data)
        })
        .catch(({ response }) => {
          console.log(response)
        })
    },
    deleteAvatar() {
      //alert("deee")

      profileSharedService
        .deleteAvatar()
        .then(() => {
          //this.$emit('avatarSaved', data)
          this.avatarUrl = ''
          this.$store.commit(Mutations.SET_PROFILE_PICTURE, this.avatarUrl)
        })
        .catch(({ response }) => {
          console.log(response)
        })
    }

    //...mapMutations([Mutations.SET_PROFILE])
  },
  created() {
    // debugger;
    //console.log(this.profile)
    //this.form = this.profile

    //this.avatar = this.data.profilePicture

    if (this.isInstructor) {
      //return axios.get(Enpoints.GET_INSTRUCTOR_PROFILE_OWN)
      profileInstructorService
        .getProfileInstructorOwn()
        .then(({ data }) => {
          this.isLoading = false
          this.form.firstName = data.firstName
          this.form.lastName = data.lastName
          this.form.telephoneNumber = data.telephoneNumber
          this.form.email = data.email
          this.avatarUrl = data.profilePicture

          this.standardLessonFee = this.$options.filters.asMoney(data.standardLessonFee, true) //data.standardLessonFee
          //this.standardLessonDuration = data.standardLessonDuration
          this.website = data.website
          this.drivingSchoolName = data.drivingSchoolName
          this.transmission = data.transmission.toString()
        })
        .catch(({ response }) => {
          console.log(response)
        })
    } else {
      //return axios.get(Enpoints.GET_STUDENT_PROFILE_OWN)
      profileStudentService
        .getProfileStudentOwn()
        .then(({ data }) => {
          this.isLoading = false
          this.form.firstName = data.firstName
          this.form.lastName = data.lastName
          this.form.telephoneNumber = data.telephoneNumber
          this.form.email = data.email
          this.avatarUrl = data.profilePicture

          this.hasPassedTheory = data.hasPassedTheory
        })
        .catch(({ response }) => {
          console.log(response)
        })
    }

    // ProfileAPI.getOwnProfile(this.isInstructor)
    //   .then(({ data }) => {
    //     this.isLoading = false
    //     this.form.firstName = data.firstName
    //     this.form.lastName = data.lastName
    //     this.form.telephoneNumber = data.telephoneNumber
    //     this.form.email = data.email
    //     this.avatarUrl = data.profilePicture

    //     console.log(data)
    //     if (this.isInstructor) {
    //       this.standardLessonFee = this.$options.filters.asMoney(data.standardLessonFee, true) //data.standardLessonFee
    //       //this.standardLessonDuration = data.standardLessonDuration
    //       this.website = data.website
    //       this.drivingSchoolName = data.drivingSchoolName
    //       this.transmission = data.transmission.toString()
    //     } else {
    //       this.hasPassedTheory = data.hasPassedTheory
    //     }
    //   })
    //   .catch(({ response }) => {
    //     console.log(response)
    //   })

    // if (this.isInstructor) {
    //   this.standardLessonFee = this.form.standardLessonFee
    //   this.standardLessonDuration = this.form.standardLessonDuration
    //   this.website = this.form.website
    // } else {
    //   this.hasPassedTheory = this.form.hasPassedTheory
    // }

    addressService
      .getAddress()
      .then(({ data }) => {
        this.address = data
      })
      .catch(({ response }) => {
        console.log('no adfd')
        console.log(response)
      })
  }
}
</script>

vb

<style scoped lang="scss">
.avatar-image {
  cursor: pointer;
}
.delete-chip {
  height: 30px;
  background: #669933;
}

$avatar-size: 100px;

.company-header-avatar {
  width: $avatar-size;
  height: $avatar-size;
  -webkit-border-radius: $avatar-size;
  -webkit-background-clip: padding-box;
  -moz-border-radius: $avatar-size;
  -moz-background-clip: padding;
  border-radius: $avatar-size;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  float: left;
  background-size: cover;
  background-position: center center;
}
</style>
