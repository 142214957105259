<template>
  <MiscWrapper>
    <template #content>
      <v-layout row wrap justify-center>
        <v-flex xs12 lg8>
          <h2>texst asd gg aws 38 yess rrvv</h2>

          <a @click="invokeError">test 1</a>
          <br />
          <a @click="invokeError2">test 2</a>

          <pre>{{ test }}</pre>
          <pre>{{ testconfig }}</pre>
        </v-flex>
      </v-layout>
    </template>
  </MiscWrapper>
</template>

<script>
import MiscWrapper from './MiscWrapper'
import axios from 'axios'
import webhookService from '@/services/generated/webhook.service.gen'

export default {
  components: {
    MiscWrapper
  },
  data() {
    return {
      test: [],
      testconfig: ''
    }
  },
  methods: {
    invokeError() {
      axios.get('/api/webhook/testerror').then(response => {
        console.log('TCL: invokeError -> response', response)
      })
    },
    invokeError2() {
      axios.get('/api/webhook/testerror2').then(response => {
        console.log('TCL: invokeError -> response', response)
      })
    }
  },
  mounted() {
    // axios.get('https://localhost:7248/api/webhook/test').then(response => {
    //   this.test = response.data
    // })

    // axios.get('https://localhost:7248/api/webhook/testconfig').then(response => {
    //   this.testconfig = response.data
    // })

    webhookService
      .testConfig()
      .then(({ data }) => {
        console.log('daaata:', data)
      })
      .catch(error => {
        console.log(error)
      })
  }
}
</script>
