//stripe.service.gen.js
// import stripeService from '@/services/generated/stripe.service.gen'
import axios from 'axios'

export default {
  getStripeAccount() {
    return axios.get('/api/stripe/account')
  },

  /*
					stripeService.getStripeAccount()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getAccountBalance() {
    return axios.get('/api/stripe/account-balance')
  },

  /*
					stripeService.getAccountBalance()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  setPaymentStatusPendingApproval() {
    return axios.put('/api/stripe/set-payment-status-ip-pending-approval')
  },

  /*
					stripeService.setPaymentStatusPendingApproval()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getStripeAccountFull() {
    return axios.get('/api/stripe/account-full')
  },

  /*
					stripeService.getStripeAccountFull()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  createAccount(firstName, lastName, address, dob, clientIP) {
    var model = {
      firstName: firstName,
      lastName: lastName,
      address: address,
      dob: dob,
      clientIP: clientIP
    }
    return axios.post('/api/stripe/create-account', model)
  },

  /*
					stripeService.createAccount(firstName, lastName, address, dob, clientIP)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  createAccountForOnBoarding() {
    return axios.post('/api/stripe/create-account-onboarding')
  },

  /*
					stripeService.createAccountForOnBoarding()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  createAccountLinks(mode) {
    var model = {
      mode: mode
    }
    return axios.post('/api/stripe/create-account-links', model)
  },

  /*
					stripeService.createAccountLinks(mode)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  updateAccountServerSide(firstName, lastName, address, dob) {
    var model = {
      firstName: firstName,
      lastName: lastName,
      address: address,
      dob: dob
    }
    return axios.post('/api/stripe/update-account-server-side', model)
  },

  /*
					stripeService.updateAccountServerSide(firstName, lastName, address, dob)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  updateAccount(externalAccountToken) {
    var model = {
      externalAccountToken: externalAccountToken
    }
    return axios.post('/api/stripe/update-account', model)
  },

  /*
					stripeService.updateAccount(externalAccountToken)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  uploadVerificationDocument(stripeAccountId, file) {
    var formData = new FormData()
    formData.append('stripeAccountId', stripeAccountId)
    formData.append('file', file)

    return axios.post('/api/stripe/upload-verification-document', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  /*
					stripeService.uploadVerificationDocument(stripeAccountId, file)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getPaymentMethods() {
    return axios.get('/api/stripe/payment-methods')
  },

  /*
					stripeService.getPaymentMethods()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  confirmPayment(payment_method_id, payment_intent_id, lessons, isExistingPaymentMethod) {
    var model = {
      payment_method_id: payment_method_id,
      payment_intent_id: payment_intent_id,
      lessons: lessons,
      isExistingPaymentMethod: isExistingPaymentMethod
    }
    return axios.post('/api/stripe/confirm-payment', model)
  },

  /*
					stripeService.confirmPayment(payment_method_id, payment_intent_id, lessons, isExistingPaymentMethod)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getPayoutDateForStripePayment(paymentId, studentId) {
    return axios.get(`/api/stripe/payout-date/${paymentId}/${studentId}`)
  }
  /*
					stripeService.getPayoutDateForStripePayment(paymentId, studentId)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
