//auth.service.gen.js
// import authService from '@/services/generated/auth.service.gen'
import axios from 'axios'

export default {
  login(email, password, client) {
    var model = {
      email: email,
      password: password,
      client: client
    }
    return axios.post('/api/auth/login', model)
  },

  /*
					authService.login(email, password, client)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  register(email, password, confirmPassword, role, client) {
    var model = {
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      role: role,
      client: client
    }
    return axios.post('/api/auth/register', model)
  },

  /*
					authService.register(email, password, confirmPassword, role, client)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  refreshToken(accessToken, client, refreshToken) {
    var model = {
      accessToken: accessToken,
      client: client,
      refreshToken: refreshToken
    }
    return axios.post('/api/auth/refresh-token', model)
  },

  /*
					authService.refreshToken(accessToken, client, refreshToken)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  forgotPassword(email) {
    var model = {
      email: email
    }
    return axios.post('/api/auth/forgot-password', model)
  },

  /*
					authService.forgotPassword(email)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  resetPassword(email, password, confirmPassword, code) {
    var model = {
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      code: code
    }
    return axios.post('/api/auth/reset-password', model)
  },

  /*
					authService.resetPassword(email, password, confirmPassword, code)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  changePassword(oldPassword, newPassword, confirmPassword) {
    var model = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword
    }
    return axios.post('/api/auth/change-password', model)
  },

  /*
					authService.changePassword(oldPassword, newPassword, confirmPassword)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  logout() {
    return axios.get('/api/auth/logout')
  },

  /*
					authService.logout()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  confirmEmail(userId, code, client) {
    return axios.get('/api/auth/confirm-email', {
      params: {
        userId: userId,
        code: code,
        client: client
      }
    })
  },

  /*
					authService.confirmEmail(userId, code, client)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  contact(email, message) {
    var model = {
      email: email,
      message: message
    }
    return axios.post('/api/auth/contact', model)
  }
  /*
					authService.contact(email, message)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
