const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN = 'refreshToken'
const EMAIL = 'email'
const USERTYPE = 'userType'
const USERDETAILS = 'userDetails'

export const getAccessToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN)
}

export const saveAccessToken = token => {
  window.localStorage.setItem(ACCESS_TOKEN, token)
}

export const getRefreshToken = () => {
  return window.localStorage.getItem(REFRESH_TOKEN)
}

export const saveRefreshToken = token => {
  window.localStorage.setItem(REFRESH_TOKEN, token)
}

export const destroyTokens = () => {
  window.localStorage.removeItem(ACCESS_TOKEN)
  window.localStorage.removeItem(REFRESH_TOKEN)
}

export const getEmail = () => {
  return window.localStorage.getItem(EMAIL)
}

export const saveEmail = email => {
  window.localStorage.setItem(EMAIL, email)
}

export const getUserType = () => {
  return window.localStorage.getItem(USERTYPE)
}

export const saveUserType = userType => {
  window.localStorage.setItem(USERTYPE, userType)
}

export const setUserDetails = userDetails => {
  window.localStorage.setItem(USERDETAILS, JSON.stringify(userDetails))
}

export const getUserDetails = () => {
  var data = localStorage.getItem(USERDETAILS)

  if (data && data !== 'undefined') {
    return JSON.parse(data)
  }
  window.localStorage.removeItem(USERDETAILS)
  return null
  //  var userDetailsStorage = window.localStorage.getItem(USERDETAILS);
  // // if (userDetails && userDetails !== 'undefined') {
  // //   return JSON.parse(userDetails);
  // // }
  // var userDetails = JSON.parse(userDetailsStorage || "null");
  // if (userDetails) {
  //   return userDetails;
  //   // There wasn't one, do whatever is appropriate
  // }

  //return {};
}

export const updateProfile = profileDetails => {
  let user = getUserDetails()
  user.profile = profileDetails
  setUserDetails(user)
}

export const updateProfilePicture = profilePicture => {
  let user = getUserDetails()
  user.profile.profilePicture = profilePicture
  setUserDetails(user)
}

export const updateCode = instructorCode => {
  let user = getUserDetails()
  user.profile.instructorCode = instructorCode
  setUserDetails(user)
}
export const updateSubscriptionStatus = subscriptionStatus => {
  let user = getUserDetails()
  user.profile.subscriptionStatus = subscriptionStatus
  setUserDetails(user)
}

export const updateSubscriptionStatusStripe = subscriptionStatusStripe => {
  let user = getUserDetails()
  user.profile.subscriptionStatusStripe = subscriptionStatusStripe
  setUserDetails(user)
}

export const updatePayoutStatus = payoutStatus => {
  let user = getUserDetails()
  user.profile.payoutStatus = payoutStatus
  setUserDetails(user)
}

export default {
  getAccessToken,
  saveAccessToken,
  getRefreshToken,
  saveRefreshToken,
  destroyTokens,
  getEmail,
  saveEmail,
  getUserType,
  saveUserType,
  setUserDetails,
  getUserDetails,
  updateProfile,
  updateProfilePicture,
  updateCode,
  updateSubscriptionStatus,
  updateSubscriptionStatusStripe,
  updatePayoutStatus
}
