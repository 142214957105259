<template>
  <MiscWrapper>
    <template #content>
      <v-layout row wrap>
        <v-flex xs12>
          <h1 class="mb-4">Privacy Policy</h1>

          <p>
            This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from
            https://www.instructorpay.com (the “Site”).
          </p>

          <h3>Personal information we collect</h3>

          <p>
            When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone,
            and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or
            products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this
            automatically-collected information as “Device Information.”
          </p>

          <p>
            We collect Device Information using the following technologies: - “Cookies” are data files that are placed on your device or computer and often include an
            anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org. - “Log files” track actions
            occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps. - “Web
            beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.
          </p>

          <p>
            Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing
            address, shipping address, payment information (including credit card numbers), email address, and phone number.
          </p>

          <h3>How do we use your personal information?</h3>
          <p>
            We use the Payment Information that we collect generally to fulfill any payments placed through the Site (including processing your payment information and
            confirmations). We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more
            generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the
            success of our marketing and advertising campaigns).
          </p>
          <h3>Sharing your personal information</h3>

          <p>
            Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful
            request for information we receive, or to otherwise protect our rights.
          </p>

          <h3>Data retention</h3>
          <p>
            When you make a payment through the Site, we will maintain your Payment Information for our records unless and until you ask us to delete this information.
          </p>

          <h3>Changes</h3>
          <p>
            We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory
            reasons.
          </p>
          <h3>Contact us</h3>

          <p>
            For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at
            hello@instructorpay.com or by mail using the details provided below:
          </p>
        </v-flex>
      </v-layout>
    </template>
  </MiscWrapper>
</template>

<script>
import MiscWrapper from './MiscWrapper'

export default {
  components: {
    MiscWrapper
  }
}
</script>
