<template>
  <v-layout row wrap align-center>
    <v-flex lg4 sm4 xs8>
      <AvatarName class="ma-2" :userId="payment.studentId" :size="'md'" :name="payment.studentName" :imageUrl="payment.studentProfilePicture" />
    </v-flex>
    <v-flex xs4 text-xs-right body-2 hidden-sm-and-up>
      {{ miniDate }}
    </v-flex>
    <v-flex class="px-6 payments-flex" :class="paymentBox">
      <div class="heading">
        <span class="amount">Paid</span>
        <span class="application-fee" :class="hasFee">App Fee</span>
        <span class="transferred">Recieved</span>
      </div>

      <div class="payments">
        <span class="amount">{{ payment.amount | asMoney }}</span>
        <span class="application-fee" :class="hasFee">{{ appFee }}</span>
        <span class="transferred ">{{ payment.transferred | asMoney }}</span>
      </div>
    </v-flex>
    <v-flex class="hidden-sm-and-down" lg1>
      <span class>
        {{ miniDate }}
        <!-- <br /> -->
        <!-- {{ miniTime }} -->
      </span>
    </v-flex>
    <v-flex class="hidden-sm-and-down" lg1>
      <!-- <span class="secondary px-1">{{ payment.paymentMethod }}</span> -->
      <v-chip small :class="chipColor" class="white--text">{{ payment.paymentMethodFriendly }}</v-chip>
      <!-- <br /> -->
      <!-- <span>ref:{{ payment.id }}</span> -->
    </v-flex>
    <v-flex class="hidden-sm-and-down" lg2>
      <ul>
        <li v-for="(lesson, index) in payment.lessons" v-bind:key="'i' + index">
          <router-link :to="{ name: 'ViewLesson', params: { lessonId: lesson.id } }">{{ lesson.lessonName }}</router-link>
          <!-- <pre>{{lesson}}</pre> -->
        </li>
      </ul>
    </v-flex>
    <!-- <pre>{{payment}}</pre> -->
  </v-layout>
</template>

<script>
// 0 Pending,
// 1 Approved,
// 2 Denied,
// 3 PaymentPassed,
// 4 Deleted

import moment from 'moment'

export default {
  props: {
    payment: {
      type: Object
    },
    isMini: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      //   pushed: false
      payoutDate: ''
    }
  },
  computed: {
    chipColor() {
      return {
        primary: this.payment.paymentMethod === 'Stripe',
        info: this.payment.paymentMethod === 'Cash',
        grey: this.payment.paymentMethod === 'Other'
      }
    },
    hasFee() {
      return {
        hasFee: this.payment.paymentMethod !== 'Stripe'
      }
    },
    paymentBox() {
      return {
        //lg12: this.isMini || this.$vuetify.breakpoint.smAndDown,
        //lg4: !this.isMini,
        mobile: this.$vuetify.breakpoint.smAndDown,
        standard: this.$vuetify.breakpoint.mdAndUp
      }
    },
    appFee() {
      if (this.payment.paymentMethod === 'Stripe') {
        return this.$options.filters.asMoney(this.payment.applicationFee)
      }
      return 'N/A'
    },
    paymentId() {
      return this.payment.id
    },
    paymentRef() {
      if (this.payment.stripeChargeId) {
        return 'ref:' + this.payment.id + '-' + this.payment.stripeChargeId
      } else {
        return 'ref:' + this.payment.id
      }
    },
    studentId() {
      return this.payment.lessons[0].studentId
    },
    date() {
      return moment(this.payment.localDatePaid).format('lll')
    },
    miniDate() {
      return moment(this.payment.localDatePaid).format('DD/MM/YY')
    },
    miniTime() {
      return moment(this.payment.localDatePaid).format('HH:mm')
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.payment-row {
  cursor: pointer;
}

.payments-flex {
  text-align: center;
  .application-fee {
    border-left: dashed 1px #aaa;
    border-right: dashed 1px #aaa;
  }
  .transferred {
    text-align: center;
  }
  span {
    padding: 5px;
  }
}
.heading {
  span {
    border-bottom: dashed 1px #aaa;
    font-weight: bold;
  }
}

.payments {
  .transferred {
  }
}

.amount {
  display: inline-block;
  width: 33%;
}
.application-fee {
  display: inline-block;
  width: 33%;

  &.hasFee {
    color: #ccc;
  }
}
.transferred {
  display: inline-block;
  width: 33%;
}

.mobile {
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
