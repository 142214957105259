<template>
  <v-app id="app">
    <Home v-if="!isLoggedIn && !isMisc" />
    <DashboardIndex v-if="isLoggedIn && !isMisc" />
    <Misc v-if="isMisc" />
  </v-app>
</template>

<script>
import Home from './views/Home'
import DashboardIndex from './views/DashboardIndex'
import Misc from './views/Misc/Misc'

export default {
  name: 'App',
  components: {
    Home,
    DashboardIndex,
    Misc
    // Header
  },
  data: () => ({
    //isLoggegIn:!true,
    items: [
      { icon: 'trending_up', text: 'Most Popular' },
      { icon: 'subscriptions', text: 'Subscriptions' },
      { icon: 'history', text: 'History' },
      { icon: 'featured_play_list', text: 'Playlists' },
      { icon: 'watch_later', text: 'Watch Later' }
    ]
  }),
  computed: {
    isLoggedIn() {
      var a = this.$store.state.auth.isAuthenticated
      return a
    },
    isMisc() {
      let meta = this.$route.meta
      console.log(meta)
      return meta.isMisc
    }
  }
  // created() {
  //   let stripeScript = document.createElement('script')
  //   recaptchaScript.setAttribute('src', 'https://js.stripe.com/v3/')
  //   document.head.appendChild(stripeScript)
  // },
}
</script>

<style lang="scss" scoped>
.v-application.v-application--is-ltr.theme--light {
  background: #fafafa;
}
</style>
