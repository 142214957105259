<template>
  <!-- <Header /> -->

  <!-- <router-view></router-view> -->
  <!-- <Header /> -->
  <router-view name="misc"></router-view>
</template>

<script>
export default {
  components: {},
  data: () => ({
    //isLoggedIn: true
  })
}
</script>
