<template>
  <v-col offset-lg="" sm="12" xs="12" md="12" lg="4">
    <SubHeading :title="'Application Fees'" />

    <div class="grey lighten-3 pa-4">
      <p class="mt-4">
        Any lessons paid for through InstructorPay will automatically have an application fee deducted. This only applies to lessons paid for by learners through this
        application using credit or debit cards. The fee is calculated as follows
      </p>

      <v-row>
        <v-col lg="12">
          <div class="pa-6 text-center display-2 pricing">
            {{ sumPC }}% + {{ sumFixed }}p
            <br />
            <span class="subtitle-2">per payment</span>
          </div>
        </v-col>
      </v-row>
      <h4 class="underline mb-2 font-weight-black">Examples</h4>

      <v-row>
        <v-col lg="12">
          <SetupPaymentsOnboardingCalculator :amount="myStandardRate" :egNumber="3" :sumFixed="sumFixed" :sumPC="sumPC" />
        </v-col>
        <!-- <v-col lg="12">
          <SetupPaymentsOnboardingCalculator :amount="25" :egNumber="1" />
        </v-col> -->
        <v-col lg="12">
          <SetupPaymentsOnboardingCalculator :hours="'2 hour'" :amount="doubleRate" :egNumber="2" :sumFixed="sumFixed" :sumPC="sumPC" />
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
//import InstructorAPI from '../../../../services/instructor.service'
// import SetupPaymentsMessages from './SetupPaymentsMessages'
import SetupPaymentsOnboardingCalculator from './SetupPaymentsOnboardingCalculator'

//mport _ from 'lodash'

export default {
  components: {
    SetupPaymentsOnboardingCalculator
  },
  data() {
    return {}
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },

    sumFixed() {
      return this.user.profile.sumFeeFixed
    },

    sumPC() {
      return this.user.profile.sumFeePercentage
    },

    doubleRate() {
      return Number((this.myStandardRate * 2.0).toFixed(2))
    },
    myStandardRate() {
      return Number(this.user.profile.standardLessonFee.toFixed(2))
    },
    // sumFixed() {
    //   return this.stripeFixed + this.myFixed
    // },

    // sumPC() {
    //   return this.stripPC + this.myPc
    // },
    // stripeFixed() {
    //   return 10
    // },
    // myFixed() {
    //   return 20
    // },

    stripPC() {
      return 1.4
    },
    myPc() {
      return 1.4 //1.5
    }
  },

  methods: {},
  created() {}
}
</script>

<style lang="scss" scoped></style>
