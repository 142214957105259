<template>
  <v-content>
    <v-container fill-height v-cloak grey lighten-5>
      <v-layout justify-center>
        <v-flex xl9 lg12 md12 sm12 class="no-gutters">
          <transition name="fade" mode="out-in">
            <router-view name="dash"></router-view>
          </transition>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
export default {
  components: {
    // UpcomingLessons
  },
  data() {
    return {}
  },
  computed: {
    showUpcoming() {
      console.log('route: ' + this.$route.path)
      return this.$route.path === '/'
    }
  }
}
</script>

<style scoped>
[v-cloak] {
  display: none !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
