import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import lessons from './lessons'
import instructor from './instructor'
import student from './student'
import payment from './payment'
import studentpayment from './studentpayment'

import global from './global'
import chat from './chat'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global,
    auth,
    chat,
    lessons,
    instructor,
    student,
    payment,
    studentpayment
  }
})
