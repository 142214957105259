<template>
  <div>
    <v-layout row>
      <v-flex>
        <v-list two-line class="transparent" v-if="hasItems">
          <template v-for="(lesson, index) in lessons">
            <!-- <pre>{{lesson}}</pre> -->
            <LessonsItem v-bind:lesson="lesson" v-bind:key="index" :type="type" />
            <v-divider v-bind:key="'div' + index" light class="my-4"></v-divider>
          </template>
        </v-list>

        <div v-if="!hasItems">
          <h4 class="pa-12 text-center">There are no {{ type }} lessons</h4>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
//import LessonsItem from '../Lessons/LessonsItem'
import LessonsItem from './StudentsMiniLessonsListItem'

import _ from 'lodash'

export default {
  props: ['lessons', 'type'],

  components: {
    LessonsItem
  },

  data() {
    return {
      // studentId: '',
      // //type: '',
      // pageSize: 10,
      // pageMetaData: {},
      // busy: false
    }
  },
  computed: {
    hasItems() {
      return this.lessons && this.lessons.length
    },
    lessonIds() {
      return _.map(this.lessons, 'id')
    }

    // sortedLessons() {
    //   if (this.type == 'upcoming') {
    //     var copy = this.lessons
    //     copy.sort(function(a,b){return new Date(a.localStartDateTime) - new Date(b.localStartDateTime);})
    //     return copy
    //   }
    //   return this.lessons
    // }

    // nextPage() {
    //   return this.pageMetaData.nextPage ? this.pageMetaData.nextPage : 1
    // }
  },
  methods: {},

  created() {}
}
</script>

<style scoped></style>
