//profileStudent.service.gen.js
// import profileStudentService from '@/services/generated/profileStudent.service.gen'
import axios from 'axios'

export default {
  getProfileStudentOwn() {
    return axios.get('/api/student/profile/getprofilestudentown')
  },

  /*
					profileStudentService.getProfileStudentOwn()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  updateProfile(hasPassedTheory, firstName, lastName, telephoneNumber, address) {
    var tempAddress = null
    if (address) {
      tempAddress = {
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        addressLine3: address.addressLine3,
        postCode: address.postCode
      }
    }
    var model = {
      hasPassedTheory: hasPassedTheory,
      firstName: firstName,
      lastName: lastName,
      telephoneNumber: telephoneNumber,
      address: tempAddress
    }
    return axios.put('/api/student/profile', model)
  }
  /*
					profileStudentService.updateProfile(hasPassedTheory, firstName, lastName, telephoneNumber, address)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
