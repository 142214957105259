// export const PayoutStatus = {
//   Unsubmitted: 0,
//   StripePending: 1,
//   StripeEnabled: 2,
//   StripeDisabledAfterEnabled: 3,
//   InstructorPayEnabled: 4,
//   DisabledByIntructor: 5,
//   Banned: 6,
//   RegisteredInterest: 7,
//   EligibleForEnabling:8
// }

export const PayoutStatus = {
  Unsubmitted: 0,
  RegisteredInterest: 1,
  EligibleForEnabling: 2,

  InstructorPayPendingApproval: 3,

  InstructorPayEnabled: 4,

  Banned: 5,
  DisabledByIntructor: 6
}

export const SubscriptionStatus = {
  Trialing: 0,
  TrialEnded: 1,
  Incomplete: 2, // On sign up didnt complete payment
  Active: 3,
  Ended: 4, // Payment failed on a billing run and card not updated
  Cancelled: 5 // Cancelled by user or
}

export const BankPayoutStatus = {
  // Unsubmitted: 0,
  // PendingApproval: 1,
  // PayoutEnabled: 2,
  // Banned: 3,
  // DisabledByIntructor: 4

  Unsubmitted: 0, // not added bank
  BankCreated: 1, // added bank detail
  PayoutEnabled: 2, // counter party created
  Suspended: 3,
  DisabledByIntructor: 4
}
