<template>
  <v-layout wrap>
    <h4>There was a problem with verifying details...</h4>
    <v-overlay :value="true">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
  </v-layout>
</template>

<script>
//import StripeAPI from '../../../../services/stripe.service'
import stripeService from '@/services/generated/stripe.service.gen'

export default {
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {
    let mode = 'verify'
    let modeQuery = this.$route.query.mode
    if (modeQuery) {
      mode = modeQuery
    }
    stripeService
      .createAccountLinks(mode)
      .then(result => {
        //console.log('createAccountServerSide' + result)
        //console.log(result.data.url)

        window.location.replace(result.data.url)
        //resolve({ accountId: result.data.accountId })
      })
      .catch(error => {
        console.log('TCL: saveData -> error', error)
      })
  }
}
</script>
