<template>
  <!-- <v-toolbar fixed text class="tb">
        <v-toolbar-title>Title</v-toolbar-title>
        <v-btn color="success">text</v-btn>
  </v-toolbar>-->

  <v-layout row wrap class="fullscree">
    <v-flex md6 xs12 accent>
      <div class="wrapper">
        <div class="pl-6 pt-3">
          <router-link to="/">
            <img src="@/assets/images/Logo.png" style="width: 250px; height: auto;" />
          </router-link>
        </div>
        <div class="row3">
          <v-layout wrap align-center fill-height justify-center>
            <v-flex xs11 sm9>
              <v-layout column>
                <slot name="form"></slot>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </v-flex>
    <v-flex md6 xs12 v-if="this.$vuetify.breakpoint.mdAndUp">
      <div class="wrapper">
        <div class="row3">
          <v-layout column justify-center align-center fill-height>
            <slot name="rightside"></slot>
          </v-layout>
        </div>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.wrapper,
html,
body {
  height: 100%;
  margin: 0;
}
.wrapper {
  display: flex;
  flex-direction: column;
}

.row3 {
  flex: 2;
  display: flex;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  // background: lime; /* Just to visualize the extent */
}
.logoa {
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}
.tb {
  background-color: rgb(255, 0, 0);
  opacity: 0.6;
}
</style>
