<template>
  <v-layout>
    <v-flex>
      <PageHeading title="Students" />
      <v-layout align-center justify-space-between row wrap class="pa-0 ma-0 my-4">
        <v-flex xs12 sm8 v-if="!$vuetify.breakpoint.smAndDown">
          <v-btn small color="accent" class="elevation-0" :text="!(status == 'Active')" @click="changeStatus('Active')">Active {{ active }}</v-btn>
          <v-btn small color="accent" class="elevation-0 ml-2" :text="!(status == 'Passed')" @click="changeStatus('Passed')">Passed {{ passed }}</v-btn>
          <v-btn small color="accent" class="elevation-0 ml-2" :text="!(status == 'Removed')" @click="changeStatus('Removed')">Removed {{ removed }}</v-btn>
          <v-btn small color="accent" class="elevation-0 ml-2" :text="!(status == 'StudentDeleted')" @click="changeStatus('StudentDeleted')"
            >Inactive {{ studentDeleted }}</v-btn
          >
        </v-flex>
        <v-flex xs12 sm4 v-if="$vuetify.breakpoint.smAndDown">
          <v-layout align-center align-content-end>
            <v-select class="mr-2 sort-select" :items="typeItems" item-text="text" item-value="value" v-model="defaultType" @change="changeStatus"></v-select>
          </v-layout>
        </v-flex>

        <v-flex xs12 sm4>
          <v-layout align-center align-content-end>
            <span class="subtitle-1 ma-4">Sort:</span>
            <v-select class="mr-2 sort-select" :items="sortItems" item-text="text" item-value="value" v-model="defaultSelected" @change="sortChanged"></v-select>
            <v-btn text icon small color="primary" @click="changeOrder('asc')">
              <v-icon>fas fa-arrow-up small-icon</v-icon>
            </v-btn>

            <v-btn text icon small color="primary" @click="changeOrder('desc')">
              <v-icon>fas fa-arrow-down small-icon</v-icon>
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
      <Spinner :isLoading="isLoading" />

      <div v-show="!isLoading" v-infinite-scroll="fetchStudents" infinite-scroll-disabled="busy" infinite-scroll-distance="pageSize">
        <v-container grid-list-xl class="ma-0 pa-0">
          <v-layout row wrap v-if="hasItems">
            <template v-for="student in students">
              <StudentsItem v-bind:student="student" :status="status" v-bind:key="student.id" />
            </template>
          </v-layout>

          <div v-if="!hasItems">
            <h4 class="pa-12 text-lg-center">There are currently no students {{ statusText }}</h4>
          </div>

          <v-alert border="top" colored-border type="info" elevation="2" class="mt-4">
            To Add Students here, your students need to sign up and use your code to pair up with you. Your pairing code is
            <span class="title accent  pa-1 white--text"> {{ instructorCode }}</span>
          </v-alert>
        </v-container>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
//import InstructorAPI from '../../../../services/instructor.service'
import instructorService from '@/services/generated/instructor.service.gen'

import StudentsItem from './StudentsItem'
//import StudentsPaging from "./StudentsPaging";
// import { Actions } from "@/store/actions.type";
// import { mapState } from "vuex";
// import { mapMutations } from "vuex";

export default {
  components: {
    StudentsItem
    //  StudentsPaging
  },
  data() {
    return {
      isLoading: true,
      type: 'active',
      order: 'name',
      status: 'Active',
      //pageNumber: "",
      pageSize: 20,
      students: [],
      pageMetaData: {},
      busy: false,
      sortItems: [
        { text: 'Name', value: 'name' },
        { text: 'Date', value: 'date' }
      ],
      defaultSelected: { text: 'Name', value: 'name' },
      defaultType: { text: 'Active ', value: 'Active' },

      counter: null
    }
  },
  computed: {
    instructorCode() {
      return this.$store.getters.user.profile.instructorCode
    },

    statusText() {
      if (this.status == 'StudentDeleted') {
        return 'Inactive'
      }
      return this.status
    },
    // currentPage() {
    //   return this.pageMetaData.currentPage;
    // },
    // totalPages() {
    //   return this.pageMetaData.totalPages;
    // }
    nextPage() {
      return this.pageMetaData.nextPage ? this.pageMetaData.nextPage : 1
    },

    shouldFetchData() {
      return this.nextPage == 1 || this.pageMetaData.hasNext
    },

    // pending() {
    //   return this.counter ? '(' + this.counter.pending + ')' : ''
    // },
    // approved() {
    //   return this.counter ? '(' + this.counter.approved + ')' : ''
    // },
    // denied() {
    //   return this.counter ? '(' + this.counter.denied + ')' : ''
    // },

    typeItems() {
      let i = [
        { text: 'Active ' + this.active, value: 'Active' },
        { text: 'Passed ' + this.passed, value: 'Passed' },
        { text: 'Removed ' + this.removed, value: 'Removed' },
        { text: 'Inactive ' + this.studentDeleted, value: 'StudentDeleted' }
      ]

      return i
    },

    // defaultType() {
    //   return { text: 'Active ' + this.active, value: 'active' }
    // },
    active() {
      return this.counter ? '(' + this.counter.active + ')' : ''
    },
    passed() {
      return this.counter ? '(' + this.counter.passed + ')' : ''
    },
    removed() {
      return this.counter ? '(' + this.counter.removed + ')' : ''
    },
    studentDeleted() {
      return this.counter ? '(' + this.counter.studentDeleted + ')' : ''
    },

    hasItems() {
      return this.students && this.students.length
    }
  },
  methods: {
    // onPageChange(pageNumber) {
    //   this.pageNumber = pageNumber;
    //   this.fetchStudents();
    // },
    sortChanged(item) {
      this.changeType(item)
    },
    reset() {
      this.students = []
      this.pageMetaData = {}
      this.fetchStudents()
    },
    changeType(type) {
      this.type = type
      this.reset()
    },

    changeOrder(order) {
      this.order = order
      this.reset()
    },

    changeStatus(status) {
      this.status = status
      this.reset()
    },

    fetchStudents() {
      this.busy = true
      let prevRoute = this.$route.path

      if (this.shouldFetchData) {
        instructorService
          .getStudents(this.type, this.order, this.status, this.nextPage, this.pageSize)
          .then(({ data }) => {
            this.pageMetaData = data.pageMetaData
            this.students.push(...data.students)
            this.counter = data.counter
            this.busy = false
            this.isLoading = false
            let currentRoute = this.$route.path
            if (currentRoute === prevRoute) {
              //this.students = data.students
              this.$router.push({
                name: 'Students',
                query: {
                  ...(this.type ? { type: this.type } : {}),
                  ...(this.order ? { order: this.order } : {}),
                  ...(this.status ? { status: this.status } : {})
                  //...(this.pageNumber ? { pageNumber: this.pageNumber } : {})
                }
              })
            }
          })
          .catch(({ response }) => {
            console.log(response)
          })
      }
    }
  },

  created() {
    this.type = this.$route.query.type ? this.$route.query.type : 'active'
    this.order = this.$route.query.order ? this.$route.query.order : 'name'
    this.status = this.$route.query.status ? this.$route.query.status : 'Active'
    //this.pageNumber = this.$route.query.pageNumber ? this.$route.query.pageNumber : "";

    //this.fetchStudents();
  }
}
</script>

<style scoped>
.sort-select {
  width: 150px;
}
</style>
