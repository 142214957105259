<template>
  <v-layout class="my-6 py-1" align-center row wrap :class="{ mobile: $vuetify.breakpoint.smAndDown, standard: $vuetify.breakpoint.mdAndUp }">
    <v-card class="pa-4" :class="{ error: user.isDisabled }" :to="{ name: 'ViewAdminUser', params: { userId: user.id } }">
      <h4>{{ user.fullName }}</h4>
      <pre>{{ user }}</pre>
      <!-- <router-link :to="{ name: 'ViewAdminUser', params: { userId: user.id } }">{{ user.fullName }}</router-link> -->
    </v-card>
  </v-layout>
</template>

<script>
export default {
  components: {},
  props: {
    user: {
      type: Object
    }
  },
  data() {
    return {
      type: ''
    }
  },
  computed: {},
  methods: {
    viewUser() {
      this.$router.push({ name: 'ViewUser', params: { userId: this.userId } })
    }
  },
  created() {
    this.type = this.$route.query.type ? this.$route.query.type : ''
  }
}
</script>

<style scoped></style>
