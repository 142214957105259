<template>
  <div>
    <v-list class="my-12">
      <SidebarItem icon="fas fa-car medium-icon" text="Lessons" link="/lessons" />
      <SidebarItem icon="fas fa-pound-sign medium-icon" text="Payments" link="/payments" />
      <SidebarItem icon="fas fa-calendar-alt medium-icon" text="Calendar" link="/calendar" />
      <SidebarItem icon="fas fa-users medium-icon" text="Students" link="/students" />
      <SidebarItem icon="far fa-envelope medium-icon" text="Messages" link="/messages" />
      <SidebarItem icon="fas fa-cog medium-icon" text="Settings" link="/settings" />
      <!-- <SidebarItem icon="far fa-user-circle medium-icon" text="Profile" link="/profile" /> -->
    </v-list>
    <v-divider></v-divider>
    <v-list class="my-12">
      <div class="d-flex justify-start align-center">
        <SidebarItem icon="fas fa-credit-card medium-icon" :text="setUpPaymentsText" link="/set-up-payments" />
        <v-icon v-if="hasPaymentIssue">fas {{ icon }} mx-5</v-icon>
      </div>
      <!-- <SidebarItem :text="'admin TODO'" :link="'/admin/users/' + this.user.profile.userId" /> -->
    </v-list>
  </div>
</template>

<script>
//import { LOGOUT, UPDATE_USER } from "@/store/actions.type";
//import { Actions } from '../../store/actions.type'

import { mapMutations } from 'vuex'
import SidebarItem from '../../components/Shared/SidebarItem'
import { BankPayoutStatus } from '../../helpers/enums'

export default {
  components: {
    SidebarItem
  },
  data() {
    return {
      items: [
        { icon: 'trending_up', text: 'INSTRUCTOR' },
        { icon: 'subscriptions', text: 'Subscriptions' },
        { icon: 'history', text: 'History' },
        { icon: 'featured_play_list', text: 'Playlists' },
        { icon: 'watch_later', text: 'Watch Later' }
      ]
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },

    fullName() {
      return this.user.profile.fullName
    },
    IsInstructor() {
      return this.user.role == 'Instructor'
    },
    hasPaymentIssue() {
      return this.hasCurrentlyDuePaymentIssue || this.hasPastDuePaymentIssue
    },
    hasCurrentlyDuePaymentIssue() {
      return this.user.profile.hasCurrentlyDuePaymentIssue
    },
    hasPastDuePaymentIssue() {
      return this.user.profile.hasPastDuePaymentIssue
    },
    icon() {
      if (this.hasPastDuePaymentIssue) {
        return 'fa-exclamation text--error blinking fast'
      }

      if (this.hasCurrentlyDuePaymentIssue) {
        return 'fa-exclamation text--warning blinking slow'
      }

      return ''
    },
    setUpPaymentsText() {
      if (this.user.profile.payoutStatus == BankPayoutStatus.PayoutEnabled) {
        return 'Payment Details'
      }
      return 'Set Up Payments'
    }
  },
  methods: {
    ...mapMutations('global', ['toggleDrawer'])

    // updateDrawerState(state) {
    //    this.toggleDrawer(state);

    //   console.log(state)
    // }
    //openDrawer() { this.$store.dispatch('navDrawer','open') },
    //closeDrawer() { this.$store.dispatch('navDrawer','close') }
  }
  // computed: {
  //   ...mapState('global', ['drawer']),

  //    drawerState: {
  //       get () {
  //         return this.drawer
  //       },
  //       set (val) {
  //         this.toggleDrawer(val);
  //       }
  //     }
  // }
}
</script>

<style lang="scss" scoped>
.blinking {
  animation-name: flash;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-play-state: running;
  &.slow {
    animation-duration: 2s;
  }
  &.fast {
    animation-duration: 0.5s;
  }
}

@keyframes flash {
  from {
    color: yellow;
  }
  to {
    color: transparent;
  }
}
</style>
