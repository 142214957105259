<template>
  <v-container class="home-hero full-parent" container--fluid style="position:relative; " :class="home">
    <v-layout align-center justify-center row wrap>
      <div class="slanted-bg">
        <v-layout wrap class="unslant">
          <v-row wrap align="stretch" class="ip-text " no-gutters="" style="">
            <v-col class="text-center hidden-sm-and-down" align-self="start">
              <div class="d-flex flex-column align-center" data-aos="fade-down-right">
                <img src="@/assets/images/undraw_events_2p66_blue.svg" />
                <div>
                  <h2 class="white--text font-weight-bold mb-2">Effective Planning</h2>
                  <h4 class="white--text">
                    Quickly preview upcoming lessons, schedule new lessons into your calendar and manage your time effectively
                  </h4>
                </div>
              </div>
            </v-col>
            <v-col class="text-center" align-self="center" data-aos="fade-down-right">
              <div class="d-flex flex-column align-center">
                <img src="@/assets/images/undraw_transfer_money_rywa_blue.svg" />
                <div>
                  <h2 class="white--text font-weight-bold mb-2">Easy Payments</h2>
                  <h4 class="white--text">
                    Simple for learners drivers to pay for lessons. Students can pay for lessons through InstructorPay, or can pay you by cash. The choice is yours
                  </h4>
                </div>
              </div>
            </v-col>
            <v-col class="text-center hidden-sm-and-down" align-self="end" data-aos="fade-down-right">
              <div class="d-flex flex-column align-center">
                <img src="@/assets/images/undraw_feedback_h2ft_blue.svg" />
                <div>
                  <h2 class="white--text font-weight-bold mb-2">Progression</h2>
                  <h4 class="white--text">
                    Keep track of progress with learners. Create notes against lessons and know when lessons have been paid
                  </h4>
                </div>
              </div>
            </v-col>
          </v-row>

          <!-- <v-flex xs12 sm6 md6 order-xs2 order-sm1 pa-6>
            <v-layout class="ip-text" column fill-height justify-center>
              <h2 class="white--text font-weight-bold mb-2">Easy Payments</h2>
              <h4 class="white--text">
                Simple for learners drivers to pay for lessons. Students can pay for lessons through InstructorPay, or can pay you by cash. The choice is yours
              </h4>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 md6 order-xs1 order-sm2 class="ip-img" align-center="">
            <v-layout justify-center :fill-height="$vuetify.breakpoint.xs">
              <img src="@/assets/images/undraw_transfer_money_rywa.svg" />
            </v-layout>
          </v-flex> -->
        </v-layout>
      </div>
    </v-layout>
  </v-container>
</template>
<script>
//import Header from '../../components/Home/Header'

export default {
  components: {
    //Header
  },
  data: () => ({}),
  computed: {
    home() {
      return {
        'home-image-xs': this.$vuetify.breakpoint.smAndDown,
        'home-image': this.$vuetify.breakpoint.mdAndUp
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-image-xs {
  text-align: center;
  .ip-img {
  }
  img {
    width: 80%;
    height: auto;
    margin-top: 30px;
  }
  .ip-text {
    h2 {
      font-size: 1.4rem;
    }

    h4 {
      font-size: 1rem;
    }
  }
}
.home-image {
  img {
    width: 80%;
    height: auto;
  }
  .ip-text {
    text-align: right;
    padding: 30px;

    h2 {
      font-size: 2.75rem;
      padding: 25px 0px;
    }

    h4 {
      font-size: 1.5rem;
    }
  }
}

.home-hero {
  background: #fff;
}

.full-parent {
  min-height: 100vh;
}
.slanted-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-image: linear-gradient(150deg, #0F1249 15%, 70%, #15476b 94%);
  background-image: linear-gradient(150deg, #ff5722 15%, 70%, rgba(253, 150, 119, 0.801) 94%);

  /* -webkit-transform: skewY(-12deg); */
  transform: skewY(12deg);
  -webkit-animation: Gradient 52s ease infinite;
  animation: Gradient 52s ease infinite;
}

.unslant {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: skewY(-12deg);
}
</style>
