<template>
  <v-layout wrap>
    <h2>success</h2>
    <pre>{{ stripeAccount }}</pre>
  </v-layout>
</template>

<script>
//import StripeAPI from '../../../../services/stripe.service'
import stripeService from '@/services/generated/stripe.service.gen'

export default {
  components: {},
  data() {
    return {
      stripeAccount: null
    }
  },
  computed: {},
  created() {
    stripeService
      .getStripeAccountFull()
      .then(({ data }) => {
        console.log(data)
        this.stripeAccount = data
      })
      .catch(({ response }) => {
        console.log(response)
      })
      .finally(() => {
        this.isLoading = false
      })
  }
}
</script>
