<template>
  <v-layout row wrap align-center class="">
    <v-flex v-if="isInstructor" lg3 xs3>
      <span class>
        {{ miniDate }}
        <span class="ml-6">{{ miniTime }}</span>
      </span>
    </v-flex>
    <v-flex v-if="isStudent" lg3>
      <span class="body-2">{{ miniDate }}</span>
      <br />
      <span class="body-2">{{ miniTime }}</span>
    </v-flex>
    <v-flex v-if="isInstructor" lg9 xs9 class="px-6 payments-flex" :class="paymentBox">
      <div class="heading">
        <span class="amount">Paid</span>
        <span class="application-fee">App Fee</span>
        <span class="transferred">Recieved</span>
      </div>

      <div class="payments">
        <span class="amount">{{ payment.amount | asMoney }}</span>
        <span class="application-fee">{{ appFee }}</span>
        <span class="transferred">{{ payment.transferred | asMoney }}</span>
      </div>
    </v-flex>

    <v-flex lg3 v-if="isStudent">
      <ul>
        <li v-for="(lesson, index) in payment.lessons" v-bind:key="'i' + index">
          <router-link :to="{ name: 'ViewLesson', params: { lessonId: lesson.id } }">{{ lesson.lessonName }}</router-link>
        </li>
      </ul>
    </v-flex>
    <v-flex v-if="isStudent" lg6 xs4 class="payments-flex " :class="paymentBox">
      <div class="px-2">
        <span class="amount-student hidden-sm-and-down">Paid:</span>
        <span :class="amountStudent" class="amount-student primary  white--text">{{ payment.amount | asMoney }}</span>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
// 0 Pending,
// 1 Approved,
// 2 Denied,
// 3 PaymentPassed,
// 4 Deleted

import moment from 'moment'

export default {
  props: {
    payment: {
      type: Object
    },
    isMini: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      //   pushed: false
      payoutDate: ''
    }
  },
  computed: {
    paymentBox() {
      return {
        //lg12: this.isMini || this.$vuetify.breakpoint.smAndDown,
        //lg4: !this.isMini,
        mobile: this.$vuetify.breakpoint.smAndDown,
        standard: this.$vuetify.breakpoint.mdAndUp
      }
    },

    amountStudent() {
      return {
        'amount-student-xs': this.$vuetify.breakpoint.smAndDown,
        'amount-student': this.$vuetify.breakpoint.mdAndUp
      }
    },

    appFee() {
      if (this.payment.paymentMethod === 'Stripe') {
        return this.$options.filters.asMoney(this.payment.applicationFee)
      }
      return 'N/A'
    },
    paymentId() {
      return this.payment.id
    },
    paymentRef() {
      if (this.payment.stripeChargeId) {
        return 'ref:' + this.payment.id + '-' + this.payment.stripeChargeId
      } else {
        return 'ref:' + this.payment.id
      }
    },
    studentId() {
      return this.payment.lessons[0].studentId
    },
    date() {
      return moment(this.payment.localDatePaid).format('lll')
    },
    miniDate() {
      return moment(this.payment.localDatePaid).format('DD/MM/YY')
    },
    miniTime() {
      return moment(this.payment.localDatePaid).format('HH:mm')
    },
    user() {
      return this.$store.state.auth.user
    },
    isInstructor() {
      return this.user.role == 'Instructor'
    },
    isStudent() {
      return this.user.role == 'Student'
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.payment-row {
  cursor: pointer;
}

.payments-flex {
  text-align: center;
  .application-fee {
    border-left: dashed 1px #aaa;
    border-right: dashed 1px #aaa;
  }
  .transferred {
    text-align: center;
  }
  span {
    padding: 5px;
  }
}
.heading {
  span {
    border-bottom: dashed 1px #aaa;
    font-weight: bold;
    font-size: 12px;
  }
}

.payments {
  .transferred {
    background: var(--v-accent-base);
    font-weight: bold;
  }
}

.amount {
  display: inline-block;
  width: 33%;
}
.application-fee {
  display: inline-block;
  width: 33%;
}
.transferred {
  display: inline-block;
  width: 33%;
}

.mobile {
  margin-top: 15px;
  margin-bottom: 15px;
}
.amount-student {
  font-weight: bold;
  width: 40%;
  display: inline-block;
  text-align: center;
}

.amount-student-xs {
  font-weight: bold;
  width: 90%;
  display: inline-block;
  text-align: center;
}
</style>
