<template>
  <div>
    <!-- <h1 v-if="IsInstructor">IsInstructor</h1>
    <h1 v-if="IsStudent">IsStudent</h1>-->

    <InstructorLessons v-if="IsInstructor" />
    <!-- <StudentLessons v-if="IsStudent"/> -->
    <StudentDetails v-if="IsStudent" />
  </div>
</template>

<script>
import InstructorLessons from '@/views/Dashboard/Instructor/Lessons/Lessons'
//import StudentLessons from "@/views/Dashboard/Student/Lessons/Lessons";
import StudentDetails from '../Dashboard/Common/StudentPage/StudentView'

export default {
  components: {
    InstructorLessons,
    //StudentLessons,
    StudentDetails
  },
  computed: {
    IsInstructor() {
      return this.$store.state.auth.user.role == 'Instructor'
    },
    IsStudent() {
      return this.$store.state.auth.user.role == 'Student'
    }
  }
}
</script>

<style scoped></style>
