//student.service.gen.js
// import studentService from '@/services/generated/student.service.gen'
import axios from 'axios'

export default {
  pairWithInstructor(instructorCode) {
    return axios.get(`/api/student/pair-with-instructor/${instructorCode}`)
  },

  /*
					studentService.pairWithInstructor(instructorCode)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  setStatusByStudent(instrutctorId, status) {
    var model = {
      instrutctorId: instrutctorId,
      status: status
    }
    return axios.post('/api/student/set-status', model)
  },

  /*
					studentService.setStatusByStudent(instrutctorId, status)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getInstructor() {
    return axios.get('/api/student/instructor')
  },

  /*
					studentService.getInstructor()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getStudentDetails() {
    return axios.get('/api/student/my-details')
  },

  /*
					studentService.getStudentDetails()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getUnpaidLessons() {
    return axios.get('/api/student/unpaid-lessons')
  }
  /*
					studentService.getUnpaidLessons()
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
