<template>
  <v-app-bar color="primary white--text" dense fixed app dark>
    <slot name="bar"></slot>
    <v-toolbar-title secondary>
      <v-layout align-center>
        <img src="@/assets/images/Logo.png" class="hidden-sm-and-up" style="width: 150px; height: auto;" />
        <img src="@/assets/images/Logo.png" class="hidden-xs-only" style="width: 180px; height: auto;" />
      </v-layout>
    </v-toolbar-title>

    <v-spacer></v-spacer>
    <h4 v-if="isInstructor && instructorCode" class="hidden-xs-only">
      <router-link class="white--text pr-4" to="/settings/code">My Code:</router-link>
    </h4>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <h4 v-on="on" @click="copyTextToClipboard">{{ instructorCode }}</h4>
      </template>
      <span>Click to copy</span>
    </v-tooltip>

    <v-snackbar v-model="showCopiedCode" color="success" :multi-line="false" :timeout="3000" :top="true" :vertical="false">
      Copied to keyboard
      <!-- <v-btn dark text @click="snackbar = false">Close</v-btn> -->
    </v-snackbar>

    <!-- <h2 class="hidden-sm-and-up">XS</h2>
    <h2 class="hidden-md-and-up hidden-xs hidden-xs-only">SM</h2>
    <h2 class="hidden-lg-and-up hidden-sm-and-down">MD</h2>
    <h2 class="hidden-xl hidden-md-and-down hidden-xl-only" >LG</h2>
    <h2 class="hidden-lg-and-down">XL</h2> -->

    <!-- <h1>{{breakpoint}}</h1>

    <span class="ml-12">{{user.email}}</span>-->
  </v-app-bar>
</template>

<script>
//import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      breakpoint: ''
    }
  },

  computed: {
    // drawer() {
    //   return this.$store.state.drawer;
    // },
    //user() { return this.$store.state.auth.user; },
    //...mapState({ user: state => state.auth.user })
    ...mapGetters(['user']),
    showCopiedCode() {
      return this.$store.getters['global/showCopiedCode']
    },
    isInstructor() {
      return this.user.role == 'Instructor'
    },
    instructorCode() {
      return this.isInstructor ? this.user.profile.instructorCode : ''
    },

    //...mapState(["auth"]),
    //mapState({user:"auth.user"}),
    //...mapState("global", ["drawer"])
    leftDrawer: {
      get() {
        return this.$store.getters['global/drawer']
      },
      set(val) {
        this.$store.commit('global/toggleDrawer', val)
      }
    }
  },
  methods: {
    // ...mapMutations('global', ['toggleDrawer']),

    // toggleDrawer2() {
    //   console.log('aaaa')
    //   //this.toggleDrawer
    //   this.$store.commit('global/toggleDrawer')
    // },

    copyTextToClipboard() {
      this.$store.commit('global/showCopiedCode', this.instructorCode)
    },
    onResize() {
      //  switch (this.$vuetify.breakpoint.name) {
      //   case 'xs': return '220px'
      //   case 'sm': return '400px'
      //   case 'md': return '500px'
      //   case 'lg': return '600px'
      //   case 'xl': return '800px'
      // }
      this.breakpoint = this.$vuetify.breakpoint.name //window.innerWidth < 600
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },

  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
    console.log('ENVss' + process.env.NODE_ENV)
  }
}
</script>

<style scoped>
.v-btn--fab.v-size--default .v-icon,
.v-btn--fab.v-size--small .v-icon {
  font-size: 18px;
}
</style>
