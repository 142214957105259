<template>
  <LessonForm />
</template>

<script>
import LessonForm from './LessonForm'

export default {
  components: {
    LessonForm
  }
}
</script>

<style scoped></style>
