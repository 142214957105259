<template>
  <v-layout>
    <v-flex>
      <PageHeading :title="pageTitle" />

      <v-layout wrap>
        <v-flex md3 sm12 hidden-sm-and-down>
          <ul class="profile-list" :class="{ mobile: $vuetify.breakpoint.smAndDown, standard: $vuetify.breakpoint.mdAndUp }">
            <li>
              <router-link @click.native="changeTitle('')" exact to="/settings">My Details</router-link>
            </li>

            <li v-if="isInstructor">
              <router-link @click.native="changeTitle('My Code')" exact to="/settings/code">My Code</router-link>
            </li>
            <li>
              <router-link @click.native="changeTitle('Change Password')" exact to="/settings/change-password">Change Password</router-link>
              <!-- <v-btn small color="primary" class="elevation-0" @click.native="changeTitle('Change Password')" exact to="/profile/change-password">Change Password</v-btn> -->
            </li>
            <li v-if="isInstructor">
              <router-link @click.native="changeTitle('My Subscription')" exact to="/settings/subscription">My Subscription</router-link>
              <!-- <v-btn small color="primary" class="elevation-0" @click.native="changeTitle('Change Password')" exact to="/profile/change-password">Change Password</v-btn> -->
            </li>
          </ul>
        </v-flex>
        <v-flex sm1 hidden-md-and-down>
          <v-divider vertical class="ml-4"></v-divider>
        </v-flex>
        <v-flex md8 xs12>
          <v-row wrap class="hidden-md-and-up text-center mobile-links text-center">
            <v-col :xs="6"> <router-link @click.native="changeTitle('')" exact to="/settings">My Details</router-link></v-col>
            <v-col :xs="6"> <router-link @click.native="changeTitle('Change Password')" exact to="/settings/change-password">Change Password</router-link></v-col>
          </v-row>
          <v-row wrap class="hidden-md-and-up text-center mobile-links" v-if="isInstructor">
            <v-col :xs="12"><router-link @click.native="changeTitle('My Code')" exact to="/settings/code">My Code</router-link></v-col>
            <v-col :xs="12"> <router-link @click.native="changeTitle('My Subscription')" exact to="/settings/subscription">My Subscription</router-link></v-col>
          </v-row>

          <!-- <div class="hidden-md-and-up">
            <v-tabs grow="" background-color="grey lighten-2" class="elevation-3" slider-color="primary">
              <v-tab @click.native="changeTitle('')" exact to="/settings">My Details</v-tab>
              <v-tab v-if="isInstructor" @click.native="changeTitle('My Code')" exact to="/settings/code">My Code</v-tab>
              <v-tab @click.native="changeTitle('Change Password')" exact to="/settings/change-password">Change Password</v-tab>
              <v-tab v-if="isInstructor" @click.native="changeTitle('Subscription')" exact to="/settings/subscription">My Subscription</v-tab>
            </v-tabs>
          </div> -->
          <div class="pa-2 grey lighten-5"><router-view></router-view></div>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      title: ''
    }
  },
  computed: {
    pageTitle() {
      return 'Profile' + (this.title == '' ? '' : ' - ' + this.title)
    },
    ...mapGetters(['profile', 'isInstructor', 'isStudent'])
  },
  methods: {
    changeTitle(msg) {
      this.title = msg
    }
  }
}
</script>

<style scoped lang="scss">
a li:hover,
a.router-link-active,
a.router-link-exact-active {
  cursor: pointer;
  text-decoration: none;
  background: #ff6633;
  color: #fff;
}
.profile-list li a {
  list-style: none;
  padding: 3px 20px;
  border-radius: 3px;
  -moz-border-radius: 3px;
}

.profile-list li {
  list-style: none;
  padding: 5px;
}

.profile-list.standard li {
  text-align: right;
  padding: 5px;
}

.profile-list.mobile li {
  text-align: left;
  display: inline;
  margin: 20px;
  a {
    padding: 15px;
  }
}

.mobile-links {
  a {
    padding: 3px 5px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    cursor: pointer;
  }
}
</style>
