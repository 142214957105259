<template>
  <v-footer class="pa-2 primary white--text px-12">
    <v-layout row wrap>
      <v-flex ml-6>
        <ul class="footer-links">
          <li>
            <router-link to="/terms">Terms</router-link>
          </li>
          <li>
            <router-link to="/privacy">Privacy</router-link>
          </li>

          <li>
            <router-link to="/contact">Contact Us</router-link>
          </li>
        </ul>
      </v-flex>
      <v-flex xs6 text-lg-right>
        <div class="year">&copy; {{ new Date().getFullYear() }}</div>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.header {
}

.year {
  text-align: right;
  padding: 16px;
}
.footer-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  //background-color: #333333;

  li {
    float: left;
  }

  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
  }

  li a:hover {
    // background-color: #262626;
  }
}
</style>
