<template>
  <div>
    <v-layout wrap>
      <v-flex lg8>
        <h2>{{ title }}</h2>
      </v-flex>
      <v-flex lg4 justify-end text-right>
        <slot name="side"></slot>
      </v-flex>
    </v-layout>
    <v-divider light class="mt-2 mb-6"></v-divider>
  </div>
</template>

<script>
export default {
  props: ['title']
}
</script>

<style scoped></style>
