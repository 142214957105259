<template>
  <div>
    <Home1 />
    <HomeSlider />
    <Home5 />
    <Home4 />
    <Home3 />
    <Home6 />
    <Footer />
  </div>
</template>

<script>
import Home1 from './Home1'
import HomeSlider from './HomeSlider'
import Home5 from './Home5'
import Home4 from './Home4'
import Home3 from './Home3'
import Home6 from './Home6'
import Footer from './Footer'

export default {
  components: {
    Home1,
    HomeSlider,
    Home5,

    Home4,
    Home3,
    Home6,
    Footer
  },
  data: () => ({})
}
</script>

<style lang="scss" scoped></style>
