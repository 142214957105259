//appointment.service.gen.js
// import appointmentService from '@/services/generated/appointment.service.gen'
import axios from 'axios'

export default {
  createAppointment(startDateTime, endDateTime, title) {
    var model = {
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      title: title
    }
    return axios.post('/api/appointment', model)
  },

  /*
					appointmentService.createAppointment(startDateTime, endDateTime, title)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  cancelAppointment(id) {
    return axios.put(`/api/appointment/cancel/${id}`)
  },

  /*
					appointmentService.cancelAppointment(id)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
  getInstructorsCalendarAppointments(month, year) {
    return axios.get('/api/appointment/calendar', {
      params: {
        month: month,
        year: year
      }
    })
  }
  /*
					appointmentService.getInstructorsCalendarAppointments(month, year)
						.then(({ data }) => {
							console.log('daaata:', data)
						})
						.catch(error => {
							console.log(error)
						})
		*/
}
