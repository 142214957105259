<template>
  <div>
    <SuccessTick v-if="paidSuccessful" :isMini="true">Payment completed successfully</SuccessTick>

    <v-layout v-show="!paidSuccessful" wrap grey lighten-3 rounded class="mt-4 pa-4">
      <v-flex lg12 xs12 class="pa-2">
        <div class="pa-4 discount-box" v-if="isCouponValid">
          For a limited time only you can subscribe at a discounted price of <strong>{{ price }}</strong> per month for your first 6 months. Subsribe now to avoid missing
          out on this discount.
        </div>
      </v-flex>
      <v-flex lg12 xs12 class="pa-2">
        <label class="primary--text text--darken-4n cc-label" for="card-element">Credit or debit card</label>
      </v-flex>

      <v-flex lg8 xs12 class="pa-2 d-flex column align-center">
        <div id="card-element" style="width:100%">
          <!-- A Stripe Element will be inserted here. -->
        </div>

        <!-- Used to display form errors. -->
      </v-flex>
      <v-flex lg4 xs12 class="pa-2">
        <v-btn block @click="payForSubscription" :disabled="submitted" :class="{ 'primary white--text': true }"
          >Pay {{ this.price }} / month <span v-if="isCouponValid">*</span>
          <v-spacer v-if="submitted"></v-spacer>
          <v-progress-circular v-if="submitted" size="16" width="2" indeterminate color="primary" class="pl-3"></v-progress-circular>
        </v-btn>
      </v-flex>

      <v-flex lg12 pa-2>
        <FormErrors :errors="errors" />
      </v-flex>
    </v-layout>

    <div v-if="isCouponValid" class="pa-2 caption">* This is the discounted price for the first 6 months, subsequent months will be charged at {{ fullPrice }}</div>
  </div>
</template>

<script>
//import StripeAPI from '@/services/stripe.service'
import stripeBillingService from '@/services/generated/stripeBilling.service.gen'

//import { Mutations } from '@/store/mutations.type'
import { Actions } from '@/store/actions.type'

//card = undefined

export default {
  props: ['buttonText', 'isCouponValid'],
  components: {},
  data() {
    return {
      combinedStatus: {},
      stripe: {},
      card: {},
      hasCardErrors: false,
      errors: [],
      paidSuccessful: false,
      submitted: false,
      paymentMethods: [],
      selectedPaymentMethod: '',
      isDiscountMode: true
    }
  },
  computed: {
    fullPrice() {
      return process.env.VUE_APP_MONTHLY_COST
    },
    price() {
      if (this.isCouponValid) {
        return process.env.VUE_APP_MONTHLY_COST_DISCOUNT
      }
      return process.env.VUE_APP_MONTHLY_COST
    }
  },
  watch: {},
  methods: {
    payForSubscription() {
      this.submitted = true
      this.errors = []
      this.stripe.createPaymentMethod('card', this.card).then(result => {
        if (result.error) {
          this.errors = [result.error.message]
          this.submitted = false
        } else {
          // Send paymentMethod.id to server
          // var model = {
          //   payment_method_id: result.paymentMethod.id
          // }
          stripeBillingService.confirmPaymentSubscription(result.paymentMethod.id, null, null).then(result => {
            // Handle server response (see Step 3)
            //result.json().then(json => {
            this.handleServerResponse(result).then(() => {
              this.$store.dispatch(Actions.GET_SUBSCRIPTION)
              //this.getSubscriptionStatus()
            })

            //})
          })
        }
      })
    },

    handleServerResponse(response) {
      console.log('TCL: handleServerResponse -> response', response)
      if (response.data.error) {
        // Show error from server on payment form
        this.errors = [response.data.error]
        //this.errors = [response.data.error.message]
        this.submitted = false
      } else if (response.data.requires_payment_method) {
        // Use Stripe.js to handle required card action
        this.errors = ['There was an error using this card, please check the card details you have entered']
        this.submitted = false
      } else if (response.data.requires_action) {
        // Use Stripe.js to handle required card action
        this.handleAction(response.data)
      } else {
        // Show success message
        // let status = response.data.subscriptionStatus
        // this.$store.commit(Mutations.SET_SUBSCRIPTION_STATUS, status)

        this.paidSuccessful = true
        setTimeout(() => {
          this.submitted = false
          this.$store.dispatch(Actions.GET_SUBSCRIPTION).then(() => {
            //this.paidSuccessful = false
          })
          //this.getSubscriptionStatus()
        }, 3000)
      }
    },

    handleAction(response) {
      this.stripe.handleCardPayment(response.payment_intent_client_secret).then(result => {
        console.log('4TCL: handleAction -> result', result)
        if (result.error) {
          // Show error in payment form
          this.errors = [result.error.message]
          this.submitted = false
        } else {
          this.paidSuccessful = true
          setTimeout(() => {
            this.paidSuccessful = false
            this.submitted = false
          }, 3000)
        }
        this.$store.dispatch(Actions.GET_SUBSCRIPTION)
        //        this.getSubscriptionStatus()
      })
    }

    // getSubscriptionStatus() {
    //   StripeAPI.getSubscriptionStatus()
    //     .then(response => {
    //       console.log(response.data)
    //       this.combinedStatus = response.data
    //       this.$store.commit(Mutations.SET_SUBSCRIPTION_STATUS, response.data.ipSubscriptionStatus)
    //       this.$store.commit(Mutations.SET_SUBSCRIPTION_STATUS, response.data.stripeSubscriptionStatus)

    //     })
    //     .catch(({ response }) => {
    //       console.log(response)
    //     })
    //     .finally(() => {
    //       //this.isLoading = false
    //     })
    // }
  },
  mounted() {
    var style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }

    // eslint-disable-next-line no-undef
    this.stripe = Stripe(process.env.VUE_APP_STRIPE_PK)
    var elements = this.stripe.elements()

    // Create an instance of the card Element.
    this.card = elements.create('card', { style: style })

    // Add an instance of the card Element into the `card-element` <div>.
    this.card.mount('#card-element')

    // Handle real-time validation errors from the card Element.
    this.card.addEventListener('change', event => {
      //this.hasCardErrors = false
      this.errors = []
      //var displayError = document.getElementById('card-errors')
      if (event.error) {
        //displayError.textContent = event.error.message
        //this.hasCardErrors = true
        this.errors = [event.error.message]
      } else {
        //displayError.textContent = ''
        this.errors = []
      }
    })
  }
}
</script>

<style scoped>
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.rounded {
  border-radius: 5px;
}

.cc-label {
  font-weight: bold;
}

.discount-box {
  border: #fa755a solid 2px;
  background-color: #fff;
}
</style>
