<template>
  <v-toolbar color="primary" flat dark>
    <v-toolbar-title>
      <h4><slot></slot></h4>
    </v-toolbar-title>
  </v-toolbar>
</template>

<script>
export default {}
</script>

<style scoped></style>
