<template>
  <v-container class="home-hero white " container--fluid :class="home2" fill-heightX id="demo">
    <v-row align="center">
      <v-col cols="12" md="9" sm="12" xs="12" offset-md="" class="pa-10">
        <v-card class="pa-2 ma-10" style="z-index:2">
          <v-carousel class="fill-heightz" height="auto" interval="5000" :cycle="true" v-model="model" v-if="!$vuetify.breakpoint.xs">
            <v-carousel-item v-for="(item, i) in items" :key="i">
              <img :src="item.src" alt="" class="fill-heightv" style="width:100%; height:auto" />
            </v-carousel-item>
          </v-carousel>
          <v-carousel class="fill-heightz" :height="auto" :cycle="true" v-model="model" v-if="$vuetify.breakpoint.xs">
            <v-carousel-item v-for="(item, i) in itemsMobile" :key="'m' + i" style="width:100%; height:auto">
              <img :src="item.src" alt="" class="fill-heightv" style="width:100%; height:auto" />
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" xs="12" sm="12" offset-md="">
        <div class="mt-10 pr-lg-10 pa-xs-0  pa-sm-0">
          <h2 class="display-3 font-weight-bold ">{{ messageTitles[model] }}</h2>
          <p class="display-1	mt-10">{{ messageContent[model] }}</p>
        </div>

        <!-- <v-card class="pa-2 ma-10 ">
          <v-carousel class="fill-heightz" height="auto" cycle="true" v-model="model" v-if="$vuetify.breakpoint.xs">
            <v-carousel-item v-for="(item, i) in itemsMobile" :key="'m' + i">
              <img :src="item.src" alt="" class="fill-heightv" style="width:100%; height:auto" @click="alert('dd')" />
            </v-carousel-item>
          </v-carousel>
        </v-card> -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
//import SVGImage from './SVG/Home1'
//import HomeRight from './HomeRight' 1500x850

export default {
  data: () => ({
    model: 0,
    items: [
      {
        src: require('../../assets/images/screens/lessons.png')
      },
      {
        src: require('../../assets/images/screens/payments.png')
      },
      {
        src: require('../../assets/images/screens/calendar.png')
      },
      {
        src: require('../../assets/images/screens/messages.png')
      },
      {
        src: require('../../assets/images/screens/progress.png')
      }
    ],
    messageTitles: ['Lessons', 'Payments', 'Calendar', 'Messages', 'Progress'],
    messageContent: [
      'Easy to use lesson management. Book lessons, see the the big picture and stay in control.',
      'Keep track of payments made to you. Get paid through the App.',
      'Stay organised and know exactly when you have time for another lesson.',
      'Communicate in one place with learner drivers, discuss and arrange your lessons.',
      'Monitor progress with learners, and give them a clear idea what stage they are at.'
    ],

    itemsMobile: [
      {
        src: require('../../assets/images/screens/mobile/calendar.png')
      },
      {
        src: require('../../assets/images/screens/mobile/lessons.png')
      },
      {
        src: require('../../assets/images/screens/mobile/messages.png')
      },
      {
        src: require('../../assets/images/screens/mobile/payments.png')
      },
      {
        src: require('../../assets/images/screens/mobile/progress.png')
      }
    ]
  }),
  computed: {
    // images() {
    //   let path = '../../assets/images/screens/'
    //   let files = ['calendar_screen.png', 'lessons_screen.png', 'messages_screen.png', 'payments_screen.png', 'progress_screen.png']
    //   let combined = files.map(img => {
    //     return require(path + img)
    //   })
    //   return combined
    // },
    home2() {
      return {
        'home2-image-xs': this.$vuetify.breakpoint.smAndDown,
        'home2-image': this.$vuetify.breakpoint.mdAndUp
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home2-image-xs {
  text-align: center;
}
.home2-image {
}

.home-hero {
  min-height: 100vh;
  width: 100vw;
  // background: #ff6633;
}

.fas {
  font-size: 30vw;
}

// .v-window-item {
//   min-height: 70vh !important;
// }
</style>
