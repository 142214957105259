<template>
  <div>
    <AdminHeader />

    <v-row>
      <v-col xs="6">
        <v-card class="pa-6">
          <h4>{{ userData.fullName }}</h4>
          <h4>{{ userData.userId }}</h4>

          <pre>{{ userData }}</pre>
          <p>
            hasCounterPartyId
            {{ hasCounterPartyId }}
          </p>
        </v-card>
      </v-col>
      <v-col xs="6">
        <v-card class="pa-6" v-if="userData.isInstructor">
          <h4>payoutStatus:{{ userData.payoutStatus }}</h4>
          <pre>
            BankPayoutStatus = {
              <!-- Unsubmitted: 0,
              PendingApproval: 1,
              PayoutEnabled: 2,
              Banned: 3,
              DisabledByIntructor: 4 -->

              
        Unsubmitted = 0, // not added bank
        BankCreated = 1, // added bank detail
        PayoutEnabled = 2, // counter party created
        Suspended = 3,
        DisabledByIntructor = 4
            }
              <!-- Unsubmitted: 0,
              RegisteredInterest: 1,
              EligibleForEnabling: 2,

              InstructorPayPendingApproval: 3,

              InstructorPayEnabled: 4,

              Banned: 5,
              DisabledByIntructor: 6 -->

          </pre>
        </v-card>
      </v-col>
    </v-row>

    <v-layout v-if="userData" row class="user-view">
      <v-flex>
        <div class="d-flex justify-space-between">
          <div>
            <v-btn class="ma-2" color="error" @click="banUser">Ban User</v-btn>
            <v-btn class="ma-2" color="error" @click="modifyEmail">Modify Email</v-btn>
          </div>

          <div v-if="userData.isInstructor">
            <!-- <v-btn class="ma-2" color="success" v-if="isBankCreated" @click="createCounterParty">Create Counterparty</v-btn> -->
            <v-btn class="ma-2" color="success"  @click="createCounterParty">Create Counterparty</v-btn>

            <v-btn class="ma-2" color="success" v-if="showEligible" @click="enableEligible">Enable Eligible for Payments</v-btn>
            <v-btn class="ma-2" color="success" v-if="showEnable" @click="enablePayments">Enable Payments</v-btn>
            <!-- <v-btn class="ma-2" color="error" @click="pendingApproval">Set to Pending Approval</v-btn> -->

            <v-btn class="ma-2" color="error" @click="disablePayments">Disable Payments</v-btn>
          </div>
        </div>
      </v-flex>
    </v-layout>

    <AdminPayments :instructorId="this.userData.userId" />
  </div>
</template>

<script>
//import AdminAPI from '@/services/admin.service'
import adminService from '@/services/generated/admin.service.gen'

import { PayoutStatus } from '../../helpers/enums'
import { BankPayoutStatus } from '../../helpers/enums'

import AdminHeader from './AdminHeader.vue'
import AdminPayments from './AdminPayments.vue'

export default {
  components: {
    AdminHeader,
    AdminPayments
  },
  data() {
    return {
      userData: null
    }
  },
  computed: {
    showEligible() {
      return this.userData.payoutStatus == PayoutStatus.RegisteredInterest
    },
    showEnable() {
      return true //this.userData.payoutStatus == PayoutStatus.InstructorPayPendingApproval
    },
    unsbmitted() {
      return PayoutStatus.Unsubmitted
    },

    InstructorPayPendingApproval() {
      return PayoutStatus.InstructorPayPendingApproval
    },

    hasCounterPartyId() {
      return this.userData.RevolutCounterPartyId && this.userData.RevolutCounterPartyId != ''
    },

    //----------------

    payoutStatus() {
      return this.userData.payoutStatus
    },
    isUnsubmitted() {
      return this.payoutStatus == BankPayoutStatus.Unsubmitted
    },

    isBankCreated() {
      return this.payoutStatus == BankPayoutStatus.BankCreated
    },

    isPayoutEnabled() {
      return this.payoutStatus == BankPayoutStatus.PayoutEnabled
    },

    isBanned() {
      return this.payoutStatus == BankPayoutStatus.Banned
    },

    isDisabledByIntructor() {
      return this.payoutStatus == BankPayoutStatus.DisabledByIntructor
    }

    //----------------
  },
  methods: {
    banUser() {
      adminService
        .banUser(this.userData.userId)

        .then(({ data }) => {
          this.loadUserData()
          console.log(data)
        })
        .catch(({ response }) => {
          console.log(response)
        })
    },
    modifyEmail() {
      adminService
        .modifyUserEmail(this.userData.userId)
        .then(({ data }) => {
          this.loadUserData()
          console.log(data)
        })
        .catch(({ response }) => {
          console.log(response)
        })
    },

    enablePayments() {
      adminService
        .enablePayments(this.userData.userId)
        .then(({ data }) => {
          this.loadUserData()
          console.log(data)
        })
        .catch(({ response }) => {
          console.log(response)
        })
    },
    enableEligible() {
      adminService
        .enableEligible(this.userData.userId)
        .then(({ data }) => {
          this.loadUserData()
          console.log(data)
        })
        .catch(({ response }) => {
          console.log(response)
        })
    },

    disablePayments() {
      adminService
        .disablePayments(this.userData.userId)
        .then(({ data }) => {
          this.loadUserData()
          console.log(data)
        })
        .catch(({ response }) => {
          console.log(response)
        })
    },
    loadUserData() {
      let userId = this.$route.params.userId
      console.log('userId' + userId)
      adminService
        .getUser(userId)
        .then(({ data }) => {
          this.userData = data
          console.log(data)
        })
        .catch(({ response }) => {
          console.log(response)
        })
    },
    createCounterParty() {
      adminService
        .createCounterPartyForUser(this.userData.userId)
        .then(({ data }) => {
          console.log('daaata:', data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created() {
    this.loadUserData()
  }
}
</script>

<style scoped></style>
