import Vue from 'vue'
import PageHeading from '@/components/Shared/PageHeading'
import AvatarName from '@/components/Shared/AvatarName'
import SubHeading from '@/components/Shared/SubHeading'
import FormTitle from '@/components/Shared/FormTitle'
import FormErrors from '@/components/Shared/FormErrors'
import Modal from '@/components/Shared/Modal'
import Spinner from '@/components/Shared/Spinner'
import SuccessTick from '@/components/Shared/SuccessTick'

Vue.component('PageHeading', PageHeading)
Vue.component('AvatarName', AvatarName)
Vue.component('SubHeading', SubHeading)
Vue.component('FormTitle', FormTitle)
Vue.component('FormErrors', FormErrors)
Vue.component('Modal', Modal)
Vue.component('Spinner', Spinner)
Vue.component('SuccessTick', SuccessTick)
