<template>
  <div v-if="this.lessons">
    <PageHeading title="Calendar">
      <template #side>
        <LessonsAddButton />
      </template>
    </PageHeading>

    <!-- <pre>{{ this.lessonsMap2 }}</pre> -->

    <v-row class="wrap">
      <v-col cols="12" md="6" sm="12" xs="12">
        <CalendarAppointment v-if="!$vuetify.breakpoint.smAndDown" />
      </v-col>
      <v-col cols="12" md="6" sm="12" xs="12" class="text-right">
        <div v-if="!$vuetify.breakpoint.smAndDown">
          <v-btn elevation="0" :ripple="false" class="text-capitalize" depressed small dark :color="this.colorTest">Test</v-btn>
          <v-btn elevation="0" :ripple="false" class="ml-4 text-capitalize" depressed small dark :color="this.colorAppointment">Unavailable</v-btn>
          <v-btn elevation="0" :ripple="false" class="ml-4 text-capitalize" depressed small dark :color="this.colorPast">Previous</v-btn>
          <v-btn elevation="0" :ripple="false" class="ml-4 text-capitalize" depressed small dark :color="this.colorFuture">Upcoming</v-btn>
        </div>
        <div v-if="$vuetify.breakpoint.smAndDown">
          <v-btn elevation="0" :ripple="false" class="text-capitalize" depressed x-small dark :color="this.colorTest">Test</v-btn>
          <v-btn elevation="0" :ripple="false" class="ml-4 text-capitalize" depressed x-small dark :color="this.colorAppointment">Unavailable</v-btn>
          <v-btn elevation="0" :ripple="false" class="ml-4 text-capitalize" depressed x-small dark :color="this.colorPast">Previous</v-btn>
          <v-btn elevation="0" :ripple="false" class="ml-4 text-capitalize" depressed x-small dark :color="this.colorFuture">Upcoming</v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- <v-layout justify-space-between>
      <div>
        
      </div>
    </v-layout> -->
    <Spinner :isLoading="isLoading" />

    <v-row class="fill-height" v-if="!isLoading">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat color="grey lighten-2">
            <CalendarAppointment v-if="$vuetify.breakpoint.smAndDown" />

            <v-btn small outlined class="mr-4 white-button" @click="setToday" v-if="!$vuetify.breakpoint.smAndDown">
              Today
            </v-btn>
            <v-btn fab text small @click="prev" v-if="!$vuetify.breakpoint.smAndDown">
              <v-icon small>fas fa-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small @click="next" v-if="!$vuetify.breakpoint.smAndDown">
              <v-icon small>fas fa-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title v-if="!$vuetify.breakpoint.smAndDown">{{ title }}</v-toolbar-title>

            <div class="flex-grow-1"></div>
            <v-menu bottom right>
              <template v-slot:activator="{ on }">
                <v-btn class="white-button" small outlined v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="changeCalendarType('day')">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeCalendarType('week')">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeCalendarType('month')">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeCalendarType('4day')">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="64" v-if="$vuetify.breakpoint.smAndDown">
          <div class="d-flex justify-space-between align-center">
            <v-btn fab text small @click="prev">
              <v-icon small>fas fa-chevron-left</v-icon>
            </v-btn>

            <v-toolbar-title class="caption">{{ title }}</v-toolbar-title>
            <v-btn fab text small @click="next">
              <v-icon small>fas fa-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-sheet>

        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="lessonsMap2"
            :event-color="getEventColor"
            :event-margin-bottom="3"
            :now="today"
            :type="type"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          ></v-calendar>
          <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
            <v-card v-if="selectedEvent.isLesson" color="grey lighten-4" min-width="350px" max-width="350px" flat>
              <v-toolbar color="primary" dark>
                <AvatarName :isBacked="false" :name="selectedEvent.studentName" :isClickable="false" :imageUrl="selectedEvent.profilePicture" :size="'md'" />
              </v-toolbar>
              <v-card-text class="pa-4">
                <h3>Date: {{ selectedEvent.details }}</h3>
                <h3>Time: {{ selectedEvent.justTime24 }}</h3>
                <h3>Duration: {{ selectedEvent.friendlyDuration }}</h3>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="goToLesson(selectedEvent.id)">
                  View
                </v-btn>
              </v-card-actions>
            </v-card>

            <v-card v-if="!selectedEvent.isLesson" color="grey lighten-4" min-width="350px" max-width="350px" flat>
              <v-card-text class="pa-4">
                <h3>{{ selectedEvent.title }}</h3>
                <h3>Time: {{ selectedEvent.justTime24 }}</h3>
                <h3>End: {{ selectedEvent.justTime24End }}</h3>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false">
                  Dismiss
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="secondary" @click="deleteAppointment(selectedEvent.id)"> <v-icon class="mr-4">fas fa-trash small-icon</v-icon> Delete </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
    <!-- <pre>{{ lessons }}</pre>
    <pre>{{ lessonsMap2 }}</pre> -->
    <!-- <v-layout row wrap>
    
      <v-col sm="4">
        <pre>{{ lessonsMap2 }}</pre>
      </v-col>
      <v-col sm="4">
        <pre>{{ lessons }}</pre>
      </v-col>
    </v-layout> -->
  </div>
</template>

<script>
//import CalendarAPI from '../../../services/calendar.service'
import appointmentService from '@/services/generated/appointment.service.gen'
import lessonInstructorService from '@/services/generated/lessonInstructor.service.gen'

import _ from 'lodash'
import moment from 'moment'
//import colours from '../../../helpers/colours'
// import CalendarPopout from './CalendarPopout'
// import LessonsOnDay from '../Instructor/Lessons/LessonsOnDay'
import LessonsAddButton from '../Instructor/Lessons/LessonsAddButton'
import CalendarAppointment from './CalendarAppointment'

export default {
  components: {
    // CalendarPopout,
    // LessonsOnDay,
    LessonsAddButton,
    CalendarAppointment
  },
  data() {
    return {
      isLoading: true,

      start: moment().format('YYYY-MM-DD'), //'2019-03-26',
      startWeek: moment().format('YYYY-MM-DD'),
      today: moment().format('YYYY-MM-DD'),
      focus: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
      lessons: [],
      appointments: [],

      type: this.$vuetify.breakpoint.smAndDown ? 'day' : 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days'
      },

      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,

      colorPast: '#aaa', //this.$vuetify.theme.themes.light.accent, // '#2E7D32',
      colorTest: this.$vuetify.theme.themes.light.primary,
      colorAppointment: '#666',
      colorFuture: 'green',

      year: moment().format('YYYY'),
      month: moment().format('MM')
    }
  },

  computed: {
    title() {
      const { start, end } = this
      if (!start || !end) {
        return ''
      }

      const startMonth = moment(this.start.month, 'M').format('MMMM') //moment(art).format('YYYY-MM-DD')).format('MMMM');//this.monthFormatter(start)
      const endMonth = moment(this.end.month, 'M').format('MMMM') //.format('MMMM');
      const suffixMonth = startMonth === endMonth ? '' : endMonth

      const startYear = start.year
      const endYear = end.year
      const suffixYear = startYear === endYear ? '' : endYear

      const startDay = start.day + this.nth(start.day)
      const endDay = end.day + this.nth(end.day)

      switch (this.type) {
        case 'month':
          return `${startMonth} ${startYear}`
        case 'week':
        case '4day':
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
        case 'day':
          return `${startMonth} ${startDay} ${startYear}`
      }
      return ''
    },
    // monthFormatter() {
    //   return this.$refs.calendar.getFormatter({
    //     timeZone: 'UTC',
    //     month: 'long'
    //   })
    // },

    // convert the list of events into a map of lists keyed by date
    // start() {
    //   return moment().format('YYYY-MM-DD')
    // },
    // monthCalendarMonth() {
    //   return moment(this.start).format('MMMM')
    // },
    // weekCalendarMonth() {
    //   return moment(this.startWeek).format('MMMM')
    // },
    // isMonth() {
    //   return this.type == 'month'
    // },
    // flattenedLessons() {
    //   let mapped = _.map(this.lessons, lesson => {
    //     let date = moment(lesson.localStartDateTime).format('YYYY-MM-DD')
    //     let timePart = moment(lesson.localStartDateTime).format('HH:mm')

    //     //let dateFormat = moment(lesson.localStartDateTime).format('MMMM Do YYYY, h:mm:ss a')
    //     return {
    //       id: lesson.id, // + timePart,
    //       studentName: lesson.studentName, // + timePart,
    //       details: lesson.friendlyDate,
    //       date: date,
    //       justTime24: timePart, //lesson.justTime24,
    //       duration: lesson.hours * 60,
    //       profilePicture: lesson.profilePicture,
    //       open: false
    //     }
    //   })
    //   return mapped
    // },
    lessonsMap2() {
      let mapped = _.map(this.lessons, lesson => {
        let date = moment(lesson.localStartDateTime).format('YYYY-MM-DD')
        let timePart = moment(lesson.localStartDateTime).format('HH:mm')

        let startDT = moment(lesson.localStartDateTime).format('YYYY-MM-DD HH:mm')
        let endDT = moment(lesson.localEndDateTime).format('YYYY-MM-DD HH:mm')

        //let dateFormat = moment(lesson.localStartDateTime).format('MMMM Do YYYY, h:mm:ss a')
        return {
          id: lesson.id, // + timePart,
          studentName: lesson.studentName, // + timePart,
          date: date,
          justTime24: timePart, //lesson.justTime24,

          duration: lesson.hours * 60,
          profilePicture: lesson.profilePicture,
          friendlyDuration: lesson.friendlyDuration,

          open: false,

          name: lesson.studentName,
          details: lesson.friendlyDate,
          start: startDT,
          end: endDT,
          color: 'blue',
          isLesson: true,
          lessonType: lesson.lessonType
        }
      })

      let mappedAppointments = _.map(this.appointments, x => {
        let date = moment(x.localStartDateTime).format('YYYY-MM-DD')
        let timePart = moment(x.localStartDateTime).format('HH:mm')
        let timePartEnd = moment(x.localEndDateTime).format('HH:mm')

        let startDT = moment(x.localStartDateTime).format('YYYY-MM-DD HH:mm')
        let endDT = moment(x.localEndDateTime).format('YYYY-MM-DD HH:mm')

        //let dateFormat = moment(lesson.localStartDateTime).format('MMMM Do YYYY, h:mm:ss a')
        return {
          id: x.id, // + timePart,
          title: x.title, // + timePart,
          name: x.title,
          date: date,
          justTime24: timePart, //lesson.justTime24,
          justTime24End: timePartEnd, //lesson.justTime24,

          duration: x.hours * 60,
          friendlyDuration: x.friendlyDuration,
          open: false,
          start: startDT,
          end: endDT,
          color: 'blue',
          isLesson: false
        }
      })

      let both = [...mapped, ...mappedAppointments]
      //console.log('TCL: both', both)

      return both
    }
  },

  methods: {
    changeCalendarType(type) {
      console.log('TCL: changeCalendarType -> type', type)
      this.type = type
      if (type == 'week' || type == 'day') {
        //this.$refs.calendar.scrollToTime('08:00')
        console.log('#### scroll to  8')
        setTimeout(() => this.$refs.calendar.scrollToTime({ hour: 8, minute: 0 }), 50)
      }
    },

    goToLesson(lessonId) {
      this.$router.push({ name: 'ViewLesson', params: { lessonId: lessonId } })
    },
    // getColour(name, top, height) {
    //   var colour = colours(name)
    //   //return colour

    //   return { top: top + 'px', height: height + 'px', background: colour.bg, color: colour.font }
    // },
    // getStyle(name) {
    //   var colour = colours(name)
    //   console.log('colour: ' + colour)
    //   return 'background-color: ' + colour.bg + '; border-color: ' + colour.bg + '; color: ' + colour.font + ';'
    // },
    // timeSelected(date) {
    //   console.log('timeSelected' + date.date)
    //   console.log('timeSelected' + JSON.stringify(date))

    //   alert('timeSelected' + date.date + date.time)
    // },
    // interval(date) {
    //   console.log('interval' + date.date)
    //   console.log('interval' + JSON.stringify(date))

    //   alert('interval' + date.date)
    // },
    // daySelected(date) {
    //   console.log('date' + date.date)
    //   console.log('date' + JSON.stringify(date))

    //   alert('day' + date.date)
    // },
    // dateSelected(date) {
    //   console.log('date' + date.date)
    //   console.log('date' + JSON.stringify(date))

    //   alert('date' + date.date)
    // },
    // open(event) {
    //   alert(event.studentName + event.duration)
    // },
    // test() {
    //   alert('hrld')
    // },
    changeDate() {
      this.fetchLessons()
    },

    fetchLessons() {
      lessonInstructorService
        .getInstructorsCalendarForInstructor(this.month, this.year)
        .then(response => {
          this.isLoading = false
          //console.log(response.data)
          this.lessons = response.data //.lessons
          //this.$refs.calendar.scrollToTime('08:00')
        })
        .catch(error => {
          console.log(error)
        })
    },

    fetchAppointments() {
      appointmentService
        .getInstructorsCalendarAppointments(this.month, this.year)
        .then(response => {
          //this.isLoading = false
          //console.log(response.data)
          this.appointments = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    // prevWeek() {
    //   this.$refs.calendarWeek.prev()
    // },
    // nextWeek() {
    //   this.$refs.calendarWeek.next()
    // },

    // prevMonth() {
    //   this.$refs.calendarMonth.prev()
    // },
    // nextMonth() {
    //   this.$refs.calendarMonth.next()
    // },

    // stringToColour(str) {
    //   var hash = 0
    //   for (let i = 0; i < str.length; i++) {
    //     hash = str.charCodeAt(i) + ((hash << 5) - hash)
    //   }
    //   var colour = '#'
    //   for (let i = 0; i < 3; i++) {
    //     var value = (hash >> (i * 8)) & 0xff
    //     colour += ('00' + value.toString(16)).substr(-2)
    //   }
    //   return colour
    // }

    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor(event) {
      if (!event.isLesson) {
        return this.colorAppointment
      }

      if (event.lessonType == 2) {
        return this.colorTest
      }

      if (moment().isAfter(event.start)) {
        return this.colorPast
      } else {
        return this.colorFuture
      }
    },
    setToday() {
      this.focus = this.today
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => (this.selectedOpen = true), 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange({ start, end }) {
      // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
      this.start = start
      this.end = end
      this.month = this.start.month
      this.year = this.start.year
      this.fetchLessons()
      this.fetchAppointments()
    },
    nth(d) {
      return d > 3 && d < 21 ? 'th' : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    },

    deleteAppointment(id) {
      this.selectedOpen = false
      appointmentService
        .cancelAppointment(id)
        .then(response => {
          //this.isLoading = false
          console.log(response.data)
          this.appointments = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }
  },

  //  mounted () {
  //     this.$refs.calendar.scrollToTime('08:00')
  //   },

  mounted() {
    //this.$refs.calendar.checkChange()

    this.fetchLessons()
    this.fetchAppointments()

    //this.$refs.calendarWeek.scrollToTime('08:00')
  }
}
</script>

<style lang="scss" scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  //border: 1px solid #1867c0;
  width: 100%;
  font-size: 12px;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 1px;
}

.my-event2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  //border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;

  &.with-time {
    position: absolute;
    right: 4px;
    margin-right: 0px;
  }
}

.today-list {
  h3 {
    margin: 15px 0px;
    .fas {
      margin-right: 15px;
    }
  }
  list-style: none;
}
</style>
